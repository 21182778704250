import {
    makeStyles,
    TableContainer,
    TableHead,
    Table,
    TableBody,
    TableRow,
} from '@material-ui/core';
import Decimal from 'decimal.js';
import { LocalDate } from 'js-joda';
import React from 'react';
import { Currency } from '../../types/alias';
import { Recibo } from '../../types/types';
import { ReciboCell } from './ReciboCell';
import ReciboRow from './ReciboRow';

interface ContainerProps {
    recibos: Recibo[];
    divisa: Currency;
    id: number;
    nombre: string;
    externalId: string;
}

const RecibosContainer: React.FC<ContainerProps> = ({
    recibos,
    divisa,
    id,
    nombre,
    externalId,
}) => {
    const useStyles = makeStyles({
        table: {
            minWidth: 650,
        },
    });
    const classes = useStyles();
    const createData = (
        id: number,
        monto: Decimal,
        peso: string,
        pagado: boolean,
        vencimiento: LocalDate,
        en_voucher: boolean,
        external_id: string,
        support_filial: boolean
    ) => {
        return {
            id,
            monto,
            peso,
            pagado,
            vencimiento,
            en_voucher,
            external_id,
            support_filial,
        };
    };

    const newDate = new Date();
    const rows =
        recibos &&
        recibos.map((recibo) => {
            return createData(
                recibo.id,
                recibo.monto,
                recibo.peso,
                recibo.pagado,
                recibo.vencimiento,
                recibo.en_voucher,
                recibo.external_id,
                recibo.support_filial
            );
        });
    const filteredRows = rows.filter(
        (row) => row.en_voucher === false && row.pagado === false
    );
    return (
        <TableContainer>
            <Table
                className={classes.table}
                size='small'
                aria-label='spaning table'
            >
                <TableHead>
                    {filteredRows.length > 0 && (
                        <TableRow>
                            <ReciboCell>N° Cuota</ReciboCell>
                            <ReciboCell>N° Recibo</ReciboCell>
                            <ReciboCell>Vencimiento</ReciboCell>
                            <ReciboCell>Acepta filial</ReciboCell>
                            <ReciboCell />
                            <ReciboCell />
                            <ReciboCell align='right'>
                                <div
                                    className='mr-2'
                                    onClick={() => console.log(newDate)}
                                >
                                    Monto a pagar
                                </div>
                            </ReciboCell>
                        </TableRow>
                    )}
                </TableHead>
                <TableBody>
                    {filteredRows
                        .sort((a, b) =>
                            new Decimal(a.peso) > new Decimal(b.peso)
                                ? 1
                                : -1 ||
                                  (a.vencimiento.isAfter(b.vencimiento)
                                      ? -1
                                      : 1)
                        )
                        .map((row, i) => (
                            <ReciboRow
                                key={row.id}
                                recibo={row}
                                divisa={divisa}
                                seguroID={id}
                                nombre={nombre}
                                index={i}
                                cuotas={filteredRows.length}
                                externalId={externalId}
                            />
                        ))}
                </TableBody>
            </Table>
        </TableContainer>
    );
};

export default RecibosContainer;
