import React from 'react';
import { useForm, $Form, $Button, $TextArea, $Select } from '@tdc-cl/x-form';
import { useMutation } from 'react-query';
import { anularVoucher } from '../../endpoints/endpoints';
import { useHistory } from 'react-router-dom';
import ActionButton from '../Buttons/ActionButton';
import { ListVoucher } from '../../types/types';
import AlertInfo from '../Alert/AlertInfo';

interface InvoiceProps {
  options: [string, string][];
  state: ListVoucher;
  setShow: (arg: boolean) => void;
  preview: string;
}
const MotivesForm: React.FC<InvoiceProps> = ({
  options,
  state,
  setShow,
  preview,
}) => {
  const history = useHistory();
  const cancelVoucher = useMutation(anularVoucher.fetch, {
    onSuccess: () => {
      history.push({
        pathname: `/contabilidad/vouchers/cancelado`,
        state: state.id,
      });
      setShow(false);
    },
  });

  const form = useForm(
    $Form({
      fields: {
        motivo: $Select('Indica un motivo y confirma para anular')
          .with({
            options: options.map(option => {
              return {
                value: option[0],
                label: option[1],
              };
            }),
            blankOption: 'Seleccionar opción',
          })
          .with({
            inputProps: {
              className:
                'px-4 text-gray700 w-full border border-gray300 rounded h-11',
            },
          }),
        otro: $TextArea('Escribe el motivo')
          .showIf(_ => _.motivo.is('Otro'))
          .with({
            inputProps: {
              className: 'border border-gray-400 w-72 p-2',
            },
          }),
      },
      submit: $Button('CONFIRMO, ANULAR', {
        async onValid(values) {
          if (preview === 'editar') {
            history.push({
              pathname: '/contabilidad/voucher/editar',
              state: {
                reason_id: values.otro ? null : Number(values.motivo.value),
                voucher: state,
                other: values.otro ?? null,
              },
            });
            setShow(false);
          } else {
            cancelVoucher.mutate({
              reason_id: values.otro ? null : Number(values.motivo.value),
              other: values.otro ?? null,
              voucher_id: state.id,
            });
          }
        },
        onInvalid: 'disable',
      }).with({
        buttonProps: {
          className:
            'bg-red400 rounded h-11 text-white font-bold text-xs px-5 sm:mt-0 flex items-center justify-center w-46 disabled:opacity-50',
        },
      }),
      props: {
        className: 'text-sm gray600',
      },
    })
  );

  return (
    <form {...form.props}>
      {form.renderFields()}
      {cancelVoucher.isError && (
        <AlertInfo
          errorMessage={`Hubo un problema al anular el voucher, por favor contacte al administrador`}
          className='text-center'
        />
      )}
      <div className='flex justify-end mt-16'>
        <div className='w-32 mr-4 '>
          <ActionButton
            variant='white'
            onClick={() => setShow(false)}
            classname='bg-gray200 hover:bg-gray250 text-gray400 border border-gray200'
          >
            Cancelar
          </ActionButton>
        </div>
        <div className='w-48'>
          <ActionButton
            isLoading={cancelVoucher.isLoading}
            onClick={() => form.submit()}
          >
            Confirmo, anular.
          </ActionButton>
        </div>
      </div>
    </form>
  );
};

export default MotivesForm;
