import React from 'react';
import {Link, useHistory} from 'react-router-dom';
import Screen from "../components/Screen/Screen";
import ActionButton from "../components/Buttons/ActionButton";

const Home = () => {
    const history = useHistory();

    return <Screen public>
        <div className='flex flex-col justify-center items-center mt-10'>

            <div
                className='bg-white border-1-5 border-gray-300 rounded shadow-lg w-4/5 md:w-2/6 flex flex-col justify-center items-center my-8 py-20'>
                <div className='mb-5 w-4/12'>
                    <img
                        src='../images/logo-color-transparent.png'
                        alt='logo'
                    />
                </div>
                <h3 className='font-bold mb-8 text-4xl text-gray700'>
                    Portal de Pagos
                </h3>
                <div className='w-8/12'>
                    <div className='p-1.5'>
                        <ActionButton
                            classname={'text-tiny'}
                            onClick={() => {
                                history.push('/persona/login');
                            }}
                        >
                            Persona Natural
                        </ActionButton>
                    </div>

                    <div className='p-1.5'>
                        <ActionButton
                            classname={'text-tiny'}
                            onClick={() => {
                                history.push('/corredor/login');
                            }}
                        >
                            Corredor
                        </ActionButton>
                    </div>

                    <div className='p-1.5'>
                        <ActionButton
                            classname={'text-tiny'}
                            onClick={() => {
                                history.push('/contabilidad/login');
                            }}
                        >
                            Contabilidad
                        </ActionButton>
                    </div>
                    <div className='p-1.5'>
                        <ActionButton
                            classname={'text-tiny'}
                            onClick={() => {
                                history.push('/cobrador/login');
                            }}
                        >
                            Cobrador
                        </ActionButton>
                    </div>
                </div>
            </div>
            <small className='gray400 mb-16'>
                MAPFRE &#169; 2021 Todos los derechos reservados
            </small>
        </div>
    </Screen>
};

export default Home;
