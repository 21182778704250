import React from 'react';
import iconTrash from '../../../svgs/iconTrash.svg';
import { deleteCuentaSource } from '../../../endpoints/endpoints';
import { useMutation } from 'react-query';
import Spinner from '../../Loader/Spinner';

const DeleteAccountBtn: React.FC<{
    id: number;
    onChange: (value: any) => void;
}> = ({ id, onChange }) => {
    const deleteAccountQuery = useMutation(
        deleteCuentaSource.url,
        deleteCuentaSource.fetch,
        {
            onSuccess: () => {
                onChange(undefined);
            },
            onSettled: () => {
                onChange(undefined);
            },
        }
    );

    return (
        <div className='ml-auto flex'>
            <span
                className='h-9 w-10 rounded bg-red400 border border-red400 cursor-pointer  flex items-center justify-center'
                onClick={(e: any) => {
                    e.stopPropagation();
                    deleteAccountQuery.mutate(id);
                }}
            >
                {deleteAccountQuery.isLoading ? (
                    <Spinner />
                ) : (
                    <img src={iconTrash} alt='trash icon' />
                )}
            </span>
        </div>
    );
};

export default DeleteAccountBtn;
