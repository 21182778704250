import React from 'react';
import { Link } from 'react-router-dom';

interface ButtonProps {
  link: string;
}

const LinkButton: React.FC<ButtonProps> = ({ link, children }) => {
  return (
    <Link
      to={link}
      className='flex justify-center items-center cursor-pointer h-10 text-center font-semibold text-xs rounded bg-red400 border text-white border-red400 disabled:opacity-50 focus:outline-none'
    >
      {children}
    </Link>
  );
};

export default LinkButton;
