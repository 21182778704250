import React from 'react';
import DeleteAccountBtn from './DeleteAccountBtn';

export interface FacturaCuentas {
    id: number;
    banco?: string;
    tipo?: string;
    rut?: string;
    cod_cia?: string;
    onChange(value: any): void;
    selected?: number;
    direccion?: string;
}
const BankAccount: React.FC<FacturaCuentas> = ({
    id,
    banco,
    tipo,
    rut,
    cod_cia,
    onChange,
    selected,
    direccion,
}) => {
    return (
        <div
            className={`cursor-pointer border rounded-md flex text-2xs md:text-sm lg:text-base h-20 px-11 whitespace-pre-wrap items-center w-full mb-3  ${
                selected === id
                    ? 'border-red400 bg-redLight border-l-6'
                    : 'border-gray200 '
            }`}
            onClick={() => {
                onChange(id);
            }}
        >
            <div className='flex items-center justify-center mr-8'>
                <div>
                    <span
                        className={`w-5 h-5 rounded-full border-2 flex justify-center items-center ${
                            selected === id ? 'border-red400' : 'border-gray300'
                        }`}
                    >
                        <span
                            className={`transition-all duration-100 block rounded-full bg-red400 ${
                                selected === id ? 'w-3 h-3' : 'w-0 h-0'
                            }`}
                        />
                    </span>
                </div>
                {/*  <input
                    type='radio'
                    value={id}
                    name={name}
                    checked={selected === id}
                    className='text-5xl'
                /> */}
            </div>
            {!direccion && (
                <>
                    {' '}
                    <div className='ml-2 lg:pl-0 flex flex-col justify-center items-start'>
                        <div>
                            <span className='text-2xs gray300 text-left'>
                                Rut Titular
                            </span>
                            <p className='text-xs font-bold text-black lg:whitespace-nowrap'>
                                {rut}
                            </p>
                        </div>
                    </div>
                    <div className='pl-3 flex flex-col justify-center ml-2 lg:ml-3'>
                        <span className='text-2xs gray300'>Banco</span>
                        <p className='text-2xs lg:text-xs font-bold text-black'>
                            {banco}
                        </p>
                    </div>
                    <div className='lg:pl-3 flex flex-col justify-center ml-2 lg:ml-3'>
                        <span className='text-2xs gray300 lg:whitespace-nowrap'>
                            Tipo de cuenta
                        </span>
                        <p className='text-2xs lg:text-xs font-bold text-black'>
                            {tipo?.toLocaleUpperCase()}
                        </p>
                    </div>
                </>
            )}
            {direccion && (
                <div className='ml-2 flex flex-col justify-center'>
                    <span className='text-2xs gray300'>Dirección</span>
                    <p className='text-xs font-bold text-black'>{direccion}</p>
                </div>
            )}
            {!cod_cia && <DeleteAccountBtn id={id} onChange={onChange} />}
        </div>
    );
};

export default BankAccount;
