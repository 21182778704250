import React from 'react';
import { NavLink } from 'react-router-dom';
import { useRol } from '../../customHooks/customHooks';
import { useClearSettings } from '../../customHooks/useClearSettings';
const CategoriesNavbar = () => {
    const rol = useRol();

    const clearSettings = useClearSettings();

    return (
        <div className='flex'>
            {rol !== 'contabilidad' && (
                <NavLink
                    to={`/${rol}/portal-de-pagos/seguros`}
                    activeClassName='border-b-4 border-red400 text-gray600 font-bold'
                    onClick={clearSettings}
                >
                    <div className='p-3 md:p-6'> Mis seguros</div>
                </NavLink>
            )}
            <NavLink
                to={`/${rol}/portal-de-pagos/vouchers`}
                activeClassName='border-b-4 border-red400 text-gray600 font-bold'
                onClick={clearSettings}
            >
                <div className='p-3 md:p-6'>Mis Vouchers</div>
            </NavLink>
            {(rol === 'contabilidad' ||
                rol === 'agente' ||
                rol === 'cobrador') && (
                <NavLink
                    to={`/${rol}/portal-de-pagos/no-asignados`}
                    activeClassName='border-b-4 border-red400 text-gray600 font-bold'
                >
                    <div className='p-3 md:p-6'> Pagos no asignados</div>
                </NavLink>
            )}
        </div>
    );
};

export default CategoriesNavbar;
