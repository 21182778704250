import Decimal from 'decimal.js';
import { LocalDate } from 'js-joda';
import React from 'react';
import { formatCurrency } from '../../../utils/utils';
interface Props {
  title: string;
  value: Decimal | string | LocalDate | Number;
}
const VoucherInfo: React.FC<Props> = ({ title, value }) => {
  return (
    <div className='flex '>
      <p className='text-sm text-gray400 '>{title}</p>{' '}
      <span className='ml-auto text-sm text-gray700 font-medium'>
        {title === 'Total valor cuotas' && typeof value === 'number'
          ? formatCurrency(new Decimal(value), 'CLP')
          : `#${value}`}
      </span>
    </div>
  );
};

export default VoucherInfo;
