import React from 'react';
import Modal from 'react-modal';
interface ModalProps {
    isOpen: boolean;
    setIsOpen: (arg: boolean) => void;
    size?: string;
    shadow?: boolean;
    onTop?: boolean;
}
const CustomModal: React.FC<ModalProps> = ({
    isOpen,
    setIsOpen,
    children,
    size,
    shadow,
    onTop,
}) => {
    const modalSize =
        size === 'lg'
            ? '45em'
            : size === 'sm'
            ? '25em'
            : size === 'md'
            ? '30em'
            : '65em';
    const animate =
        size === 'sm' ? 'modalSlide 0.2s ease-out forwards' : 'none';
    const customStyles = {
        content: {
            top: onTop ? '40%' : '50%',
            left: '50%',
            right: 'auto',
            bottom: 'auto',
            borderRadius: 10,
            width: modalSize,
            marginRight: '-50%',
            transform: 'translate(-50%, -50%)',
            animation: animate,
            maxHeight: '520px',
            overlfow: 'scroll',
            boxShadow: shadow ? '0 2px 8px -2px rgba(0,0,0,0.5)' : 'none',
        },
        overlay: {
            backgroundColor: 'rgba(123,129,137, 70%)',
            zIndex: 10,
        },
    };

    const closeModal = () => {
        setIsOpen(false);
    };

    Modal.setAppElement('#root');

    return (
        <div className='bg-red-500'>
            <Modal
                isOpen={isOpen}
                onRequestClose={closeModal}
                style={customStyles}
            >
                {children}
            </Modal>
        </div>
    );
};

CustomModal.defaultProps = {
    size: 'lg',
};

export default CustomModal;
