import React from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import Home from './screens/Home';
import Login from './screens/Login';
import PortalPagos from './screens/PortalPagos';
import CheckoutInsurance from './screens/CheckoutInsurance';
import PayCompleted from './screens/PayCompleted';
import InicioCorredor from './screens/InicioCorredor';
import MyPayments from './screens/MyPayments';
import VoucherDetail from './screens/VoucherDetail';
import { XFormContext, spanish } from '@tdc-cl/x-form';
import CanceledConfirmed from './screens/CanceledConfirmed';
import EditVoucher from './screens/EditVoucher';
import PaymentErrors from './components/Corredor/PaymentErrors';
import DeleteVoucher from './screens/DeleteVoucher';
import NotFound from './screens/NotFound';
import {ResetPwd} from "./screens/ResetPwd";
import {ResetPwdRequest} from "./screens/ResetPwdRequest";

const Layout = () => {
    return (
        <BrowserRouter>
            <XFormContext.Provider value={{ locale: spanish }}>
                <Switch>
                    <Route
                        exact
                        path='/:rol(persona|corredor)/portal-de-pagos/:paso(seguros)/'
                        component={PortalPagos}
                    />
                    <Route
                        exact
                        path='/:rol(persona|contabilidad|corredor|cobrador)/login'
                        component={Login}
                    />
                    <Route
                        exact
                        path='/:rol(contabilidad|cobrador)/reset-pwd-request'
                        component={ResetPwdRequest}
                    />
                    <Route
                        exact
                        path='/reset-password'
                        component={ResetPwd}
                    />
                    <Route
                        exact
                        path='/:rol(persona|corredor)/:paso(checkout|partes-pago)'
                        component={CheckoutInsurance}
                    />
                    <Route
                        exact
                        path='/:rol(contabilidad)/voucher/editar'
                        component={EditVoucher}
                    />
                    <Route
                        exact
                        path='/:rol(contabilidad)/portal-de-pagos/no-asignados'
                        component={PaymentErrors}
                    />
                    <Route
                        exact
                        path='/:rol(persona|corredor|contabilidad)/voucher'
                        component={PayCompleted}
                    />
                    <Route
                        exact
                        path='/corredor/partes/:pago'
                        component={MyPayments}
                    />
                    <Route
                        exact
                        path='/:rol(contabilidad)/portal-de-pagos/anular-voucher'
                        component={DeleteVoucher}
                    />
                    <Route
                        exact
                        path='/:rol(contabilidad)/vouchers/cancelado/'
                        component={CanceledConfirmed}
                    />
                    <Route
                        exact
                        path='/:rol(contabilidad)/:step(voucher-detail)/'
                        component={VoucherDetail}
                    />
                    <Route
                        exact
                        path='/:rol(persona|contabilidad|corredor)/portal-de-pagos/vouchers/:page?'
                        component={MyPayments}
                    />
                    <Route
                        exact
                        path='/:rol(corredor)/inicio'
                        component={InicioCorredor}
                    />
                    <Route exact path='/' component={Home} />
                    <Route
                        exact
                        path='/:rol(contabilidad)/vouchers/finalizado/'
                    >
                        <CanceledConfirmed finalizado />
                    </Route>
                    <Route render={NotFound} />
                </Switch>
            </XFormContext.Provider>
        </BrowserRouter>
    );
};

export default Layout;
