import React, { useState } from 'react';
import InsuranceInfo from './InsuranceInfo';
import { Poliza } from '../../types/types';
import RecibosContainer from './RecibosContainer';
import { LocalDate } from 'js-joda';

const InsuranceCard: React.FC<Poliza> = ({
    divisa,
    id,
    nombre,
    recibos,
    external_id,
}) => {
    const [showMore, setShowMore] = useState(false);
    const transitionStyle = {
        opacity: `${showMore ? '1' : '0.3'}`,
        maxHeight: `${showMore ? '5000px' : '0px'}`,
        maxWidth: `${showMore ? '2000px' : '0px'}`,
        transition: `${showMore && 'max-height 1s, opacity 0.4s'}`,
    };
    const currentDate = LocalDate.now();
    const isExpired = recibos.some(
        (recibo) =>
            recibo.vencimiento &&
            !recibo.pagado &&
            !recibo.en_voucher &&
            currentDate.isAfter(LocalDate.parse(recibo.vencimiento.toString()))
    );
    return (
        <div
            className={`bg-calcite border-md border border-gray250 rounded mb-3 ${
                showMore ? 'pb-3' : ''
            } ${isExpired && 'expired-insurance'}`}
        >
            <InsuranceInfo
                nombre={nombre}
                divisa={divisa}
                id={external_id}
                recibos={recibos}
                showMore={showMore}
                setShowMore={setShowMore}
            />
            <div style={transitionStyle}>
                <RecibosContainer
                    recibos={recibos}
                    divisa={divisa}
                    id={id}
                    externalId={external_id}
                    nombre={nombre}
                />
            </div>
        </div>
    );
};

export default InsuranceCard;
