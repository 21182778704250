import { Server, Bearer, JsonStorage, Endpoint } from '@tdc-cl/async-stuff';
import { any, array } from 'superstruct';
import { models } from '../models';
import {
    CreateClientBankAccount,
    CreatePayerSetting,
    CreateVoucher,
    FilialAppendResponse,
    LoginResponse,
    PartitionResponse,
    ListVoucher,
    VoucherPagination,
} from '../types/types';
import { Environment } from "../utils/Environment";

export const apiURL = Environment.getString('REACT_APP_DOMAIN');

export const authStorage = new JsonStorage(
    'authTokenMapfre',
    models.LoginResponse()
);

export const userStorage = new JsonStorage(
    'userInfoMapfre',
    models.User() as any
);

export const server = new Server(apiURL, {
    headers: () => Bearer(authStorage.get()?.token),
});

/*                 VOUCHERS                  */
export const voucherPersonaPage = server.endpoint.getAll(
    'voucher/persona/?page=',
    models.VoucherPagination()
);

export const fetchContabilidadVouchers = server.endpoint.get(
    'voucher',
    models.VoucherPagination()
);

export const fetchVouchers = new Endpoint<[number, string], VoucherPagination>({
    server,
    method: 'GET',
    path: 'voucher/:id?page=:page',
    urlWithParams: (url: string, id: number, page: string) =>
        url.replace(':id', id.toString()).replace(':page', page.toString()),
    struct: models.VoucherPagination() as any,
});

export const fetchDetailVoucher = server.endpoint.get<number, ListVoucher>(
    'voucher_detail',
    models.ListVoucher() as any
);

/*              CLIENTES CORREDOR             */

export const clientesCorredor = server.endpoint.getAll(
    '/clientes/corredor/',
    array(models.User())
);

/*                         POLIZAS                      */
export const documentosPolizas = server.endpoint.get(
    'documentos/polizas',
    array(models.Poliza())
);

/*               PARTES CORREDOR              */

export const particionarEmpresa = server.endpoint.post<
    {
        filial_payer_settings: number[];
        non_filial_payer_setting: number | null;
        documents: number[];
    },
    PartitionResponse
>('voucher/particionar/empresa/', models.PartitionResponse());

/*                         BUSCAR FILIALES                      */
export const filialesCliente = server.endpoint.get(
    'factura/filiales/',
    array(models.Filial())
);

/*                         BUSCAR FILIALES                      */
export const ultimosClientes = server.endpoint.getAll(
    'voucher/ultimas-transacciones',
    array(models.User())
);

/*                 GENERAR SETTINGS FILIALES CORREDOR                  */

export const generarSettings = server.endpoint.post<
    CreatePayerSetting,
    FilialAppendResponse
>('factura/pagadores/generar-setting', models.FilialAppendResponse());

/*                 GENERAR VOUCHER CORREDOR                  */

export const generarVoucherCorredor = server.endpoint.post<
    {
        partition_id: number;
    },
    ListVoucher
>('voucher/generar/empresa/', models.ListVoucher() as any);

/*                    FACTURA PREVIEW                   */

export const facturaPreview = server.endpoint.get(
    'factura/preview',
    array(models.FacturaPreview())
);

/*                 FILIALES                  */

export const filialCliente = server.endpoint.getAll(
    'factura/filiales/2',
    array(models.Filial())
);

/*                 PARTES PERSONA                  */

export const particionarPersona = server.endpoint.post<
    {
        documents: number[];
    },
    PartitionResponse
>('voucher/particionar/persona/', models.PartitionResponse());

/* CUENTAS Y DIRECCIONES PERSONA */

export const cuentasMapfre = server.endpoint.get(
    'factura/cuentas-mapfre',
    array(models.MapfreAccount())
);

export const cuentasPersona = server.endpoint.get(
    '/factura/list-cuentas-cliente/',
    array(models.ListClientBankAccount())
);

export const direccionesPersona = server.endpoint.get(
    'factura/direciones/',
    array(models.Direccion())
);

/*                CUENTAS Y DIRECCIONES PERSONA                 */

export const crearCuenta = server.endpoint.post<
    CreateClientBankAccount,
    {
        id: number;
        banco: string;
        tipo: string;
        rut: string;
        n_cuenta: string;
        email: string;
    }
>('/factura/cuentas-cliente/', models.CreateClientBankAccount());

/*                      GENERAR VOUCHER                   */

export const generateVoucher = server.endpoint.post<CreateVoucher, ListVoucher>(
    'voucher/generar/',
    models.ListVoucher() as any
);

/*                         ANULAR FACTURA                      */

export const anularVoucher = server.endpoint.post<
    {
        voucher_id: number;
        reason_id: number | null;
        other: string;
    },
    {}
>('voucher/contabilidad/anular/', models.AnularResponse());

/*                           ANULAR Y REEMPLAZAR                           */

export const reemplazarVoucher = server.endpoint.post<
    {
        voucher_id: number;
        reason_id: number | null;
        other: string;
        new_partition: {
            partition_part_id: number;
            direccion: number;
            cuenta_source: number;
            cuenta_target: number;
        }[];
    },
    ListVoucher
>('/voucher/contabilidad/anular-reemplazar/', models.ListVoucher() as any);

/*            FACTURA CONFIRMAR          */

export const confirmarFacturaParte = server.endpoint.post<
    {
        voucher: number;
    },
    {}
>('factura/confirmar', models.AnularResponse());

/*            CONTABILIDAD FILIALES SETTING          */

export const filialSettingContabilidad = server.endpoint.get(
    '/factura/pagadores/contabilidad/voucher-data/',
    models.ContabilidadVoucherData() as any
);

/*            CONTABILIDAD FILIALES SETTING          */

export const lastSettings = server.endpoint.get(
    '/factura/pagadores/ultimo-setting/',
    models.LastSettings() as any
);

/*            LOGIN PERSONA          */

export const loginPersona = server.endpoint.post<
    {
        rut: string;
        recaptcha: string;
    },
    LoginResponse
>('login/persona', models.LoginResponse());

/*            LOGIN CONTABILIDAD          */

export const loginContabilidad = server.endpoint.post<
    {
        rut: string;
        password: string;
        recaptcha: string;
    },
    LoginResponse
>('login/contabilidad', models.LoginResponse());

/*            LOGIN CORREDOR          */

export const loginCorredor = server.endpoint.post<
    {
        rut: string;
        password: string;
        recaptcha: string;
    },
    LoginResponse
>('login/corredor', models.LoginResponse());

/*            LOGIN COBRADOR          */

export const loginCobrador = server.endpoint.post<
    {
        rut: string;
        password: string;
        recaptcha: string;
    },
    LoginResponse
>('login/cobrador', models.LoginResponse());

/*            USER FROM LOGIN          */

export const userLogin = server.endpoint.get('users/', models.User() as any);

/*          ERRORES PAGO CONTABILIDAD         */

export const unassignedPayments = server.endpoint.getAll(
    '/voucher/contabilidad/pagos-no-asignados/',
    array(models.NotAssigned())
);

export const voucherError = server.endpoint.get(
    'voucher/contabilidad/voucher-errores/',
    array(models.PaymentError())
);

export const deleteCuentaSource = server.endpoint.delete(
    'factura/cuentas-cliente',
    any()
);

export const facturaReference = server.endpoint.getAll(
    'factura/references',
    array(models.References())
);

export const divisaRates = server.endpoint.get('/rates/', models.Rates());

export const endVoucher = server.endpoint.post<{ voucher_id: number }, any>(
    'voucher/contabilidad/finalizar/',
    any()
);


/*            RESET PWD REQUEST          */

export const resetPasswordRequest = server.endpoint.post<
    {
        rut: string;
        recaptcha: string;
    },
    any
>('password_reset_request', any());


/*            RESET PWD          */

export const resetPassword = server.endpoint.post<
    {
        token: string;
        password: string;
        recaptcha: string;
    },
    any
>('password_reset', any());