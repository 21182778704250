import Decimal from 'decimal.js';
import React, { useState } from 'react';
import { ListVoucherPart } from '../../../types/types';
import { formatCurrency } from '../../../utils/utils';
import circleDown from '../../../svgs/circle-ctrl-down.svg';

const VoucherDetailPersona: React.FC<{
    parte: ListVoucherPart;
    index: number;
}> = ({ parte: { id, partition_part, pago_data }, index }) => {
    const [showMore, setShowMore] = useState(false);
    const toggleShow = () => {
        if (showMore) {
            setShowMore(false);
        } else {
            setShowMore(true);
        }
    };
    const [isHovered, setIsHovered] = useState(false);
    return (
        <div className={`mb-3 text-gray-500  `}>
            <div
                className='flex justify-between border-b cursor-pointer border-gray200 font-bold text-black'
                onClick={toggleShow}
                onMouseEnter={() => setIsHovered(true)}
                onMouseLeave={() => setIsHovered(false)}
            >
                <span>Parte {id}</span>
                <div className='flex items-center mb-3'>
                    <span>
                        {formatCurrency(
                            new Decimal(
                                (partition_part as any)[
                                    partition_part.monto_to_show
                                ].monto
                            ),
                            (partition_part as any)[
                                partition_part.monto_to_show
                            ].divisa
                        )}
                    </span>
                    <div
                        className={`w-5 h-5 rounded-full ${
                            isHovered && 'bg-gray200'
                        } flex justify-center ml-3 items-center`}
                    >
                        <img
                            src={circleDown}
                            className={`${showMore && 'rotate-180'}`}
                            alt=''
                        />
                    </div>
                </div>
            </div>
            {showMore && (
                <div>
                    <div className='text-xs mt-4 flex flex-col leading-5'>
                        <span className='text-black mb-2 font-semibold'>
                            Cuenta bancaria origen
                        </span>
                        <span>{pago_data.cuenta_source.banco}</span>
                        <span>
                            {pago_data.cuenta_source.tipo.toLocaleUpperCase()}
                        </span>
                        <span>{pago_data.cuenta_source.rut}</span>
                        <span>
                            Número de Cuenta: {pago_data.cuenta_source.n_cuenta}
                        </span>
                    </div>
                    <div className='text-xs mt-4 flex flex-col leading-5'>
                        <span className='text-black mb-2 font-semibold'>
                            Cuenta bancaria destino
                        </span>
                        <span>{pago_data.cuenta_target.banco}</span>
                        <span>
                            {pago_data.cuenta_target.tipo.toLocaleUpperCase()}
                        </span>
                        <span>{pago_data.cuenta_target.rut}</span>
                        <span>
                            Número de Cuenta: {pago_data.cuenta_target.n_cuenta}
                        </span>
                    </div>
                    <div className='text-xs mt-4 flex flex-col'>
                        <span className='text-black mb-2 font-semibold'>
                            Dirección de facturación{' '}
                        </span>
                        <span>{pago_data.direccion.direccion}</span>
                    </div>
                </div>
            )}
        </div>
    );
};

export default VoucherDetailPersona;
