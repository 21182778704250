import React from 'react';
import { StyledTableCell } from '../PaymentsTable/TableStyle';

const ErrorHeader = () => {
    return (
        <>
            <StyledTableCell align='left'>CUENTA DE ORIGEN</StyledTableCell>
            <StyledTableCell align='left'>CUENTA DESTINO</StyledTableCell>
            <StyledTableCell align='left'>MONTO</StyledTableCell>
            <StyledTableCell align='left'>FECHA</StyledTableCell>
        </>
    );
};

export default ErrorHeader;
