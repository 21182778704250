import { useAtom } from 'jotai';
import { LocalDate } from 'js-joda';
import React from 'react';
import { payListAtom } from '../../store/Atoms';
import { formatCurrency, formatDate } from '../../utils/utils';
import _ from 'lodash';
import { Divisa } from '../../types/types';
import iconAdd from '../../svgs/iconAdd.svg';
import { TableRow } from '@material-ui/core';
import { Recibo } from '../../types/alias';
import TableCell from '@material-ui/core/TableCell';
import { withStyles, Theme, createStyles } from '@material-ui/core/styles';
import { useBoolean } from '../../customHooks/useBoolean';
import ReactTooltip from 'react-tooltip';

interface ReciboRowProps {
  recibo: Recibo;
  divisa: Divisa;
  seguroID: number;
  nombre: string;
  index: number;
  cuotas: number;
  externalId: string;
}

const ReciboRow: React.FC<ReciboRowProps> = ({
  recibo: {
    id,
    monto,
    pagado,
    vencimiento,
    peso,
    en_voucher,
    external_id,
    support_filial,
  },
  divisa,
  nombre,
  index,
  cuotas,
  externalId,
}) => {
  const currentDate = LocalDate.now();
  const expired =
    !pagado && currentDate.isAfter(LocalDate.parse(vencimiento.toString()));

  const [list, setList] = useAtom(payListAtom);

  const idExist = list.some(item =>
    item.recibos.some(recibo => recibo.id === id)
  );

  const handleAdd = () => {
    if (!idExist) {
      if (
        _.some(list, { id: externalId, included: [index - 1] }) ||
        index === 0
      ) {
        if (!_.some(list, { id: externalId })) {
          const nuevoDocumento = [
            ...list,
            {
              nombre,
              id: externalId,
              divisa,
              included: [index],
              recibos: [
                {
                  id,
                  monto,
                  pagado,
                  vencimiento,
                  peso,
                  en_voucher,
                  index,
                  external_id,
                  support_filial,
                },
              ],
            },
          ];
          setList(nuevoDocumento);
          localStorage.setItem('payListMapfre', JSON.stringify(nuevoDocumento));
        } else {
          const insuranceToAdd = list.find(item => item.id === externalId);
          const nuevoRecibo = [
            ...list.filter(items => items.id !== insuranceToAdd!.id),
            {
              ...insuranceToAdd!,
              included: [...insuranceToAdd!.included, index],
              recibos: [
                ...insuranceToAdd!.recibos,
                {
                  id,
                  monto,
                  pagado,
                  vencimiento,
                  peso,
                  en_voucher,
                  external_id,
                  support_filial,
                },
              ],
            },
          ];
          setList(nuevoRecibo);
          localStorage.setItem('payListMapfre', JSON.stringify(nuevoRecibo));
        }
      }
    }
  };
  const ReciboCell = withStyles((theme: Theme) =>
    createStyles({
      head: {
        backgroundColor: '#F3F3F3',
        color: '#616664',
        fontSize: 13,
        fontWeight: 'bold',
        paddingTop: 18,
        paddingBottom: 18,
        border: 'none',
        fontFamily: 'DMSans',
      },
      body: {
        fontSize: 14,
        color: '#2D373D',
        paddingTop: 10,
        paddingBottom: 10,
        backgroundColor: hovered.value ? '#EEEEEE' : '#fff',
        fontWeight: 500,
        border: 'none',
        fontFamily: 'DMSans',
      },
    })
  )(TableCell);

  const hovered = useBoolean(false);
  const notPreviousAdded =
    !_.some(list, {
      id: externalId,
      included: [index - 1],
    }) && index !== 0;
  return (
    <TableRow
      hover
      className={` ${idExist && 'pointer-events-none opacity-50'}`}
      onMouseEnter={() => hovered.setValue(true)}
      onMouseLeave={() => hovered.setValue(false)}
    >
      <ReciboCell>
        <span className={` ${expired && 'text-orange400'}`}>
          {' '}
          {index + 1}/{cuotas}
        </span>
      </ReciboCell>
      <ReciboCell>
        {' '}
        <span className={` ${expired && 'text-orange400'}`}>
          {' '}
          {external_id}
        </span>
      </ReciboCell>
      <ReciboCell>
        <div className={`flex items-center ${expired && 'text-orange400'}`}>
          {formatDate(vencimiento)}
          {expired && (
            <div className='flex items-center justify-center ml-1 bg-orange300 w-4 h-4 rounded-full'>
              <span className='text-white text-xs'>&#33;</span>
            </div>
          )}
        </div>
      </ReciboCell>
      <ReciboCell>{support_filial ? 'Sí' : 'No'}</ReciboCell>
      <ReciboCell />
      <ReciboCell />
      <ReciboCell align='right'>
        <ReactTooltip
          id='info'
          place='top'
          className='text-xs px-1 p-0 mb-4 font-light h-5'
        >
          Debe añadir la cuota anterior
        </ReactTooltip>
        <div className='flex justify-end'>
          <div
            className={`font-bold bg-gray100 px-1 min-w-6 h-10 border border-gray200 rounded-l flex justify-center items-center text-black ${
              expired && 'text-orange400'
            }`}
            onClick={() => console.log(list)}
          >
            {formatCurrency(monto, divisa)}
          </div>
          <button
            data-tip
            data-for={`${notPreviousAdded ? 'info' : ''}`}
            className={`bg-red400 min-w-6 h-10 rounded-l-none rounded-r-md text-white text-tiny flex justify-center items-center focus:outline-none ${
              notPreviousAdded
                ? 'opacity-60 cursor-default bg-gray300'
                : 'bg-red400 cursor-pointer hover:bg-red600'
            }`}
            onClick={() => {
              if (notPreviousAdded) {
                return null;
              } else {
                handleAdd();
              }
            }}
            disabled={idExist && true}
          >
            {' '}
            Añadir
            <img src={iconAdd} className='w-4 ml-2' alt='icon add' />
          </button>
        </div>
      </ReciboCell>
    </TableRow>
  );
};

export default ReciboRow;
