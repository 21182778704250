export class Environment {
    // noinspection JSUnusedLocalSymbols
    private constructor() {}

    static getString(name: string): string {
        if (!name.startsWith('REACT_APP_')) {
            throw new Error(`Environment variable "${name}" must start with "REACT_APP_"`);
        }

        const value = process.env[name];
        if (value === undefined) {
            throw new Error(`Environment variable "${name}" not found`);
        }

        return value;
    }
}