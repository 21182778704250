import Decimal from 'decimal.js';
import { useAtom } from 'jotai';
import React from 'react';
import { payListAtom } from '../../store/Atoms';
import { Divisa } from '../../types/types';
import { formatCurrency } from '../../utils/utils';

const TotalDivisa: React.FC<{ divisa: Divisa }> = ({ divisa }) => {
    const [list] = useAtom(payListAtom);
    const total =
        list
            .filter((item) => item.divisa === divisa)
            .map((item) => item.recibos.map((recibo) => recibo.monto))
            .flat(1)
            .reduce((a, b) => a.add(b), new Decimal(0)) ?? new Decimal(0);
    return (
        <div
            className={`flex items-center ${
                total.equals(new Decimal(0)) && 'hidden'
            }`}
        >
            <p
                className='text-2xl text-gray700'
                onClick={() => console.log(total, new Decimal(0))}
            >
                {' '}
                {divisa === 'CLP' && 'CLP '}
                {formatCurrency(total, divisa)}
            </p>
        </div>
    );
};

export default TotalDivisa;
