import { MutableRefObject, useEffect } from 'react';

function useClickOutside<T extends HTMLElement>(
    ref?: MutableRefObject<T | null>,
    handler?: (event: any) => void,
    trigger?: MutableRefObject<T | null>
) {
    useEffect(() => {
        const listener = (event: any) => {
            if (ref && handler && trigger) {
                if (
                    !ref.current ||
                    ref.current.contains(event.target) ||
                    !trigger.current ||
                    trigger.current.contains(event.target)
                ) {
                    return;
                }

                handler(event);
            }
        };

        document.addEventListener('mousedown', listener);
        document.addEventListener('touchstart', listener);

        return () => {
            document.removeEventListener('mousedown', listener);
            document.removeEventListener('touchstart', listener);
        };
    }, [ref, handler, trigger]);
}

export { useClickOutside };
