import React, { useEffect } from 'react';
import { Link, useHistory, useLocation } from 'react-router-dom';
import { useRol } from '../../../customHooks/customHooks';
import Decimal from 'decimal.js';
import { PartitionResponse } from '../../../types/types';
import { useAtom } from 'jotai';
import {
  clienteCorredorAtom,
  porcentajeTotalAtom,
  voucherInfoAtom,
} from '../../../store/Atoms';
import AnticipadoBadge from '../../Badges/AnticipadoBadge';
import PartsDetail from './PartsDetail';
import arrowLeft from '../../../svgs/arrowLeft.svg';

const CorredorCheckout: React.FC = () => {
  const rol = useRol();
  const location = useLocation();
  const [cliente] = useAtom(clienteCorredorAtom);
  const history = useHistory();
  const { state } = location as { state: PartitionResponse };
  const partPercentage = (data: PartitionResponse, ammount: number) => {
    const total: number = data.partes.reduce(
      (item1, item2) => item1 + (item2 as any)[item2.monto_to_show].monto,
      0
    );
    return (ammount / total) * 100;
  };
  const [, setPorcentajeTotal] = useAtom(porcentajeTotalAtom);
  const [voucherInfoPersona, setVoucherInfo] = useAtom(voucherInfoAtom);

  useEffect(() => {
    if (!location.state) {
      history.push(`/${rol}/portal-de-pagos/seguros`);
    }
    setPorcentajeTotal(new Decimal(0));
    setVoucherInfo({
      partes: state?.partes.map(part => {
        return {
          partition_part_id: part.id,
          direccion: 0,
          cuenta_source: undefined,
          cuenta_target: 0,
        };
      }),
      factura_mode: rol === 'persona' ? null : voucherInfoPersona.factura_mode,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.state, history, rol, state]);
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div>
      <div onClick={history.goBack} className='cursor-pointer mb-4'>
        <img src={arrowLeft} alt='arrow icon' className='inline mr-3' />
        <p className='inline'>Volver a información de recibos</p>
      </div>
      <div className='bg-calcite rounded-md lg:mr-5 border border-gray250 px-8 mb-6 py-4'>
        <div className={'flex items-center justify-between'}>
          <div>
            <h5 className='gray600 mr-5 font-bold text-lg'>
              {cliente && cliente.nombre}
            </h5>
            <small className='text-xs text-gray400 font-light'>
              RUT {cliente && cliente.rut.slice(0, cliente.rut.length - 1)}-
              {cliente && cliente.rut[cliente.rut.length - 1]}
            </small>
          </div>
          {cliente && cliente.facturacion_anticipada && (
            <div>
              {' '}
              <AnticipadoBadge />
            </div>
          )}
        </div>
      </div>
      <div className='bg-calcite border border-gray-250 lg:mr-5 rounded-lg'>
        <p className='text-lg font-bold px-8 py-5 border-b border-gray250'>
          Confirma la información de cada parte del voucher para finalizar
        </p>
        <div className='px-8 mt-8'>
          {!!state &&
            state.partes.map((parte, i) => {
              return (
                <PartsDetail
                  part={parte}
                  percentage={partPercentage(
                    state,
                    (parte as any)[parte.monto_to_show].monto
                  )}
                  client_id={cliente.id}
                  key={i}
                />
              );
            })}
        </div>
      </div>
    </div>
  );
};

export default CorredorCheckout;
