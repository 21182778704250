import { CustomField, Invalid, Valid } from '@tdc-cl/x-form';
import { regex } from './$ValidEmail';
import _ from 'lodash';

export const $ArrayEmails = CustomField<string, string[]>({
  blankInput: '',
  preprocess: (input) => input.replace(/ /g, ';'),
  parse: (input: string) =>
    Valid(input.split(';').filter((mail) => mail !== '')),
  validate: (value) => {
    if (value.every((email) => regex.test(email))) {
      return Valid(value);
    } else {
      return Invalid('Alguno de los correos ingresados no es válido');
    }
  },
  acceptExternal: (data) => {
    if (_.isArray(data)) {
      return data.join(';');
    }
  },
});
