import { useJsonStorage } from '@tdc-cl/async-stuff';
import React, { useState, useEffect } from 'react';
import { useQuery } from 'react-query';
import { useHistory, useLocation } from 'react-router-dom';
import { useRol } from '../../customHooks/customHooks';
import { authStorage, userLogin, userStorage } from '../../endpoints/endpoints';
import CategoriesNavbar from './CategoriesNavbar';
import LogoNavbar from './LogoNavbar';
import UserInfoNavbar from './UserInfoNavbar';
import { LoginResponse, User } from '../../types/types';

const Navbar = () => {
    const [showMore, setShowMore] = useState(false);
    const user: LoginResponse = useJsonStorage(authStorage)!;
    const history = useHistory();
    const rol = useRol();
    const { data, isError } = useQuery(
        [userLogin.url, user?.id],
        () => userLogin.fetch(user!.id),
        {
            onSuccess: (data: User) => userStorage.set(data),
        }
    );
    const location = useLocation();
    const isCheckout = location.pathname.includes('checkout');

    useEffect(() => {
        if (!user) {
            history.push(`/${rol}/login`);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <nav className='pt-4 pb-2 lg:pb-0 lg:pt-0 px-8 fixed w-full z-10 top-0 lg:flex gray600 border-t border-b border-gray-400 bg-white z-75 shadow text-sm min-h-6'>
            <div className='flex md:items-center justify-between items-center'>
                <LogoNavbar />

                {!isCheckout && (
                    <>
                        {' '}
                        <div className='block lg:hidden'>
                            {!!data && (
                                <p className='font-bold mr-2 text-black'>
                                    {data.nombre.toUpperCase()}
                                </p>
                            )}
                            {isError && (
                                <p>
                                    Hubo un problema. <br /> Por favor contacta
                                    con el administrador
                                </p>
                            )}
                        </div>
                        <div className='block lg:hidden'>
                            <button
                                className='flex items-center px-3 py-2 border rounded text-teal-lighter border-teal-light hover:text-white hover:border-white focus:outline-none'
                                onClick={() =>
                                    showMore
                                        ? setShowMore(false)
                                        : setShowMore(true)
                                }
                            >
                                <svg
                                    className='h-3 w-3'
                                    viewBox='0 0 20 20'
                                    xmlns='http://www.w3.org/2000/svg'
                                >
                                    <title>Menu</title>
                                    <path d='M0 3h20v2H0V3zm0 6h20v2H0V9zm0 6h20v2H0v-2z' />
                                </svg>
                            </button>
                        </div>
                    </>
                )}
            </div>
            {!isCheckout && (
                <div
                    className={`w-full flex-grow mt-5 md:mt-0 lg:flex lg:items-center lg:w-auto lg:pt-0 ${
                        !showMore && 'hidden'
                    }`}
                >
                    <ul className='list-reset flex flex-1 items-center'>
                        <li className='lg:ml-2'>
                            <CategoriesNavbar />
                        </li>
                        <li className='lg:mr-3 ml-auto'>
                            <UserInfoNavbar />
                        </li>
                    </ul>
                </div>
            )}
        </nav>
    );
};

export default Navbar;
