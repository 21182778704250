import React, { useRef, useState } from 'react';
import { useQuery } from 'react-query';
import { clientesCorredor } from '../../endpoints/endpoints';
import { User } from '../../types/types';
import SearchCliente from '../SearchBar/SearchCliente';
import { equalizeSearch } from '../../utils/utils';
import Loader from '../Loader/Loader';
import AlertInfo from '../Alert/AlertInfo';
import { useBoolean } from '../../customHooks/useBoolean';

const Searcher: React.FC<{
    chooseCompany: (arg: User) => void;
}> = ({ chooseCompany }) => {
    const [searchTerm, setSearchTerm] = useState('');
    const setSearched = useBoolean(false);
    const [clientes, setClientes] = useState<User[]>([]);

    const getClientes = useQuery(
        clientesCorredor.url,
        () => clientesCorredor.fetch(),
        {
            onSuccess: (data) => {
                setClientes(data);
            },
        }
    );

    const filteredList = clientes.filter(
        (cliente) =>
            cliente.tipo !== 'cliente' &&
            (equalizeSearch(cliente.nombre).startsWith(
                equalizeSearch(searchTerm)
            ) ||
                equalizeSearch(cliente.rut).startsWith(
                    equalizeSearch(searchTerm)
                ))
    );
    const optionsRef = useRef(null);
    const [hoveredRow, setHoveredRow] = useState<number | undefined>(undefined);

    return (
        <div className='p-16 mt-14 bg-red400 text-white'>
            <h4 className='font-medium text-3xl'>Portal de pagos de cuotas</h4>
            <p className='text-tiny text-calcite mt-1 font-light'>
                Busca la empresa y realiza el pago de tus cuotas
            </p>
            <div className='md:flex items-center mt-8 '>
                <div className='md:w-1/2 relative'>
                    <SearchCliente
                        setSearched={setSearched}
                        search={(e) => setSearchTerm(e)}
                        optionsRef={optionsRef}
                        shadow={true}
                    />
                    {setSearched.value && (
                        <div
                            ref={optionsRef}
                            className={`absolute top-12 text-black border border-gray max-h-350 overflow-y-auto bg-white w-full rounded text-xs font-semibold gray600 ${
                                clientes.length === 0 && 'p-6'
                            } `}
                        >
                            {getClientes.isLoading ? (
                                <div className='flex justify-center'>
                                    <Loader />
                                </div>
                            ) : getClientes.isSuccess ? (
                                filteredList.length > 0 ? (
                                    filteredList.map((cliente) => {
                                        return (
                                            <div
                                                key={cliente.id}
                                                className={`flex justify-between w-full border-b py-4 px-6 cursor-pointer ${
                                                    cliente.id === hoveredRow &&
                                                    'bg-gray200'
                                                }`}
                                                onClick={() =>
                                                    chooseCompany(cliente)
                                                }
                                                onMouseEnter={() =>
                                                    setHoveredRow(cliente.id)
                                                }
                                                onMouseLeave={() =>
                                                    setHoveredRow(undefined)
                                                }
                                            >
                                                <span>{cliente.nombre}</span>
                                                <span>{cliente.rut}</span>
                                            </div>
                                        );
                                    })
                                ) : (
                                    <div className='flex justify-between w-full border-b py-4 px-6 cursor-pointer'>
                                        No hay clientes con este nombre o rut
                                    </div>
                                )
                            ) : (
                                <AlertInfo errorMessage='Hubo un error. Por favor intente nuevamente o contacte al administrador' />
                            )}
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
};

export default Searcher;
