import React, { useEffect } from 'react';
import { Link, useHistory, useLocation } from 'react-router-dom';
import arrowLeft from '../../../svgs/arrowLeft.svg';
import PartsDetail from './PartsDetail';
import { LoginResponse, PartitionResponse } from '../../../types/types';
import { useRol } from '../../../customHooks/customHooks';
import { useAtom } from 'jotai';
import { voucherInfoAtom } from '../../../store/Atoms';
import { useJsonStorage } from '@tdc-cl/async-stuff';
import { authStorage } from '../../../endpoints/endpoints';

const PartsSummary: React.FC = () => {
  const rol = useRol();
  const location = useLocation();
  const history = useHistory();
  const { state } = location as { state: PartitionResponse };
  const partPercentage = (data: PartitionResponse, ammount: number) => {
    const total: number = data.partes.reduce(
      (item1, item2) => item1 + (item2 as any)[item2.monto_to_show].monto,
      0
    );
    return (ammount / total) * 100;
  };

  const [voucherInfo, setVoucherInfo] = useAtom(voucherInfoAtom);
  const user: LoginResponse = useJsonStorage(authStorage)!;

  useEffect(() => {
    if (!location.state) {
      history.push(`/${rol}/portal-de-pagos/seguros`);
    } else {
      setVoucherInfo({
        partes: state?.partes.map(part => {
          return {
            partition_part_id: part.id,
            direccion: 0,
            cuenta_source: undefined,
            cuenta_target: 0,
          };
        }),
        factura_mode: rol === 'persona' ?null : voucherInfo.factura_mode
      });
    }
  }, [location.state, history, rol, setVoucherInfo, state]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div>
      <div className='mb-4 mt-2 ml-5'>
        <Link to={`/persona/portal-de-pagos/seguros`}>
          <img src={arrowLeft} alt='arrow icon' className='inline mr-3' />
          <p className='inline text-base'>Volver a la selección de Seguros</p>
        </Link>
      </div>
      <p className='text-2xl font-bold text-gray700 px-5'>
        2. Complete la información de cada recibo para finalizar
      </p>
      <div className=''>
        <div className='px-5 mt-4'>
          {!!state &&
            state.partes.map(item => {
              return (
                <>
                  <div key={item.id}>
                    <PartsDetail
                      part={item}
                      percentage={partPercentage(
                        state,
                        (item as any)[item.monto_to_show].monto
                      )}
                      client_id={user.id}
                    />
                  </div>
                </>
              );
            })}
        </div>
      </div>
    </div>
  );
};

export default PartsSummary;
