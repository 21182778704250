import Decimal from 'decimal.js';
import React, { useEffect, useState } from 'react';
import { PayerSettings } from '../../types/types';
import { formatCurrency } from '../../utils/utils';
import InvoiceForm from '../forms/InvoiceForm';
import circleDown from '../../svgs/circle-ctrl-down.svg';
import { useAtom } from 'jotai';
import { payListAtom, porcentajeTotalAtom } from '../../store/Atoms';
import Spinner from '../Loader/Spinner';

interface InvoiceProps {
    prefill: boolean;
    filial: PayerSettings;
    monto: Decimal;
    filiales: PayerSettings[];
    setFiliales: (arg: PayerSettings[]) => void;
    nonFilial?: boolean;
    loading?: boolean;
}

const Invoice: React.FC<InvoiceProps> = ({
    prefill,
    monto,
    filial,
    filiales,
    setFiliales,
    nonFilial,
    loading,
}) => {
    const [optionsSelected, setOptionsSelected] = useState<
        {
            code: string;
            text?: string | null;
            folio: string;
            date?: string | null;
        }[]
    >([]);
    const [list] = useAtom(payListAtom);
    const calcPercentage = (total: Decimal, percentage: Decimal) => {
        const per = percentage.dividedBy(new Decimal(100));
        return total.times(new Decimal(per));
    };
    const [show, setShow] = useState(false);

    const [porcentajeTotal, setPorcentajeTotal] = useAtom(porcentajeTotalAtom);
    const filterRecibos = (condition: boolean) =>
        list
            .map(poliza => {
                return {
                    nombre: poliza.nombre,
                    recibos: poliza.recibos.filter(
                        recibo => recibo.support_filial === condition
                    ),
                };
            })
            .filter(x => x.recibos.length);

    const [isHover, setIsHover] = useState(false);

    useEffect(() => {
        if (filial) {
            setOptionsSelected(
                filial.references
                    .filter(x => x.reference.code !== '')
                    .map(x => ({
                        code: x.reference.code,
                        text: x.text,
                        folio: x.folio,
                        date: x.date,
                    }))
            );
        }
    }, [filial]);

    return (
        <div>
            <div
                className={`mt-2 rounded-md border-1-3 border-gray200 lg:mr-5 mb-5 px-5 md:mb-0 bg-white`}
            >
                <div
                    className={`py-3 border-gray-300 bg-white grid grid-cols-12 items-center cursor-pointer ${
                        show && 'border-b'
                    }`}
                    onClick={() => {
                        setPorcentajeTotal(
                            porcentajeTotal.minus(
                                new Decimal(filial.porcentaje)
                            )
                        );
                        if (!show) {
                            setShow(true);
                        } else {
                            setShow(false);
                        }
                    }}
                    onMouseEnter={() => setIsHover(true)}
                    onMouseLeave={() => setIsHover(false)}
                >
                    <div className={`col-span-3`}>
                        {filterRecibos(nonFilial ? false : true).map(
                            (seguro, i) => {
                                return (
                                    <div className={`${i !== 0 && 'mt-2'}`}>
                                        <p className='text-gray700 font-bold'>
                                            {seguro.nombre}
                                        </p>
                                        {seguro.recibos.map(x => (
                                            <div className='mt-1'>
                                                <p className='text-gray400'>
                                                    Recibo n° {x.external_id}
                                                </p>
                                            </div>
                                        ))}
                                    </div>
                                );
                            }
                        )}
                    </div>
                    <p className='col-span-3 font-bold'>
                        {filial.empresa.nombre}
                    </p>

                    <p className='col-span-2 font-bold'>
                        {loading ? (
                            <Spinner color='text-gray400' />
                        ) : (
                            formatCurrency(
                                calcPercentage(
                                    monto,
                                    new Decimal(filial.porcentaje)
                                ),
                                'CLP'
                            )
                        )}
                    </p>
                    <p className='col-span-1 font-bold'>{`${
                        Math.round(filial.porcentaje) === 100 ||
                        filial.porcentaje === 0
                            ? filial.porcentaje
                            : filial.porcentaje.toFixed(2)
                    }%`}</p>

                    <div className='col-span-3 flex justify-end'>
                        <div
                            className={`font-normal text-sm bg-gray200 flex items-center justify-center rounded-full h-10 min-w-9 px-3 ${
                                isHover && 'bg-gray250'
                            }`}
                        >
                            <span>Completar información</span>
                            <img
                                className={`${
                                    show && 'rotate-180'
                                } w-3 ml-1`}
                                src={circleDown}
                                alt='complete'
                            />
                        </div>
                    </div>
                </div>

                <div className={`${!show && 'hidden'} `}>
                    <div>
                        <div className='py-6 '>
                            <div className='md:w-6/12'>
                                <InvoiceForm
                                    setOptionsSelected={setOptionsSelected}
                                    optionsSelected={optionsSelected}
                                    prefill={prefill}
                                    filial={filial}
                                    setFiliales={setFiliales}
                                    filiales={filiales}
                                    setEditar={setShow}
                                    nonFilial={nonFilial}
                                    monto={monto}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Invoice;
