import React, { useEffect } from 'react';
import { useForm, $Form, $Button, $Text, $Select } from '@tdc-cl/x-form';
import { $Rut } from '../../customFields/$Rut';
import { $ValidEmail } from '../../customFields/$ValidEmail';
import { LoginResponse } from '../../types/types';
import { useJsonStorage } from '@tdc-cl/async-stuff';
import { authStorage, crearCuenta } from '../../endpoints/endpoints';
import { QueryObserverResult, RefetchOptions, useMutation } from 'react-query';
import { useAtom } from 'jotai';
import { clienteCorredorAtom, refetchAccountsAtom } from '../../store/Atoms';
import { useRol } from '../../customHooks/customHooks';
import AlertInfo from '../Alert/AlertInfo';
import ActionButton from '../Buttons/ActionButton';

const NewAccountForm: React.FC<{
    setShowNewAccount: (arg: boolean) => void;
    refetch: (options?: RefetchOptions | undefined) => Promise<
        QueryObserverResult<
            {
                id: number;
                banco: string;
                tipo: string;
                rut: string;
                n_cuenta: string;
                email: string;
            }[],
            unknown
        >
    >;
}> = ({ setShowNewAccount, refetch }) => {
    const user: LoginResponse = useJsonStorage(authStorage)!;
    const [cliente] = useAtom(clienteCorredorAtom);
    const rol = useRol();
    const crearNuevaCuenta = useMutation(crearCuenta.fetch, {
        mutationKey: 'nueva-cuenta',
        onSuccess: (data) => {
            setShowNewAccount(false);
            refetch();
        },
    });

    const form = useForm(
        $Form({
            fields: {
                rut: $Rut('RUT Titular').with({
                    inputProps: {
                        className: 'mb-4',
                    },
                }),
                banco: $Select('Banco')
                    .with({
                        options: [
                            'Banco de Chile',
                            'Scotiabank Chile',
                            'Banco de Crédito e Inversiones',
                            'Banco BICE',
                            'Banco Santander',
                            'Banco Itaú',
                            'Banco Security',
                            'Banco Falabella',
                            'Banco Ripley',
                            'Banco Deutsche',
                        ],
                    })
                    .with({
                        inputProps: {
                            className:
                                'w-full border-1-5 border-gray300 py-2 rounded',
                        },
                    }),
                tipo: $Select('Tipo de cuenta')
                    .with({
                        options: [
                            { value: 'corriente', label: 'Corriente' },
                            { value: 'ahorro', label: 'Ahorro' },
                        ],
                    })
                    .with({
                        inputProps: {
                            className:
                                'w-full border-1-5 border-gray300 py-2 rounded',
                        },
                    }),
                n_cuenta: $Text('N° de cuenta'),
                email: $ValidEmail('Correo Electrónico'),
            },
            submit: $Button('GUARDAR', {
                async onValid(values) {
                    crearNuevaCuenta.mutate({
                        client_id:
                            rol === 'persona'
                                ? user.id
                                : rol === 'corredor' && cliente.id,
                        ...values,
                    });
                },
                onInvalid: 'disable',
            }),
            props: {
                className: 'customized-form',
            },
        })
    );

    useEffect(() => {
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <form {...form.props}>
            {form.renderFields()}

            <div className='flex flex-col items-end'>
                {crearNuevaCuenta.isError && (
                    <AlertInfo
                        errorMessage={`${crearNuevaCuenta.error}`}
                        className='text-center'
                    />
                )}
                <div className='w-6/12'>
                    <ActionButton
                        onClick={() => {
                            form.submit();
                        }}
                        isLoading={crearNuevaCuenta.isLoading}
                    >
                        GUARDAR
                    </ActionButton>
                </div>
            </div>
        </form>
    );
};

export default NewAccountForm;
