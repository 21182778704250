import React, { useState } from 'react';
import TableRow from '@material-ui/core/TableRow';
import { formatCurrency } from '../../utils/utils';
import { equalizeSearch } from '../../utils/utils';
import Decimal from 'decimal.js';
import { StyledTableCell } from './TableStyle';
import { Divisa, ListVoucher } from '../../types/types';
import circleDown from '../../svgs/circle-ctrl-down.svg';
import warning from '../../svgs/c-warning.svg';
import { useHistory } from 'react-router-dom';
import { useRol } from '../../customHooks/customHooks';
import { selectedVoucherAtom } from '../../store/Atoms';
import { useAtom } from 'jotai';
import AnticipadoBadge from '../Badges/AnticipadoBadge';
import CustomModal from '../CustomModal/CustomModal';
import PartPreview from '../PartsPreview/PartPreview';
import FacturaConfirmada from './FacturaConfirmada';
import DetailPreview from '../PortalPagos/Checkout/DetailPreview';
import ReactTooltip from 'react-tooltip';

interface RowProps {
    rows: ListVoucher[];
    searchTerm: string;
}
const VoucherRow: React.FC<RowProps> = ({ rows, searchTerm }) => {
    const history = useHistory();
    const rol = useRol();

    const [selectedVoucher] = useAtom(selectedVoucherAtom);

    const [selectedRow, setSelectedRow] = useState<number | undefined>(
        selectedVoucher.voucher
    );
    const [hoveredRow, setHoveredRow] = useState<number | undefined>(undefined);
    const [showDetail, setShowDetail] = useState(selectedVoucher.show);
    const [showFacturar, setShowFacturar] = useState(false);
    const [voucher, setVoucher] = useState(0);
    const [facturaConfirmada, setFacturaConfirmada] = useState(false);
    const [hoveredRowPart, setHoveredRowPart] =
        useState<number | undefined>(undefined);

    const toggleDetail = (state: boolean, id: number) => {
        if (state && id === selectedRow) {
            setShowDetail(false);
        } else {
            setSelectedRow(id);
            setShowDetail(true);
        }
    };
    const [showModal, setShowModal] = useState(false);
    const [partDetailID, setPartDetailID] = useState<number>(0);
    const [showParte, setShowParte] = useState(rows[0]?.partes[0]);

    return (
        <>
            {/* eslint-disable-next-line array-callback-return */}
            {rows!.map(row => {
                const voucherPart = {
                    id: row.id,
                    fecha: row.fecha,
                    state: row.state,
                    total: row.total,
                    user: row.user,
                    partes: row.partes,
                    total_to_show: row.total_to_show,
                };

                const totalDivisas = Object.entries(row.total_to_show);
                if (
                    equalizeSearch(row.user.nombre).startsWith(
                        equalizeSearch(searchTerm)
                    ) ||
                    equalizeSearch(row.user.rut).startsWith(
                        equalizeSearch(searchTerm)
                    )
                ) {
                    return (
                        <>
                            <TableRow
                                onClick={() => toggleDetail(showDetail, row.id)}
                                key={row.id}
                                className={`cursor-pointer relative z-0  `}
                                onMouseEnter={() => setHoveredRow(row.id)}
                                onMouseLeave={() => setHoveredRow(undefined)}
                            >
                                <StyledTableCell
                                    align='left'
                                    className={` ${
                                        showDetail &&
                                        selectedRow === row.id &&
                                        'bg-gray200'
                                    } ${hoveredRow === row.id && 'bg-blu0'} ${
                                        (rol === 'contabilidad' ||
                                            rol === 'agente' ||
                                            rol === 'cobrador') &&
                                        'cursor-pointer'
                                    }`}
                                >
                                    <div className='flex items-center'>
                                        {row.waiting_facturation && (
                                            <div className='rounded-full bg-red400 w-2 h-2'></div>
                                        )}
                                        <span
                                            className={`font-medium text-sm text-gray400 ${
                                                row.waiting_facturation &&
                                                'ml-2'
                                            }`}
                                        >
                                            {' '}
                                            #{row.id}{' '}
                                        </span>
                                    </div>
                                </StyledTableCell>
                                <StyledTableCell
                                    className={`text-sm text-gray400 font-medium ${
                                        hoveredRow === row.id && 'bg-blue0'
                                    } ${
                                        showDetail &&
                                        selectedRow === row.id &&
                                        'bg-gray200'
                                    }`}
                                    align='left'
                                >
                                    {`${new Date(
                                        row.fecha.toString()
                                    ).toLocaleDateString('es-ES', {
                                        day: 'numeric',
                                        month: 'short',
                                        year: 'numeric',
                                    })}`}
                                </StyledTableCell>
                                {rol !== 'persona' && (
                                    <StyledTableCell
                                        className={`${
                                            hoveredRow === row.id && 'bg-blu0'
                                        } ${
                                            showDetail &&
                                            selectedRow === row.id &&
                                            'bg-gray200'
                                        }`}
                                        align='left'
                                    >
                                        <div className='flex flex-col md:-mr-5'>
                                            <span className='flex text-tiny items-center font-bold text-gray700'>
                                                {row.user.nombre}
                                                {row.user
                                                    .facturacion_anticipada && (
                                                    <span className='ml-2'>
                                                        <AnticipadoBadge />
                                                    </span>
                                                )}
                                            </span>
                                            <small className='gray400'>
                                                {row.user.rut}
                                            </small>
                                        </div>
                                    </StyledTableCell>
                                )}

                                <StyledTableCell
                                    className={`text-sm text-gray400 font-medium ${
                                        hoveredRow === row.id && 'bg-bl00'
                                    } ${
                                        showDetail &&
                                        selectedRow === row.id &&
                                        'bg-gray200'
                                    }`}
                                    align='left'
                                >
                                    {row.partes.length}
                                </StyledTableCell>

                                <StyledTableCell
                                    className={`${
                                        hoveredRow === row.id && 'bg-ue100'
                                    } ${
                                        showDetail &&
                                        selectedRow === row.id &&
                                        'bg-gray200'
                                    }`}
                                    align='left'
                                >
                                    <span className='text-sm text-gray400 font-medium'>
                                        {totalDivisas.map((totalDivisa, i) => {
                                            if (!!totalDivisa[1]) {
                                                return (
                                                    <span>
                                                        {i > 0 && (
                                                            <span className='mx-1'>
                                                                +
                                                            </span>
                                                        )}
                                                        {formatCurrency(
                                                            new Decimal(
                                                                totalDivisa[1]
                                                            ),
                                                            totalDivisa[0] as Divisa
                                                        )}
                                                    </span>
                                                );
                                            }
                                        })}
                                    </span>
                                </StyledTableCell>
                                <StyledTableCell
                                    className={`${
                                        hoveredRow === row.id && 'bg-e100'
                                    } ${
                                        showDetail &&
                                        selectedRow === row.id &&
                                        'bg-gray200'
                                    }`}
                                >
                                    <div className='flex'>
                                        {(row.state.includes('Con Errores') ||
                                            row.state === 'Error' ||
                                            row.state.includes(
                                                'Facturación Requerida'
                                            )) && <img src={warning} alt='' />}
                                        <span
                                            className={`${
                                                row.state === 'Pagado'
                                                    ? 'text-green-700'
                                                    : 'text-orangeState'
                                            } ml-2 font-medium text-sm`}
                                        >
                                            {row.state}
                                        </span>
                                    </div>
                                </StyledTableCell>

                                {rol !== 'persona' && (
                                    <StyledTableCell
                                        className={`${
                                            hoveredRow === row.id && 'bg-bl0'
                                        } ${
                                            showDetail &&
                                            selectedRow === row.id &&
                                            'bg-gray200'
                                        }`}
                                    >
                                        {row.waiting_facturation && (
                                            <button
                                                className='text-sm text-center w-full flex items-center justify-center h-10 cursor-pointer rounded bg-red400 border hover:bg-red600 hover:border-red600 text-white border-red400 py-1 focus:outline-none'
                                                onClick={() => {
                                                    setVoucher(row.id);
                                                    setShowFacturar(true);
                                                }}
                                            >
                                                Facturar
                                            </button>
                                        )}
                                    </StyledTableCell>
                                )}
                                <StyledTableCell
                                    className={`${
                                        rol === 'persona' &&
                                        'flex justify-center'
                                    } ${hoveredRow === row.id && 'bg-b0'} ${
                                        showDetail &&
                                        selectedRow === row.id &&
                                        'bg-gray200'
                                    }`}
                                >
                                    <div
                                        className={`flex items-center justify-center rounded-full  h-8 w-8 ${
                                            hoveredRow === row.id &&
                                            'bg-gray200'
                                        }`}
                                    >
                                        <img
                                            src={circleDown}
                                            alt='see more icon'
                                            className={`max-w-max lg:w-4 cursor-pointer ${
                                                showDetail &&
                                                selectedRow === row.id &&
                                                'rotate-180'
                                            }`}
                                            onClick={() =>
                                                toggleDetail(showDetail, row.id)
                                            }
                                        />
                                    </div>
                                </StyledTableCell>
                            </TableRow>
                            {showDetail && selectedRow === row.id && (
                                <TableRow>
                                    <StyledTableCell
                                        className='p-0'
                                        colSpan={8}
                                    >
                                        <div
                                            className={`h-full w-full relative shadow-rows ${
                                                !showModal &&
                                                !showFacturar &&
                                                'z-50'
                                            }`}
                                        >
                                            <div className='w-full p-4 '>
                                                <div className='flex items-center justify-between border-b border-gray200 p-4'>
                                                    <p className='font-bold text-lg'>
                                                        Voucher # {row.id}
                                                    </p>
                                                    {(rol === 'contabilidad' ||
                                                        rol === 'cobrador') && (
                                                        <>
                                                            <ReactTooltip
                                                                id='btn'
                                                                place='top'
                                                                className='text-xs px-1 p-0 mb-4 font-light h-5'
                                                            >
                                                                Aquí podrás ver
                                                                el detalle de
                                                                los pagos, los
                                                                errores y la
                                                                opción de anular
                                                                el voucher.
                                                            </ReactTooltip>
                                                            <button
                                                                data-tip
                                                                data-for='btn'
                                                                onClick={() => {
                                                                    history.push(
                                                                        {
                                                                            pathname: `/contabilidad/voucher-detail`,
                                                                            state: voucherPart,
                                                                        }
                                                                    );
                                                                }}
                                                                className='border border-red400 font-bold text-red400 rounded min-w-9 px-2 h-9'
                                                            >
                                                                Ver
                                                                Compesaciones y
                                                                Errores
                                                            </button>
                                                        </>
                                                    )}
                                                </div>
                                                {row.partes.map((parte, i) => {
                                                    const divisaToShow = parte
                                                        .partition_part
                                                        .monto_to_show as any;

                                                    return (
                                                        <>
                                                            <TableRow
                                                                key={parte.id}
                                                                onMouseEnter={() =>
                                                                    setHoveredRowPart(
                                                                        parte.id
                                                                    )
                                                                }
                                                                onMouseLeave={() =>
                                                                    setHoveredRowPart(
                                                                        undefined
                                                                    )
                                                                }
                                                                className='cursor-pointer  border-transparent'
                                                                onClick={() => {
                                                                    setPartDetailID(
                                                                        parte.id
                                                                    );
                                                                    setShowModal(
                                                                        true
                                                                    );
                                                                    setShowParte(
                                                                        parte
                                                                    );
                                                                }}
                                                            >
                                                                <StyledTableCell
                                                                    className={`text-sm text-gray700 font-normal ${
                                                                        hoveredRowPart ===
                                                                            parte.id &&
                                                                        'bg-gray200'
                                                                    } w-1/12 border-transparent`}
                                                                >
                                                                    Parte{' '}
                                                                    {parte.id}
                                                                </StyledTableCell>
                                                                <StyledTableCell
                                                                    className={`${
                                                                        hoveredRowPart ===
                                                                            parte.id &&
                                                                        'bg-gray200'
                                                                    } w-2/12  border-transparent`}
                                                                >
                                                                    <span className='text-sm text-gray700 font-normal'>
                                                                        {formatCurrency(
                                                                            new Decimal(
                                                                                (
                                                                                    parte.partition_part as any
                                                                                )[
                                                                                    divisaToShow
                                                                                ].monto
                                                                            ),
                                                                            (
                                                                                parte.partition_part as any
                                                                            )[
                                                                                divisaToShow
                                                                            ]
                                                                                .divisa
                                                                        )}
                                                                    </span>
                                                                </StyledTableCell>
                                                                <StyledTableCell
                                                                    align='left'
                                                                    className={`${
                                                                        hoveredRowPart ===
                                                                            parte.id &&
                                                                        'bg-gray200'
                                                                    } border-transparent `}
                                                                >
                                                                    <div className='flex '>
                                                                        {(parte.state.includes(
                                                                            'Con Errores'
                                                                        ) ||
                                                                            parte.state ===
                                                                                'Error' ||
                                                                            parte.state.includes(
                                                                                'Facturación Requerida'
                                                                            )) && (
                                                                            <img
                                                                                src={
                                                                                    warning
                                                                                }
                                                                                alt=''
                                                                            />
                                                                        )}
                                                                        <span
                                                                            className={`${
                                                                                parte.state ===
                                                                                'Pagado'
                                                                                    ? 'text-green-700'
                                                                                    : 'text-orangeState ml-2'
                                                                            } font-medium w-52`}
                                                                        >
                                                                            {
                                                                                parte.state
                                                                            }
                                                                        </span>
                                                                    </div>
                                                                </StyledTableCell>
                                                                <StyledTableCell
                                                                    align='right'
                                                                    className={`${
                                                                        hoveredRowPart ===
                                                                            parte.id &&
                                                                        'bg-gray200'
                                                                    } w-9/12 border-transparent`}
                                                                >
                                                                    <span
                                                                        className=' underline text-sm text-gray700 font-medium cursor-pointer'
                                                                        onClick={() => {
                                                                            setShowModal(
                                                                                true
                                                                            );
                                                                        }}
                                                                    >
                                                                        Ver más
                                                                        detalle
                                                                    </span>
                                                                </StyledTableCell>
                                                            </TableRow>
                                                        </>
                                                    );
                                                })}
                                                <CustomModal
                                                    isOpen={showModal}
                                                    setIsOpen={setShowModal}
                                                    shadow
                                                >
                                                    <DetailPreview
                                                        voucherID={row.id}
                                                        parteID={partDetailID}
                                                        percentage={
                                                            showParte.pago_data
                                                                .payer_setting
                                                                ?.porcentaje
                                                        }
                                                        setShowModal={
                                                            setShowModal
                                                        }
                                                    />
                                                </CustomModal>
                                            </div>
                                        </div>
                                    </StyledTableCell>
                                </TableRow>
                            )}
                        </>
                    );
                }
            })}
            <CustomModal
                isOpen={showFacturar}
                setIsOpen={setShowFacturar}
                size={facturaConfirmada ? 'lg' : 'xl'}
            >
                {!facturaConfirmada ? (
                    <PartPreview
                        setFacturaConfirmada={setFacturaConfirmada}
                        setShowFacturar={setShowFacturar}
                        parte={voucher}
                    />
                ) : (
                    <FacturaConfirmada
                        setFacturaConfirmada={setFacturaConfirmada}
                        setShowFacturar={setShowFacturar}
                        parteID={voucher}
                    />
                )}
            </CustomModal>
        </>
    );
};

export default VoucherRow;
