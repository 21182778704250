import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import Navbar from '../components/Navbar/Navbar';
import check from '../svgs/check.svg';
import LinkButton from '../components/Buttons/LinkButton';
import { useRol } from '../customHooks/customHooks';
import Screen from '../components/Screen/Screen';

const CanceledConfirmed: React.FC<{ finalizado?: boolean }> = ({
    finalizado,
}) => {
    const { state } = useLocation() as { state: number };
    const rol = useRol();

    const home =
        rol === 'contabilidad' || rol === 'agente' || rol === 'cobrador'
            ? '/contabilidad/portal-de-pagos/vouchers'
            : rol === 'corredor'
            ? '/corredor/inicio'
            : rol === 'persona'
            ? '/persona/portal-de-pagos/seguros'
            : '/';
    return (
        <Screen>
            <Navbar />
            <div className='pt-14 mt-14 px-14'>
                <div className='flex items-center'>
                    <div>
                        <h3 className='gray600'>¡Listo!</h3>
                        <h5 className='font-bold'>
                            El voucher #{state} ha sido{' '}
                            {finalizado ? 'finalizado' : 'anulado'}.
                        </h5>
                    </div>
                    <img src={check} alt='check icon' className='ml-auto' />
                </div>
                <div className='rounded border-1-3 border-gray300 bg-white h-72 mt-5'>
                    <div className='border-b border-gray py-3 px-5'>
                        <p>Voucher</p>
                    </div>
                    <div className='py-3 px-5'>
                        <small>
                            Puedes revisar el estado del voucher en la sección{' '}
                            <span className='font-bold underline'>
                                <Link to={`/${rol}/portal-de-pagos/vouchers`}>
                                    Vouchers
                                </Link>
                            </span>
                        </small>
                        <div className='w-4/5 md:w-1/4 mt-10'>
                            <LinkButton link={home}>IR AL INICIO</LinkButton>
                        </div>
                    </div>
                </div>
            </div>
        </Screen>
    );
};

export default CanceledConfirmed;
