import React from 'react';
import AnticipadoBadge from '../Badges/AnticipadoBadge';
import circleDown from '../../svgs/circle-ctrl-down.svg';
import { useBoolean } from '../../customHooks/useBoolean';

interface ClientesCorredor {
    id: number;
    rut: string;
    nombre: string;
    facturacion_anticipada: boolean;
}

const CompanyInfo: React.FC<{
    cliente: ClientesCorredor;
    onClick: () => void;
}> = ({ cliente: { nombre, rut, facturacion_anticipada }, onClick }) => {
    const isHovered = useBoolean(false);
    return (
        <div
            className='flex bg-white p-3 rounded mt-4 min-w-96 w-full items-center gray600 font-medium cursor-pointer'
            onClick={() => onClick()}
            onMouseEnter={() => isHovered.setValue(true)}
            onMouseLeave={() => isHovered.setValue(false)}
        >
            <div className='flex items-start'>
                <div className='mx-2'>
                    <p className='text-tiny text-gray700 '>{nombre}</p>
                    <small className='text-xs font-light text-gray400'>
                        RUT {rut.slice(0, rut.length - 1)}-{rut[rut.length - 1]}
                    </small>
                </div>
                {facturacion_anticipada && (
                    <div className='flex mr-2'>
                        <span className='mr-2 text-sm'>&#8226;</span>{' '}
                        <AnticipadoBadge />
                    </div>
                )}
            </div>

            <div
                className={`ml-auto ${
                    isHovered.value ? 'opacity-100' : 'opacity-0'
                }`}
            >
                <div className='bg-gray200 flex items-center justify-center rounded-full w-10 h-10'>
                    <img
                        src={circleDown}
                        alt='circle'
                        className='-rotate-90'
                    />
                </div>
            </div>
        </div>
    );
};

export default CompanyInfo;
