import { useAtom } from 'jotai';
import React, { useState } from 'react';
import { invoiceMethodAtom, voucherInfoAtom } from '../../../store/Atoms';
import { AlertType, useCrearVoucher } from '../../../store/hooks';

import close from '../../../svgs/e-close.svg';
import AlertModal from '../../Alert/AlertModal';
import ActionButton from '../../Buttons/ActionButton';
import CustomModal from '../../CustomModal/CustomModal';

export interface InvoiceMethodProps {
    onClick: (alertModal: AlertType) => void;
    isLoading: boolean;
}
const InvoiceMethod: React.FC<InvoiceMethodProps> = ({
    onClick,
    isLoading,
}) => {
    const [selected, setSelected] = useState<'1' | '2' | null>(null);
    const [, setShowInviceMethod] = useAtom(invoiceMethodAtom);
    const [voucherInfoPersona, setVoucherInfo] = useAtom(voucherInfoAtom);
    const [alertMessage, setAlertMessage] = useState('');
    const [showModal, setShowModal] = useState(false);
    const alertModal = {
        setShowModal,
        setAlertMessage,
    };
    const invoiceMethods = [
        {
            id: '1',
            title: 'Generar factura afecta y excenta por cada recibo',
            subtitle:
                'Se generarán 2 facturas por cada recibo seleccionado, una afecta y otra excenta.',
        },
        {
            id: '2',
            title: 'Generar factura afecta y excenta por el total',
            subtitle:
                'Se generarán 2 facturas en total, una afecta y otra excenta con el valor total.',
        },
    ];

    return (
        <div className='p-4'>
            <div className=' gray600 border-b flex justify-between items-center border-gray200 mb-6 pb-4'>
                <p className='text-xl font-bold text-gray700'>
                    Método de facturación
                </p>

                <div
                    onClick={() => setShowInviceMethod(false)}
                    className='hover:bg-gray250 bg-gray200 w-11 h-11 flex justify-center items-center rounded cursor-pointer'
                >
                    <img src={close} alt='close' />
                </div>
            </div>
            <p className='text-gray700 font-bold text-sm mb-5'>
                Escoja el método para generar las facturas
            </p>
            {invoiceMethods.map(method => (
                <div
                    className={`cursor-pointer border rounded-md flex text-2xs md:text-sm lg:text-base h-20 px-6 whitespace-pre-wrap items-center w-full mb-3 
          ${
              selected === method.id
                  ? 'border-red400 bg-redLight '
                  : 'border-gray200 '
          }`}
                    onClick={() => {
                        setSelected(method.id as any);
                        setVoucherInfo({
                            ...voucherInfoPersona,
                            factura_mode: method.id as any,
                        });
                    }}
                >
                    <div className='flex items-center justify-center mr-8'>
                        <div>
                            <span
                                className={`w-5 h-5 rounded-full border-2 flex justify-center items-center ${
                                    selected === method.id
                                        ? 'border-red400 '
                                        : 'border-gray300 bg-gray100'
                                }`}
                            >
                                <span
                                    className={`transition-all duration-100 block rounded-full bg-red400 ${
                                        selected === method.id
                                            ? 'w-3 h-3'
                                            : 'w-0 h-0'
                                    }`}
                                />
                            </span>
                        </div>
                        <div className='ml-5'>
                            <p className='text-gray700 font-bold text-sm'>
                                {method.title}
                            </p>
                            <p className='text-gray700'>{method.subtitle}</p>
                        </div>
                    </div>
                </div>
            ))}
            <div className='w-96 ml-auto mt-10 flex'>
                <div className='mr-4 w-52'>
                    <ActionButton
                        classname='bg-gray200 hover:bg-gray250 text-gray400 border-gray200'
                        onClick={() => setShowInviceMethod(false)}
                    >
                        Volver
                    </ActionButton>
                </div>
                <ActionButton
                    onClick={() => onClick(alertModal)}
                    isLoading={isLoading}
                >
                    Confirmar y finalizar
                </ActionButton>
            </div>
            <CustomModal
                isOpen={showModal}
                setIsOpen={setShowModal}
                size={'sm'}
            >
                <AlertModal
                    setShowModal={setShowModal}
                    alertMessage={alertMessage}
                />
            </CustomModal>
        </div>
    );
};

export default InvoiceMethod;
