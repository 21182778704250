import React, { useRef } from 'react';
import { useClickOutside } from '../../customHooks/useClickOutside';
import iconSearch from '../../svgs/iconSearch.svg';

const SearchCliente: React.FC<{
    search: (arg: string) => void;
    setSearched?: {
        value: boolean;
        setValue: React.Dispatch<React.SetStateAction<boolean>>;
        toggle: () => void;
    };
    shadow?: boolean;
    optionsRef?: React.MutableRefObject<HTMLDivElement | null>;
}> = ({ search, setSearched, optionsRef, shadow }) => {
    const triggerRef = useRef(null);

    useClickOutside<any>(optionsRef, setSearched?.toggle, triggerRef);

    return (
        <div className='relative'>
            <input
                ref={triggerRef}
                type='text'
                className={`px-9 py-3 w-full placeholder-gray400  text-black border-gray200 rounded text-xs bg-gray100 ${
                    shadow && 'shadow-searchBar'
                }`}
                placeholder='Buscar empresa por nombre o rut...'
                onChange={(e) => search(e.target.value)}
                onFocus={() => setSearched && setSearched.setValue(true)}
                /*    onBlur={() => {
                    setSearched && setSearched(false);
                }} */
            />

            <img
                src={iconSearch}
                alt='search icon'
                className='absolute top-3 left-3'
            />
        </div>
    );
};

export default SearchCliente;
