import Decimal from 'decimal.js';
import { LocalDate } from 'js-joda';
import React from 'react';
import { PartitionPartItem } from '../../types/types';
import { formatCurrency, formatDate } from '../../utils/utils';

const Document: React.FC<{ partition: PartitionPartItem; errors?: number[] }> =
    ({ partition, errors }) => {
        const divisaToShow = partition.monto_to_show as any;
        const hasError = errors && errors.includes(partition.document.id);
        return (
            <>
                <p className='font-bold'>
                    {' '}
                    ID:
                    <span className='ml-1 font-normal'>
                        {partition.document.external_id}
                    </span>
                </p>

                <p className='font-bold'>
                    Vencimiento:{' '}
                    <span className='ml-1 font-normal'>
                        {formatDate(
                            LocalDate.parse(
                                partition.document.vencimiento.toString()
                            )
                        )}
                    </span>
                </p>

                <p className='font-bold'>
                    {' '}
                    Monto:
                    <span className='ml-1 font-normal'>
                        {formatCurrency(
                            new Decimal((partition as any)[divisaToShow].monto),
                            (partition as any)[divisaToShow].divisa
                        )}
                    </span>
                </p>
                {hasError && (
                    <p className='font-bold p-2 rounded bg-red-400 max-w-max text-white mt-1'>
                        {' '}
                        Tiene error de pago
                    </p>
                )}
            </>
        );
    };

export default Document;
