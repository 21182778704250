import Decimal from 'decimal.js';
import { convert, LocalDate } from 'js-joda';
import { Currency } from '../types/alias';
import { Recibo } from '../types/alias';
import classNames from 'classnames';
import { Money } from '../Money';

export const formatDate = (date: LocalDate) => {
    const jsDate = convert(date).toDate();
    return jsDate
        .toLocaleDateString('es-ES', {
            day: 'numeric',
            month: 'short',
            year: 'numeric',
        })
        .replace('.', '');
};

export const formatCurrency = (ammount: Decimal, currency: Currency) => {
    const USDFormat = new Intl.NumberFormat('es-CL', {
        currency: 'USD',
        style: 'currency',
    });

    switch (currency) {
        case 'CLP':
            return Money.CLP(ammount).toString();
        case 'USD':
            return Money.USD(ammount).toString();
        case 'UF':
            return Money.UF(ammount).toString();
        case 'US$':
            return USDFormat.format(Number(ammount));
        default:
            break;
    }
};
export const proximaCuota = (recibos: Recibo[]): Recibo | undefined => {
    const currentDate = LocalDate.now();
    const proximo = recibos.filter(
        (recibo) => !recibo.pagado && !recibo.en_voucher
    );

    return proximo.sort((a, b) =>
        new Decimal(a.peso) > new Decimal(b.peso)
            ? 1
            : -1 || (a.vencimiento.isAfter(b.vencimiento) ? -1 : 1)
    )[0];
};
export const equalizeSearch = (searched: string) => {
    return searched
        .normalize('NFD')
        .replace(/[\u0300-\u036f]/g, '')
        .toLowerCase()
        .replaceAll('.', '');
};
export const classNamed = new Proxy(
    {},
    {
        get(_, Tag: any) {
            return (cls: string) =>
                ({ children, ...rest }: any) => {
                    return (
                        <Tag
                            {...rest}
                            className={classNames(cls, rest.className)}
                        >
                            {children}
                        </Tag>
                    );
                };
        },
    }
) as {
    [TTag in keyof JSX.IntrinsicElements]: (
        className: string
    ) => React.FC<JSX.IntrinsicElements[TTag]>;
};
