import React, { useEffect, useState } from 'react';
import Navbar from '../components/Navbar/Navbar';
import EditHeader from '../components/EditVoucher/EditHeader';
import { useAtom } from 'jotai';
import {
    newSettingsAtom,
    nonFilialSettingAtom,
    setPreviewAtom,
    showPreviewAtom,
} from '../store/Atoms';
import VoucherParts from '../components/VoucherParts/VoucherParts';
import InvoiceSummary from '../components/VoucherDetail/InvoiceSummary';
import { useHistory, useLocation } from 'react-router-dom';
import { LastSettings, ListVoucher } from '../types/types';
import { useQuery } from 'react-query';
import { filialSettingContabilidad } from '../endpoints/endpoints';
import Loader from '../components/Loader/Loader';
import AlertInfo from '../components/Alert/AlertInfo';
import Decimal from 'decimal.js';
import { Recibo } from '../types/alias';
import Screen from '../components/Screen/Screen';

const EditVoucher = () => {
    const [, setShowPreview] = useAtom(showPreviewAtom);
    const [, setPreview] = useAtom(setPreviewAtom);
    const { state } = useLocation() as {
        state: {
            reason_id: number;
            voucher: ListVoucher;
            other: string;
        };
    };
    const history = useHistory();
    const [newSettings, setNewSettings] = useAtom(newSettingsAtom);
    const [, setNonFilialSetting] = useAtom(nonFilialSettingAtom);

    const [documentos, setDocumentos] = useState<number[]>([]);

    const { data, isLoading, isError, error } = useQuery(
        [filialSettingContabilidad.url, state?.voucher.id],
        () => filialSettingContabilidad.fetch(state?.voucher.id),
        {
            onSuccess: (data: {
                payer_set: LastSettings;
                documents: Recibo[];
            }) => {
                setDocumentos(data.documents.map((documento) => documento.id));
            },
        }
    );

    useEffect(() => {
        if (!state) {
            history.push('/contabilidad/portal-de-pagos/vouchers');
        }
        if (newSettings.length === 0 && data) {
            setNewSettings(
                data.payer_set.filial_settings.map((filial) => ({
                    id: filial.empresa.id,
                    porcentaje: new Decimal(filial.porcentaje),
                    setting: filial.id,
                }))
            );
            if (data.payer_set.non_filial_setting) {
                setNonFilialSetting({
                    empresa: data.payer_set.non_filial_setting.empresa.id,
                    setting: data.payer_set.non_filial_setting.id,
                });
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [state, newSettings, data]);

    useEffect(() => {
        console.log(newSettings);
    }, [newSettings]);

    return (
        <Screen>
            <Navbar />
            <section>
                <div className='grid grid-cols-1 lg:grid-cols-11'>
                    <div className='md:col-span-7 lg:mr-8'>
                        <div className='mb-5'>
                            <EditHeader
                                name={state?.voucher.user.nombre}
                                id={state?.voucher.id}
                                anticipado={
                                    state?.voucher.user.facturacion_anticipada
                                }
                            />
                        </div>
                        <div>
                            {isLoading ? (
                                <div className='flex justify-center mt-14 mb-6'>
                                    <Loader />
                                </div>
                            ) : isError ? (
                                <AlertInfo errorMessage={`${error}`} />
                            ) : (
                                data && (
                                    <>
                                        {data?.payer_set.filial_settings.map(
                                            (filial, i) => (
                                                <div key={filial.id}>
                                                    <VoucherParts
                                                        state={state}
                                                        cliientId={
                                                            state.voucher.user
                                                                .id
                                                        }
                                                        filial={filial}
                                                        total={
                                                            state?.voucher.total
                                                        }
                                                    />
                                                </div>
                                            )
                                        )}
                                        <h4
                                            onClick={() =>
                                                console.log(newSettings)
                                            }
                                        >
                                            Recibos que no aceptan pagos de
                                            empresas filiales
                                        </h4>
                                        {data.payer_set.non_filial_setting ? (
                                            <VoucherParts
                                                state={state}
                                                cliientId={state.voucher.user.id}
                                                filial={
                                                    data.payer_set
                                                        .non_filial_setting!
                                                }
                                                total={state?.voucher.total}
                                                nonFilial
                                            />
                                        ) : (
                                            <p>No hay recibos que no acepten pagos de empresas filiales.</p>
                                        )}
                                    </>
                                )
                            )}
                        </div>
                    </div>

                    <div className='mt-5 md:mt-0 lg:col-span-4'>
                        {state && (
                            <InvoiceSummary
                                setPreview={setPreview}
                                setShowPreview={setShowPreview}
                                voucher={state?.voucher}
                                state={state}
                                documents={documentos}
                            />
                        )}
                    </div>
                </div>
            </section>
        </Screen>
    );
};

export default EditVoucher;
