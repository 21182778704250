import React from 'react';

interface Props {}

const InvoiceHeader: React.FC<Props> = () => {
  return (
    <div className='grid grid-cols-12 gap-8 items-center'>
      <div className='col-span-6 text-center leading-6 text-xs'>
        <p className='font-bold text-xs'>
          MAPFRE COMPAÑÍA DE SEGUROS GENERALES DE CHILE S.A.
        </p>
        <p className='text-xs'>GIRO: SEGUROS GENERALES</p>
        <p className='text-xs'>
          ISIDORA GOYENECHEA 3520 - LAS CONDES - 16 PISO - OF. 1600 - SANTIAGO
        </p>
        <p className='text-xs'>TELEFONO: 694 7000 / FAX: 696 4086</p>
      </div>
      <div className='col-span-6 border-2 border-red-600 items-center justify-center text-center p-6'>
        <div className='text-red-600 font-bold'>
          <p>PREVISUALIZACIÓN</p>
          <p>FACTURA ELECTRÓNICA</p>
        </div>
        <small>
          Esto es una previsualización. No válido como documento tributario.
        </small>
      </div>
    </div>
  );
};

export default InvoiceHeader;
