import {
    any,
    array,
    boolean,
    enums,
    integer,
    lazy,
    nullable,
    number,
    object,
    optional,
    type,
    string,
} from 'superstruct';
import { decimal, localDate, zonedDateTime } from '@tdc-cl/async-stuff';

export const models = {
    LoginResponse: () =>
        object({
            token: string(),
            id: integer(),
        }),

    ContabilidadVoucherData: () =>
        object({
            payer_set: object({
                filial_settings: array(lazy(models.PayerSettings)),
                non_filial_setting: optional(
                    nullable(lazy(models.PayerSettings))
                ),
            }),
            documents: array(lazy(models.Recibo)),
        }),
    Documento: () =>
        object({
            en_voucher: boolean(),
            external_id: string(),
            id: number(),
            monto: string(),
            pagado: boolean(),
            peso: string(),
            vencimiento: localDate(),
            support_filial: boolean(),
        }),
    CreateClientBankAccount: () =>
        object({
            id: integer(),
            banco: string(),
            tipo: string(),
            rut: string(),
            n_cuenta: string(),
            email: string(),
        }),
    FacturaItemsPreview: () =>
        object({
            monto: integer(),
            document: lazy(models.Recibo),
            data: string(),
            type: string(),
        }),
    FacturaPreview: () =>
        object({
            items: array(lazy(models.FacturaItemsPreview)),
            fecha: localDate(),
            pago_data: lazy(models.PagoData),
        }),
    MapfreAccount: () =>
        object({
            id: integer(),
            banco: string(),
            tipo: string(),
            rut: string(),
            cod_cia: string(),
            n_cuenta: string(),
            email: string(),
        }),

    CreateVoucherPart: () =>
        object({
            partition_part_id: integer(),
            direccion: integer(),
            cuenta_source: string(),
            cuenta_target: string(),
        }),

    ListClientBankAccount: () =>
        object({
            id: integer(),
            banco: string(),
            tipo: string(),
            rut: string(),
            n_cuenta: string(),
            email: string(),
        }),
    AnularResponse: () => object({}),
    Divisa: () => enums(['CLP', 'USD', 'UF', 'US$']),

    Morosidad: () =>
        object({
            monto: decimal(),
            divisa: lazy(models.Divisa),
            pagado: boolean(),
            id: integer(),
        }),

    FilialAppendResponse: () =>
        object({
            id: integer(),
        }),

    VoucherPagination: () =>
        object({
            count: integer(),
            next: optional(nullable(string())),
            previous: optional(nullable(string())),
            results: array(lazy(models.ListVoucher)),
        }),

    totalToShowDivisas: () =>
        object({
            CLP: optional(number()),
            USD: optional(number()),
            UF: optional(number()),
        }),

    ListVoucher: () =>
        object({
            id: integer(),
            fecha: zonedDateTime(),
            state: lazy(models.VoucherState),
            total: integer(),
            user: lazy(models.User),
            partes: array(lazy(models.ListVoucherPart)),
            vencimiento: localDate(),
            waiting_facturation: optional(boolean()),
            total_to_show: lazy(models.totalToShowDivisas),
        }),
    VoucherDetail: () =>
        object({
            id: integer(),
            fecha: zonedDateTime(),
            state: lazy(models.VoucherState),
            total: integer(),
            user: lazy(models.User),
            partes: array(lazy(models.ListVoucherPart)),
            vencimiento: localDate(),
            waiting_facturation: optional(boolean()),
            total_to_show: lazy(models.totalToShowDivisas),
        }),

    Recibo: () =>
        object({
            id: integer(),
            monto: decimal(),
            peso: string(),
            pagado: boolean(),
            vencimiento: localDate(),
            en_voucher: boolean(),
            external_id: string(),
            support_filial: boolean(),
        }),

    PolizaType: () =>
        enums(['Seguro General', 'Seguro de Vida', 'Garantias y Crédito']),

    Poliza: () =>
        object({
            divisa: lazy(models.Divisa),
            id: integer(),
            tipo: lazy(models.PolizaType),
            user: lazy(models.User),
            nombre: string(),
            recibos: array(lazy(models.Recibo)),
            external_id: string(),
        }),

    ListVoucherPart: () =>
        object({
            id: integer(),
            partition_part: lazy(models.PartitionPart),
            state: lazy(models.VoucherPartState),
            pago_data: any(),
            facturas: array(
                object({
                    uri: optional(nullable(string())),
                    state: lazy(models.FacturaState),
                })
            ),
        }),
    PagoData: () =>
        object({
            cuenta_target: lazy(models.MapfreAccount),
            cuenta_source: lazy(models.ListClientBankAccount),
            direccion: lazy(models.DireccionesSerialiazer),
            payer_setting: nullable(lazy(models.PayerSettings)),
        }),
    DireccionesSerialiazer: () =>
        object({
            id: integer(),
            nombre: string(),
            direccion: string(),
            ciudad: string(),
            comuna: string(),
        }),
    Filial: () =>
        object({
            id: integer(),
            nombre: string(),
            matriz_id: integer(),
            rut: string(),
        }),
    Cuenta: () =>
        object({
            id: integer(),
            banco: string(),
            tipo: string(),
            rut: string(),
            cod_cia: string(),
        }),

    Direccion: () =>
        object({
            id: integer(),
            nombre: string(),
            direccion: string(),
        }),

    FacturaState: () => enums(['No Confirmada', 'Confirmada', 'Emitida']),
    TotalWithDivisa: () =>
        object({ monto: number(), divisa: lazy(models.Divisa) }),
    PartitionPart: () =>
        type({
            id: integer(),
            items: optional(array(lazy(models.PartitionPartItem))),
            cod_cia: string(),
            total_ori: lazy(models.TotalWithDivisa),
            total_clp: lazy(models.TotalWithDivisa),
            monto_to_show: string(),
        }),

    PartitionResponse: () =>
        object({
            id: integer(),
            partes: array(lazy(models.PartitionPart)),
        }),

    VoucherPartState: () =>
        enums([
            'Pendiente / Facturación Requerida',
            'Pagado / Facturación Requerida',
            'Pendiente',
            'Pagado',
            'Pagado / Con Errores',
            'Error',
            'Expirado',
            'Anulado',
            'Pendiente / Con Errores',
            'Finalizado'
        ]),

    VoucherError: () =>
        object({
            divisa: lazy(models.Divisa),
            not_used_monto: string(),
            type: string(),
            voucher_part: nullable(number()),
            failed_documents: array(number()),
        }),
    NotAssigned: () =>
        object({
            divisa: lazy(models.Divisa),
            monto: string(),
            type: string(),
            account_source_bank: nullable(string()),
            account_source_number: nullable(string()),
            account_target_bank: nullable(string()),
            account_target_number: nullable(string()),
            timestamp: string(),
            match_score: number(),
            client_rut: nullable(string()),
            transaction_id: nullable(string()),
        }),

    RequestData: () =>
        type({
            voucher_part: number(),
            monto_cartola: nullable(
                array(object({ monto: number(), divisa: lazy(models.Divisa) }))
            ),
            monto_email: nullable(
                array(object({ monto: number(), divisa: lazy(models.Divisa) }))
            ),
            need_refund: nullable(number()),
        }),

    PaymentError: () =>
        object({
            payments_errors: array(lazy(models.VoucherError)),
            requests_data: array(lazy(models.RequestData)),
        }),

    VoucherState: () =>
        enums([
            'Pendiente / Facturación Requerida',
            'Pagado / Facturación Requerida',
            'Pendiente',
            'Pagado',
            'Pagado / Con Errores',
            'Error',
            'Expirado',
            'Anulado',
            'Pendiente / Con Errores',
            'Finalizado'
        ]),
    TipoEmpresa: () =>
        enums(['cliente', 'empresa', 'agente', 'contabilidad', 'cobrador']),

    PartitionPartItem: () =>
        object({
            id: integer(),
            data: string(),
            monto_ori: lazy(models.TotalWithDivisa),
            monto_clp: lazy(models.TotalWithDivisa),
            monto_to_show: string(),
            document: lazy(models.Documento),
        }),

    Empresa: () =>
        object({
            id: integer(),
            rut: string(),
            nombre: string(),
            email: string(),
        }),

    User: () =>
        object({
            id: integer(),
            rut: string(),
            nombre: string(),
            email: string(),
            facturacion_anticipada: boolean(),
            tipo: lazy(models.TipoEmpresa),
        }),

    ListPayerSettings: () =>
        object({
            id: integer(),
            porcentaje: integer(),
            oc: string(),
            hes: string(),
            das: string(),
            emails: array(string()),
            empresa: lazy(models.Empresa),
        }),
    PayerSettings: () =>
        object({
            id: integer(),
            porcentaje: integer(),
            emails: array(string()),
            empresa: lazy(models.Empresa),
            references: array(
                object({
                    reference: lazy(models.References),
                    folio: string(),
                    date: nullable(optional(string())),
                    text: nullable(optional(string())),
                })
            ),
        }),
    LastSettings: () =>
        object({
            filial_settings: array(lazy(models.PayerSettings)),
            non_filial_setting: nullable(lazy(models.PayerSettings)),
        }),

    References: () =>
        object({
            code: string(),
            id: number(),
            name: string(),
        }),
    Rates: () =>
        object({
            UF: number(),
            USD: number(),
            CLP: number(),
        }),
};
