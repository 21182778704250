import React, { useEffect, useState } from 'react';
import {
    useForm,
    $Form,
    $Button,
    Valid,
    CustomField,
    $Decimal,
    Invalid,
} from '@tdc-cl/x-form';

import Decimal from 'decimal.js';
import { Empresa, Reference } from '../../types/types';
import { useMutation, useQuery } from 'react-query';
import { facturaReference, generarSettings } from '../../endpoints/endpoints';
import { formatCurrency } from '../../utils/utils';
import { useAtom } from 'jotai';
import { newSettingsAtom } from '../../store/Atoms';
import Spinner from '../Loader/Spinner';

interface InvoiceProps {
    percentage: Decimal;
    total: number;
    filial: {
        id: number;
        porcentaje: number;
        references: {
            reference: Reference;
            folio: string;
            date?: string | null;
            text?: string | null;
        }[];
        emails: string[];
        empresa: Empresa;
    };
    cliientId: number;
    nonFilial?: boolean;
}
const InvoiceForm: React.FC<InvoiceProps> = ({
    percentage,
    total,
    filial,
    cliientId,
    nonFilial,
}) => {
    const [changed, setChanged] = useState(false);
    const [per, setPer] = useState(new Decimal(percentage));
    const [newSettings] = useAtom(newSettingsAtom);
    const [newPorcentaje, setNewPorcentaje] = useState<Decimal>(percentage);
    const generateSetting = useMutation(generarSettings.fetch, {
        onSuccess: data => {
            setChanged(false);
            const replaceID = newSettings.findIndex(
                setting => setting.id === filial.empresa.id
            );
            newSettings[replaceID] = {
                id: filial.empresa.id,
                setting: data.id,
                porcentaje: newPorcentaje,
            };
        },
    });

    const calcFromAmmount = (porcentaje: Decimal, total: Decimal) => {
        return porcentaje.times(total).dividedBy(new Decimal(100));
    };

    const ammount = calcFromAmmount(per, new Decimal(total)).toNumber();

    const $PorcentajeFilial = CustomField.extends($Decimal).with({
        validate(value) {
            if (value.greaterThan(new Decimal(100))) {
                return Invalid('El porcentaje no puede ser mayor a 100');
            } else {
                return Valid(value);
            }
        },
        inputProps: {
            className:
                'pl-4 rounded-l border border-gray-400 py-2 font-bold text-center w-20',
        },
    });

    const queryReferences = useQuery(
        [facturaReference.url],
        facturaReference.fetch
    );

    const getReferenceId = (value: string) => {
        const references = queryReferences.data;
        if (references) {
            const reference = references.find(
                x => x.code === value.toUpperCase()
            );
            return reference?.id ?? 0;
        } else {
            return 0;
        }
    };

    const form = useForm(
        $Form({
            fields: {
                porcentaje: $PorcentajeFilial('').with({
                    inputProps: {
                        onChange: (e: any) => {
                            const value =
                                e.target.value !== ''
                                    ? new Decimal(e.target.value)
                                    : new Decimal(0);
                            setChanged(true);
                            setNewPorcentaje(value);
                            setPer(value);
                        },
                        type: 'number',
                        disabled: nonFilial,
                    },
                }),
            },
            submit: $Button('GUARDAR CAMBIO', {
                async onValid(values) {
                    generateSetting.mutate({
                        ...filial,
                        references: filial.references.map(x => ({
                            reference: getReferenceId(x.reference.code),
                            folio: x.folio,
                            date: x.date,
                            text: x.text,
                        })),
                        porcentaje: values.porcentaje,
                        client_id: cliientId,
                        empresa: filial.empresa.id,
                    });
                },
                onInvalid: 'disable',
            }).with({
                buttonProps: {
                    className:
                        'bg-red400 rounded h-10 text-white font-bold text-xs focus:outline-none px-5 mt-3 sm:mt-0 flex items-center justify-center min-w-40 disabled:opacity-50',
                },
            }),
            props: {
                className:
                    'w-full sm:flex sm:justify-between justify-center items-center',
            },
        })
    );

    useEffect(() => {
        form.fillWith({ porcentaje: percentage });

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    const { porcentaje } = form.fields;
    return (
        <form {...form.props}>
            <div className='md:w-5/12 relative'>
                <input
                    className='text-sm rounded bg-gray100 border border-l-0 border-gray-400 p-2 pl-8 font-bold text-right pointer-events-none'
                    value={formatCurrency(new Decimal(ammount), 'CLP')}
                    readOnly
                />
                <div className='text-sm absolute top-0 left-0'>
                    <div className='relative'>
                        {porcentaje.render()}
                        <span className='absolute top-1 left-2 text-lg font-semibold'>
                            %
                        </span>
                    </div>
                </div>
            </div>

            {!nonFilial && (
                <div>
                    <button
                        {...form.submitter.buttonProps}
                        disabled={
                            form.submitter.buttonProps.disabled || !changed
                        }
                    >
                        {generateSetting.isLoading && (
                            <div className='mr-2'>
                                <Spinner />
                            </div>
                        )}
                        {changed ? 'GUARDAR CAMBIO' : 'GUARDADO'}
                    </button>
                </div>
            )}
        </form>
    );
};

export default InvoiceForm;
