import './xFormPatch';
import React from 'react';
import { ReactQueryDevtools } from 'react-query/devtools';
import './styles/index.css';
import './styles/typography.css';
import ReactDOM from 'react-dom';
import Layout from './Layout';
import { QueryClient, QueryClientProvider } from 'react-query';
import { Provider } from 'jotai';

ReactDOM.render(
    <React.StrictMode>
        <QueryClientProvider
            client={
                new QueryClient({
                    defaultOptions: {
                        queries: {
                            refetchOnWindowFocus: false,
                        },
                    },
                })
            }
        >
            <Provider>
                <Layout />
            </Provider>
            <ReactQueryDevtools initialIsOpen={false} />
        </QueryClientProvider>
    </React.StrictMode>,
    document.getElementById('root')
);
