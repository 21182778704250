import React, { useEffect } from 'react';
import Navbar from '../components/Navbar/Navbar';
import CompanyInfo from '../components/Corredor/CompanyInfo';
import Searcher from '../components/Corredor/Searcher';
import { useHistory } from 'react-router-dom';
import { User } from '../types/types';
import { clienteCorredorAtom, payListAtom } from '../store/Atoms';
import { useAtom } from 'jotai';
import { useQuery } from 'react-query';
import { ultimosClientes } from '../endpoints/endpoints';
import Loader from '../components/Loader/Loader';
import Screen from '../components/Screen/Screen';

const InicioCorredor = () => {
    const history = useHistory();
    const [, setCliente] = useAtom(clienteCorredorAtom);
    const { data, isSuccess, isLoading } = useQuery(ultimosClientes.url, () =>
        ultimosClientes.fetch()
    );
    const chooseCompany = (cliente: User) => {
        setCliente(cliente);
        localStorage.setItem('empresaMapfre', JSON.stringify(cliente));
        history.push({
            pathname: `/corredor/portal-de-pagos/seguros`,
            state: cliente,
        });
    };
    const [, setList] = useAtom(payListAtom);

    useEffect(() => {
        localStorage.setItem('payListMapfre', JSON.stringify([]));
        setList([]);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    return (
        <Screen>
            <Navbar />
            <Searcher chooseCompany={chooseCompany} />
            <div className='py-5 px-16'>
                <h4 className='font-medium mt-2 mb-5 text-center md:text-left text-gray700 text-xl'>
                    Tus últimos pagos fueron con:{' '}
                </h4>
                {isLoading && (
                    <div className='flex justify-center'>
                        <Loader />
                    </div>
                )}
                <div className='max-w-max'>
                    {isSuccess &&
                        data!
                            .filter((cliente) => cliente.tipo !== 'cliente')
                            .map((cliente) => (
                                <CompanyInfo
                                    cliente={cliente}
                                    key={cliente.id}
                                    onClick={() => chooseCompany(cliente)}
                                />
                            ))}
                </div>
                {isSuccess &&
                    data!.filter((cliente) => cliente.tipo !== 'cliente')
                        .length === 0 && (
                        <h6 className='mt-10'>
                            No hay transacciones realizadas
                        </h6>
                    )}
            </div>
        </Screen>
    );
};

export default InicioCorredor;
