import { useAtom } from 'jotai';
import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import { invoiceMethodAtom, payListAtom } from '../../store/Atoms';
import { AlertType } from '../../store/hooks';
import AlertInfo from '../Alert/AlertInfo';
import AlertModal from '../Alert/AlertModal';
import ActionButton from '../Buttons/ActionButton';
import CustomModal from '../CustomModal/CustomModal';
import PayItem from './PayItem';
import TotalDivisa from './TotalDivisa';
import ReactTooltip from 'react-tooltip';
import { useRol } from '../../customHooks/customHooks';

interface PaySummaryProps {
    onClick: (alertModal: AlertType) => void;
    isLoading: boolean;
    isError: boolean;
    disabled?: boolean;
    tooltip?: string;
}

const PaySummary: React.FC<PaySummaryProps> = ({
    onClick,
    isLoading,
    isError,
    disabled,
    tooltip,
}) => {
    const [showModal, setShowModal] = useState(false);
    const [list] = useAtom(payListAtom);
    const { paso } = useParams() as { paso: string };
    const [alertMessage, setAlertMessage] = useState('');

    const divisasTypes: string[] = [];
    list.forEach(
        item =>
            !divisasTypes.includes(item.divisa) &&
            divisasTypes.push(item.divisa)
    );

    const alertModal = {
        setShowModal,
        setAlertMessage,
    };

    return (
        <div className='h-full w-full'>
            <div
                className={`border-1-5 lg:fixed bg-gray150 rounded-lg min-h-70vh flex flex-col mt-4 md:mt-0 min-w-94 max-h-85vh overflow-y-auto ${
                    isError && 'mr-6'
                }`}
            >
                <div className='px-6 py-2 border-b-1-5 font-bold flex justify-between items-center'>
                    <p className='text-2xl text-gray700 pr-2'>Total a pagar</p>
                    <div className='flex items-center'>
                        {divisasTypes.length > 1 && (
                            <span className='text-2xl mr-3'>+</span>
                        )}
                        {divisasTypes.length === 0 && (
                            <span className='text-2xl text-gray700'>$0</span>
                        )}
                        <div>
                            <TotalDivisa divisa={'CLP'} />
                            <TotalDivisa divisa={'UF'} />
                            <TotalDivisa divisa={'USD'} />
                        </div>
                    </div>
                </div>

                {list.length > 0 && (
                    <div className='px-5 pt-2 flex-grow'>
                        {list.length > 0 &&
                            list.map(item => (
                                <PayItem key={item.id} {...item} />
                            ))}
                    </div>
                )}
                <div className='h-full flex-1 flex items-center'>
                    {list.length === 0 && (
                        <div className='w-full text-center h-full'>
                            <p className='text-gray700 text-sm italic'>
                                Haz clic en un seguro <br />
                                para añadir tus cuotas a pagar.
                            </p>
                        </div>
                    )}
                </div>
                {isError && (
                    <div className='my-10 flex justify-center gray600 font-semibold px-5'>
                        <AlertInfo className='text-red600'>
                            <p className='font-bold'>No es posible continuar</p>
                            <p className='font-normal'>
                                Por favor verifique y complete la información de
                                todos los campos para cada recibo e intente
                                nuevamente.
                            </p>
                        </AlertInfo>
                    </div>
                )}
                <div className='flex items-center justify-center pb-4 px-4 mt-auto'>
                    <ActionButton
                        dataFor={`${disabled && 'btn'}`}
                        disabled={disabled}
                        isLoading={isLoading}
                        onClick={() => onClick(alertModal)}
                    >
                        {paso === 'checkout'
                            ? 'Generar voucher de pago'
                            : 'Continuar con el pago'}
                    </ActionButton>
                    {disabled && (
                        <ReactTooltip
                            id='btn'
                            place='top'
                            className='text-xs px-1 p-0 mb-4 font-light h-5'
                        >
                            {tooltip}
                        </ReactTooltip>
                    )}
                </div>
            </div>

            <CustomModal
                isOpen={showModal}
                setIsOpen={setShowModal}
                size={'sm'}
            >
                <AlertModal
                    setShowModal={setShowModal}
                    alertMessage={alertMessage}
                />
            </CustomModal>
        </div>
    );
};

export default PaySummary;
