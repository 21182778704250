import { withStyles, Theme, createStyles } from '@material-ui/core/styles';
import TableCell from '@material-ui/core/TableCell';

export const ReciboCell = withStyles((theme: Theme) =>
    createStyles({
        head: {
            backgroundColor: '#F3F3F3',
            color: '#616664',
            fontSize: 13,
            fontWeight: 'bold',
            paddingTop: 18,
            paddingBottom: 18,
            border: 'none',
        },
        body: {
            fontSize: 13,
            color: '#363636',
            paddingTop: 10,
            paddingBottom: 10,
            backgroundColor: '#fff',
            fontWeight: 500,
            border: 'none',
        },
    })
)(TableCell);
