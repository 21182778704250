import React, { useState } from 'react';
import Navbar from '../components/Navbar/Navbar';
import SearchCliente from '../components/SearchBar/SearchCliente';
import PaymentsTable from '../components/PaymentsTable/PaymentsTable';
import CustomSelect from '../components/SearchBar/CustomSelect';
import { useRol } from '../customHooks/customHooks';
import Screen from '../components/Screen/Screen';

const MyPayments = () => {
    const [searchTerm, setSearchTerm] = useState('');
    const rol = useRol();
    return (
        <Screen>
            <Navbar />
            <section>
                <h4 className='mb-5 lg:mb-0 font-bold text-center md:text-left'>
                    Mis vouchers de pago
                </h4>
                <div className='mt-6 p-6 rounded-lg border border-gray200 bg-white'>
                    {rol !== 'persona' && (
                        <div className={`mt flex items-center mb-4`}>
                            <div className={`w-full md:w-1/3`}>
                                <SearchCliente
                                    search={(e) => {
                                        setSearchTerm(e);
                                    }}
                                />
                            </div>
                        </div>
                    )}

                    <div>
                        <PaymentsTable searchTerm={searchTerm} />
                    </div>
                </div>
            </section>
        </Screen>
    );
};

export default MyPayments;
