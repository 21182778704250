import Decimal from 'decimal.js';
import React, { useState } from 'react';
import circleDown from '../../svgs/circleDown.svg';
import { Empresa, Reference } from '../../types/types';
import EditPercentageForm from '../forms/EditPercentageForm';

interface VoucherPartsProps {
    state: any;
    filial: {
        id: number;
        porcentaje: number;
        references: {
            reference: Reference;
            folio: string;
            date?: string | null;
            text?: string | null;
        }[];
        emails: string[];
        empresa: Empresa;
    };
    total: number;
    cliientId: number;
    nonFilial?: boolean;
}

const VoucherParts: React.FC<VoucherPartsProps> = ({
    filial,
    total,
    cliientId,
    state,
    nonFilial,
}) => {
    const [show, setShow] = useState(false);

    return (
        <div className={'w-full mt-7 mb-6 lg:pr-7'}>
            <div className='rounded border-1-3 border-gray300 bg-white'>
                <div
                    className='flex p-4 font-bold cursor-pointer h-full'
                    onClick={() => (show ? setShow(false) : setShow(true))}
                >
                    <p onClick={() => console.log(state)}>Parte {filial.id}</p>
                    <img
                        src={circleDown}
                        alt='edit'
                        className=' ml-auto'
                        onClick={() => (show ? setShow(false) : setShow(true))}
                    />
                </div>

                <div
                    className={`${
                        !show ? 'hidden' : ' border-gray-300 border-t-1-5'
                    }`}
                >
                    <div className='mt-5'>
                        <label className='pl-5 font-bold'>Monto</label>
                    </div>
                    <div className='p-5 w-full sm:flex sm:justify-between justify-center'>
                        <EditPercentageForm
                            percentage={new Decimal(filial.porcentaje)}
                            total={total}
                            filial={filial}
                            cliientId={cliientId}
                            nonFilial={nonFilial}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default VoucherParts;
