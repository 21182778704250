import { useJsonStorage } from '@tdc-cl/async-stuff';
import { useAtom } from 'jotai';
import React from 'react';
import { useQuery } from 'react-query';
import { useHistory } from 'react-router-dom';
import { useRol } from '../../customHooks/customHooks';
import { authStorage, userLogin, userStorage } from '../../endpoints/endpoints';
import { payListAtom } from '../../store/Atoms';
import logout from '../../svgs/logout.svg';
import { LoginResponse, User } from '../../types/types';

const UserInfoNavbar = () => {
    const rol = useRol();
    const history = useHistory();
    const user: LoginResponse = useJsonStorage(authStorage)!;
    const { data, isError } = useQuery(
        userLogin.url,
        () => userLogin.fetch(user!.id),
        {
            onSuccess: (data: User) => userStorage.set(data),
        }
    );
    const [, setList] = useAtom(payListAtom);

    const userInfo: any = useJsonStorage(userStorage)!;
    const handleLogout = () => {
        authStorage.clear();
        userStorage.clear();
        localStorage.removeItem('payListMapfre');
        setList([]);
        if (userInfo.tipo === 'cobrador') {
            history.push(`/cobrador/login`);
        } else {
            history.push(`/${rol}/login`);
        }
    };

    return (
        <div className='flex lg:mr-5 ml-auto'>
            <div className='hidden lg:inline-block lg:border-r lg:border-gray-400'>
                <div className='lg:pr-3'>
                    {!!data && (
                        <>
                            <p className='font-bold mr-2 text-black'>
                                {data.nombre.toUpperCase()}
                            </p>
                        </>
                    )}
                    {isError && (
                        <p>
                            Hubo un problema. <br /> Por favor contacta con el
                            administrador
                        </p>
                    )}
                </div>
                <small
                    className='text-xs'
                    onClick={() => console.log(userStorage)}
                >
                    {!!data && data.rut}
                </small>
            </div>
            <div
                className='ml-8 flex justify-center items-center cursor-pointer'
                onClick={handleLogout}
            >
                <img src={logout} alt='out icon' className='mr-3' />
                <span className='font-bold text-black'>Salir</span>
            </div>
        </div>
    );
};

export default UserInfoNavbar;
