import Decimal from 'decimal.js';
import { atom } from 'jotai';
import { Money } from '../Money';
import { Divisa, PayerSettings, Recibo, User } from '../types/types';
import _ from 'lodash';
/*          PAY LIST           */

export interface ListType {
  nombre: string;
  id: string;
  recibos: Recibo[];
  divisa: Divisa;
  included: number[];
}

const initialListValue: ListType[] = !!localStorage.getItem('payListMapfre')
  ? JSON.parse(localStorage.getItem('payListMapfre')!)
  : [];

export const payListAtom = atom<ListType[]>(initialListValue);

export const reduceRecibos = atom(get => {
  if (get(payListAtom).length > 0) {
    const montos = get(payListAtom).map(item =>
      item.recibos.map(recibo => {
        if (item.divisa === 'UF') {
          return Money.UF(recibo.monto).as('CLP');
        } else if (item.divisa === 'USD') {
          return Money.USD(recibo.monto).as('CLP');
        } else {
          return recibo.monto;
        }
      })
    );
    return montos
      .flat(1)
      .reduce((a: Decimal, b: Decimal) => a.add(b), new Decimal(0));
  } else {
    return new Decimal(0);
  }
});

export const deleteItemAtom = atom(
  get => get(payListAtom),
  (get, set, itemID: string) => {
    const list = get(payListAtom);
    set(
      payListAtom,
      list.filter(item => item.id !== itemID)
    );
    localStorage.setItem('payListMapfre', JSON.stringify(get(payListAtom)));
  }
);

export const deleteReciboAtom = atom(
  get => get(payListAtom),
  (get, set, reciboID: number) => {
    const list = get(payListAtom);
    set(
      payListAtom,
      list.filter(items => {
        const indexOfRecibo = _.findIndex(
          items.recibos,
          o => o.id === reciboID
        );
        const newItem = Object.assign(items, {
          recibos: items.recibos.filter(recibo => recibo.id !== reciboID),
          included: items.included.filter(x => x !== indexOfRecibo),
        });
        return newItem.recibos.length > 0;
      })
    );
    localStorage.setItem('payListMapfre', JSON.stringify(get(payListAtom)));
  }
);

/* PARTITION PERSONA */

export const documentosRecibosAtom = atom(get => {
  return {
    documents: get(payListAtom)
      .map(item => item.recibos.map(recibo => recibo.id))
      .flat(1),
  };
});

/* GENERAR VOUCHER PERSONA */

export const voucherInfoAtom = atom<{
  partes: {
    partition_part_id: number;
    direccion: number;
    cuenta_source?: number;
    cuenta_target: number;
  }[];
  factura_mode: '1' | '2' | null;
}>({
  partes: [
    {
      partition_part_id: 0,
      direccion: 0,
      cuenta_source: undefined,
      cuenta_target: 0,
    },
  ],
  factura_mode: null,
});

/* SET MODAL SHOW */
export const showPartPreviewAtom = atom(false);
export const showPreviewAtom = atom(false);
export const facturaConfirmadaAtom = atom(false);
export const setPreviewAtom = atom('');
export const invoiceMethodAtom = atom<boolean>(false)


/* CORREDOR CLIENTE */

const clienteStorage: User =
  !!localStorage.getItem('empresaMapfre') &&
  JSON.parse(localStorage.getItem('empresaMapfre')!);

export const clienteCorredorAtom = atom<User>(clienteStorage);
export const clienteCorredorID = atom(get =>
  !!get(clienteCorredorAtom) ? get(clienteCorredorAtom)!.id : undefined
);

/* CORREDOR CLIENTE */

export const porcentajeTotalAtom = atom<Decimal>(new Decimal(0));
export const filialSettingAtom = atom<{ empresa: number; setting: number }[]>(
  []
);
export const nonFilialSettingAtom =
  atom<{ empresa: number; setting: number } | null>(null);

export const filialesAtom = atom<PayerSettings[]>([]);
export const nonFilialesAtom = atom<PayerSettings | undefined>(undefined);

/*              SETTINGS EDIT VOUCHER             */

export const newSettingsAtom = atom<
  { id: number; setting: number; porcentaje: Decimal }[]
>([]);

/*              SETTINGS EDIT VOUCHER             */

export const selectedVoucherAtom = atom<{ voucher: number; show: boolean }>({
  voucher: 0,
  show: false,
});

/*              REFETCH BANK ACCOUNTS            */

export const refetchAccountsAtom = atom<number>(0);

/*              REFETCH TABLE            */

export const refetchTableAtom = atom<number>(0);

