import React from 'react';
import AnticipadoBadge from '../Badges/AnticipadoBadge';
import arrowLeft from '../../svgs/arrowLeft.svg';
import IconEdit from '../../svgs/iconEdit.svg';
import { useHistory } from 'react-router-dom';
import { useAtom } from 'jotai';
import { newSettingsAtom } from '../../store/Atoms';

interface HeaderProps {
  id: number;
  name: string;
  anticipado: boolean;
}

const EditHeader: React.FC<HeaderProps> = ({ id, name, anticipado }) => {
  const history = useHistory();
  const [, setNewSettings] = useAtom(newSettingsAtom);
  return (
    <>
      <img
        src={arrowLeft}
        alt='arrow icon'
        className='inline mr-3 cursor-pointer'
        onClick={() => {
          history.goBack();
          setNewSettings([]);
        }}
      />

      <div className='flex mt-5 gray400'>
        <p className='text-2xs'>
          VOUCHERS {'>'} ID #{id}
        </p>

        <span className='text-2xs ml-1'>
          {'>'}{' '}
          <span className='font-bold'>ANULAR Y GENERAR NUEVO VOUCHER</span>
        </span>
      </div>
      <div className='flex items-center mt-3'>
        <h4 className='gray600 lg:mr-5'>{name}</h4>
        {anticipado && (
          <div className='ml-2 mr-3 lg:mr-0'>
            <AnticipadoBadge />
          </div>
        )}
        <div className='ml-auto lg:pr-6'>
          <img src={IconEdit} alt='back icon' className='w-11/12' />
        </div>
      </div>
    </>
  );
};

export default EditHeader;
