import React, { useState } from 'react';
import upArrow from '../../svgs/up-arrow.svg';
import circleDown from '../../svgs/circle-ctrl-down.svg';
import { Currency } from '../../types/alias';
import { Recibo } from '../../types/types';
import { formatCurrency, formatDate, proximaCuota } from '../../utils/utils';
import { classNamed } from '../../utils/utils';

interface InsuranceInfoProps {
    divisa: Currency;
    nombre: string;
    id: string;
    recibos: Recibo[];
    showMore: boolean;
    setShowMore: (text: boolean) => void;
}

const InsuranceInfo: React.FC<InsuranceInfoProps> = ({
    divisa,
    nombre,
    id,
    recibos,
    showMore,
    setShowMore,
}) => {
    const filteredRows = recibos.filter(
        (recibo) => recibo.en_voucher === false && recibo.pagado === false
    );
    const [isHover, setIsHover] = useState(false);
    const siguienteFecha = proximaCuota(recibos)?.vencimiento;
    const siguienteMonto = proximaCuota(recibos)?.monto;
    const InfoTitle = classNamed.p('text-sm mb-2');
    const Info = classNamed.span('text-sm text-gray700');
    return (
        <div
            onClick={() => (showMore ? setShowMore(false) : setShowMore(true))}
            onMouseEnter={() => setIsHover(true)}
            onMouseLeave={() => setIsHover(false)}
            className='py-3 grid grid-cols-4 cursor-pointer lg:grid-cols-12 px-5 lg:px-0 justify-items-center items-center lg:justify-items-start h-full'
        >
            <div className='col-span-3 lg:col-span-4 mb-3 lg:mb-0 pl-5'>
                <h6 className='gray600 text-base font-bold'>{nombre}</h6>
                <span className='rounded text-xs gray600 flex items-cente mt-1 text-left gray400'>
                    N° de Póliza: {id}
                </span>
            </div>

            <div className='lg:col-span-2 flex flex-col lg:mt-0'>
                <Info>{filteredRows.length}</Info>
            </div>
            <div className='lg:col-span-2 lg:mt-0 flex flex-col'>
                <Info>
                    {siguienteMonto ? (
                        formatCurrency(siguienteMonto, divisa)
                    ) : (
                        <span className='w-full text-center'>&#8212;</span>
                    )}
                </Info>
            </div>
            <div className='lg:col-span-2 lg:mt-0 flex flex-col'>
                {
                    <Info>
                        {siguienteFecha ? (
                            formatDate(siguienteFecha)
                        ) : (
                            <span className='w-full text-center justify-center items-center'>
                                &#8212;
                            </span>
                        )}
                    </Info>
                }
            </div>
            <div className='lg:col-span-2 flex items-center justify-center cursor-pointer pr-'>
                <div
                    className={`flex items-center justify-center rounded-full bg-gray200 h-10 min-w-9 px-5 text-sm ${
                        isHover && 'bg-gray250'
                    }`}
                >
                    Ver cuotas
                    <img
                        className={`ml-2 ${showMore && 'rotate-180'}`}
                        src={circleDown}
                        alt='see more icon'
                        onClick={() =>
                            showMore ? setShowMore(false) : setShowMore(true)
                        }
                    />
                </div>
            </div>
        </div>
    );
};

export default InsuranceInfo;
