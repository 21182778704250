import React, { useState, useEffect } from 'react';
import { formatCurrency } from '../../utils/utils';
import iconTrash from '../../svgs/iconTrash.svg';
import { Currency } from '../../types/alias';
import Decimal from 'decimal.js';
import ReactTooltip from 'react-tooltip';

interface PayRemoveProp {
  amount: Decimal;
  divisa: Currency;
  id: string | number;
  deleteItem: (arg: any) => void;
  disabled?: boolean;
}
const PayRemove: React.FC<PayRemoveProp> = ({
  amount,
  divisa,
  id,
  deleteItem,
  disabled,
}) => {
  const [isTooltip, setIsTooltip] = useState(false);
  useEffect(() => {
    if (disabled === true) {
      setIsTooltip(true);
    }
    if (disabled === false) {
      setIsTooltip(false);
    }
  }, [disabled]);
  return (
    <>
      {isTooltip && (
        <ReactTooltip
          id='btn'
          place='top'
          className='text-xs px-1 p-0 mb-4 font-light'
        >
          Debes eliminar el último recibo
        </ReactTooltip>
      )}
      <div className='flex'>
        <span className='text-sm font-bold min-w-20 px-2 h-9 border border-gray200 flex items-center justify-center'>
          {formatCurrency(amount, divisa)}
        </span>
        <span
          data-tip
          data-for={disabled && 'btn'}
          className={`h-9 w-10 rounded-r bg-red400 border  border-red400  flex items-center justify-center ${
            disabled
              ? 'opacity-60'
              : ' cursor-pointer hover:bg-red600 hover:border-red600'
          }`}
          onClick={() => (disabled ? null : deleteItem(id))}
        >
          <img src={iconTrash} alt='trash icon' />
        </span>
      </div>
    </>
  );
};

export default PayRemove;
