import React from 'react';
import Navbar from '../../components/Navbar/Navbar';
import Screen from '../Screen/Screen';

import ErrorsTable from './ErrorsTable';

const PaymentErrors = () => {
    return (
        <Screen>
            <Navbar />
            <section>
                <div className=''>
                    <div>
                        <h4 className='mb-5 lg:mb-0 font-bold text-center md:text-left'>
                            Pagos no asignados
                        </h4>
                    </div>

                    <div className='mt-6 p-6 rounded-lg border border-gray200 bg-white'>
                        <ErrorsTable />
                    </div>
                </div>
            </section>
        </Screen>
    );
};

export default PaymentErrors;
