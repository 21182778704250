import React from 'react';
import circleDown from '../../svgs/circleDown.svg';

interface InsuranceProps {
    nombre: string;
    id: string;
    showMore: boolean;
    setShowMore: (arg: boolean) => void;
}

const InsuranceSummary: React.FC<InsuranceProps> = ({
    nombre,
    id,
    showMore,
    setShowMore,
}) => {
    return (
        <div
            onClick={() => (showMore ? setShowMore(false) : setShowMore(true))}
            className='w-full cursor-pointer'
        >
            <div className='flex justify-between'>
                <div>
                    <span className='font-bold text-tiny text-gray700'>
                        {nombre}
                    </span>
                    <small className='block text-gray400 text-2xs'>
                        N° Poliza: {id}
                    </small>
                </div>

                <img
                    src={circleDown}
                    alt='circle down logo'
                    className='w-5 mr-5  cursor-pointer'
                    onClick={() =>
                        showMore ? setShowMore(false) : setShowMore(true)
                    }
                />
            </div>
        </div>
    );
};

export default InsuranceSummary;
