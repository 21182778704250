import React from 'react';

const AnticipadoBadge = () => {
    return (
        <small className='bg-gray100 text-green700 font-bold py-1 px-2 rounded flex justify-center items-center text-xs'>
            Facturador anticipado
        </small>
    );
};

export default AnticipadoBadge;
