/* eslint-disable array-callback-return */
import React, { useState, useEffect, useRef } from 'react';
import SearchCliente from '../SearchBar/SearchCliente';
import ActionButton from '../Buttons/ActionButton';
import Invoice from '../Invoice/Invoice';
import CorredorHeader from './CorredorHeader';
import { filialesAtom, nonFilialesAtom, payListAtom } from '../../store/Atoms';
import { useAtom } from 'jotai';
import {
    clienteCorredorAtom,
    clienteCorredorID,
    filialSettingAtom,
    nonFilialSettingAtom,
    porcentajeTotalAtom,
} from '../../store/Atoms';
import { Empresa, LastSettings, PayerSettings } from '../../types/types';
import { useHistory, useLocation } from 'react-router-dom';
import Loader from '../Loader/Loader';
import Decimal from 'decimal.js';
import {
    clientesCorredor,
    divisaRates,
    lastSettings,
} from '../../endpoints/endpoints';
import { useQuery } from 'react-query';
import AlertInfo from '../Alert/AlertInfo';
import { useBoolean } from '../../customHooks/useBoolean';
import { Money } from '../../Money';
import cInfo from '../../svgs/c-info.svg';

const PartsCorredor = () => {
    const [clientID] = useAtom(clienteCorredorID);
    const [, setFilialSetting] = useAtom(filialSettingAtom);
    const [, setNonFilialSetting] = useAtom(nonFilialSettingAtom);
    const [, setPorcentajeTotal] = useAtom(porcentajeTotalAtom);
    const [filiales, setFiliales] = useAtom(filialesAtom);
    const [nonFiliales, setNonFiliales] = useAtom(nonFilialesAtom);
    const [cliente] = useAtom(clienteCorredorAtom);
    const { state } = useLocation();
    const history = useHistory();
    const [searchTerm, setSearchTerm] = useState('');
    const [optionFilial, setOptionFilial] = useState<Empresa[]>([]);
    const [selectedOption, setSelectedOption] = useState<Empresa | undefined>();
    const [prefill, setPrefill] = useState(false);
    const [alertMessage, setAlertMessage] = useState('');
    const [showModal, setShowModal] = useState(false);
    const [fetchLastSetting, setFetchLastSetting] = useState(false);
    const [list] = useAtom(payListAtom);
    const [hoveredRow, setHoveredRow] = useState<number | undefined>(undefined);

    const paymentsList = useQuery(
        divisaRates.url,
        () => divisaRates.fetch(''),
        {
            onSuccess: data => {
                Money.setUfValue(new Decimal(Number(data.UF)));
                Money.setUsdValue(new Decimal(Number(data.USD)));
            },
        }
    );

    const addFilial = (empresa: Empresa) => {
        if (filiales.length) {
            if (
                !filiales.some(
                    filial => filial.empresa.id === selectedOption!.id
                )
            ) {
                setFiliales([
                    {
                        id: selectedOption!.id,
                        porcentaje: 0,
                        emails: [''],
                        empresa,
                        references: [
                            {
                                reference: {
                                    code: '',
                                    id: 0,
                                    name: '807 - DUS',
                                },
                                folio: 'hh',
                            },
                        ],
                    },
                    ...filiales,
                ]);
            } else {
                setShowModal(true);
                setAlertMessage('El filial escogido ya está agregado');
            }
        } else {
            setFiliales([
                {
                    id: selectedOption!.id,
                    porcentaje: 0,
                    references: [
                        {
                            reference: {
                                code: '',
                                id: 11,
                                name: '807 - DUS',
                            },
                            folio: 'hh',
                        },
                    ],
                    emails: [''],
                    empresa,
                },
            ]);
        }
    };

    /*             FETCH             */

    const lastSettingsQuery = useQuery(
        [lastSettings.url, clientID],
        () => lastSettings.fetch(clientID!),
        {
            onSuccess: (data: LastSettings) => {
                setFiliales(data.filial_settings);

                if (
                    list.some(recibo =>
                        recibo.recibos.some(x => x.support_filial === false)
                    )
                ) {
                    if (!!data.non_filial_setting) {
                        setNonFiliales(data.non_filial_setting);
                    }
                    if (data.non_filial_setting) {
                        setNonFilialSetting(
                            data.non_filial_setting
                                ? {
                                      empresa:
                                          data.non_filial_setting.empresa.id,
                                      setting: data.non_filial_setting.id,
                                  }
                                : null
                        );
                    }
                }

                if (
                    !list.every(recibo =>
                        recibo.recibos.every(x => x.support_filial === false)
                    )
                ) {
                    setFilialSetting(
                        data.filial_settings.map((filial: PayerSettings) => {
                            return {
                                empresa: filial.empresa.id,
                                setting: filial.id,
                            };
                        })
                    );
                }

                setPorcentajeTotal(
                    data.filial_settings.reduce(
                        (a, b) => a.add(b.porcentaje),
                        new Decimal(0)
                    )
                );

                setFetchLastSetting(false);
            },
            enabled: fetchLastSetting,
            onSettled: () => setFetchLastSetting(false),
            onError: () => setPrefill(false),
        }
    );

    useQuery([clientesCorredor.url], () => clientesCorredor.fetch(), {
        onSuccess: data =>
            setOptionFilial(data.filter(filial => filial.tipo !== 'cliente')),
    });

    useEffect(() => {
        if (!state) {
            history.push('/corredor/portal-de-pagos/seguros');
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [state]);

    useEffect(() => {
        if (
            list.some(recibo =>
                recibo.recibos.some(x => x.support_filial === false)
            ) &&
            !nonFiliales
        ) {
            setNonFiliales({
                id: 0,
                porcentaje: 100,
                emails: [''],
                empresa: {
                    id: cliente.id,
                    rut: cliente.rut,
                    nombre: cliente.nombre,
                    email: cliente.email,
                },
                references: [
                    {
                        reference: {
                            code: '',
                            id: 0,
                            name: '807 - DUS',
                        },
                        folio: 'hh',
                    },
                ],
            });
        }
    }, []);

    const filialesAmmount = (condition: boolean) =>
        list
            .map(poliza => {
                return {
                    divisa: poliza.divisa,
                    recibos: poliza.recibos.filter(
                        recibo => recibo.support_filial === condition
                    ),
                };
            })
            .map(poliza =>
                poliza.recibos.map(recibo => {
                    if (paymentsList.isSuccess) {
                        if (poliza.divisa === 'UF') {
                            return Money.UF(recibo.monto).as('CLP');
                        } else if (poliza.divisa === 'USD') {
                            return Money.USD(recibo.monto).as('CLP');
                        } else {
                            return recibo.monto;
                        }
                    } else {
                        return recibo.monto;
                    }
                })
            )
            .flat(1)
            .reduce((a: Decimal, b: Decimal) => a.add(b), new Decimal(0));

    const optionsRef = useRef(null);
    const setSearched = useBoolean(false);

    return (
        <>
            <div>
                <CorredorHeader
                    link='/corredor/portal-de-pagos/seguros'
                    checkout={true}
                    cliente={cliente!}
                    onClick={() => {
                        if (!prefill) {
                            setPrefill(true);
                            setFetchLastSetting(true);
                        }
                    }}
                    prefill={prefill}
                    filialesLength={filiales.length}
                />
                <>
                    {nonFiliales && (
                        <>
                            <h4 className='text-gray700 text-xl font-bold mb-3'>
                                Recibos que no aceptan pagos de empresas
                                filiales
                            </h4>
                            <div className='bg-calcite py-6 px-8 rounded-md border border-gray-200 lg:mr-5'>
                                <p className='text-gray700 font-bold mb-10'>
                                    El pago de estos recibos debe ser realizado
                                    por {cliente?.nombre}
                                </p>
                                <div className='px-5 lg:mr-5'>
                                    <div className='w-full grid grid-cols-12'>
                                        <div className='col-span-3'></div>
                                        <p className='col-span-3 text-gray400 text-tiny font-bold'>
                                            Empresa pagadora
                                        </p>

                                        <p className='col-span-2 text-gray400 text-tiny font-bold'>
                                            $ Monto en pesos
                                        </p>
                                        <p className='col-span-3 text-gray400 text-tiny font-bold flex items-center'>
                                            % Porcentaje de pago{' '}
                                        </p>

                                        <div className='col-span-1'>
                                            <span className='opacity-0'>h</span>
                                        </div>
                                    </div>
                                </div>
                                <Invoice
                                    loading={paymentsList.isLoading}
                                    prefill={prefill}
                                    filial={nonFiliales!}
                                    monto={filialesAmmount(false)}
                                    filiales={filiales}
                                    setFiliales={setFiliales}
                                    nonFilial
                                />
                            </div>
                        </>
                    )}
                    {!list.every(recibo =>
                        recibo.recibos.every(x => x.support_filial === false)
                    ) && (
                        <>
                            <h4 className='text-gray700 text-xl font-bold mt-5 mb-3'>
                                Recibos que aceptan pagos de empresas filiales
                            </h4>
                            <div className='lg:mr-5 bg-calcite py-6 px-8 rounded-md border border-gray-200'>
                                <p className='text-gray700 font-bold mb-2'>
                                    Busque en las empresas filiales y complete
                                    la información para cada parte del recibo
                                </p>
                                <div className='mt-5 md:flex md:w-8/12 mb-2'>
                                    <div className='w-full relative'>
                                        {!selectedOption ? (
                                            <SearchCliente
                                                search={e => setSearchTerm(e)}
                                                setSearched={setSearched}
                                                optionsRef={optionsRef}
                                            />
                                        ) : (
                                            <div
                                                key={selectedOption.id}
                                                className='flex justify-between w-full py-3 px-8 bg-white rounded text-xs font-bold border border-red-600'
                                            >
                                                <span>
                                                    {selectedOption.nombre}
                                                </span>
                                                <span>
                                                    {selectedOption.rut}
                                                </span>
                                            </div>
                                        )}

                                        {setSearched.value &&
                                            optionFilial &&
                                            !selectedOption && (
                                                <div
                                                    ref={optionsRef}
                                                    className={`absolute top-12 text-black border border-gray bg-white w-full rounded text-xs font-semibold gray600 z-20 ${
                                                        optionFilial.length ===
                                                            0 && 'p-6'
                                                    }`}
                                                >
                                                    {optionFilial.length > 0 ? (
                                                        optionFilial.map(
                                                            cliente => {
                                                                if (
                                                                    cliente.rut
                                                                        .toLowerCase()
                                                                        .startsWith(
                                                                            searchTerm.toLowerCase()
                                                                        ) ||
                                                                    cliente.nombre
                                                                        .toLowerCase()
                                                                        .startsWith(
                                                                            searchTerm.toLowerCase()
                                                                        )
                                                                ) {
                                                                    return (
                                                                        <div
                                                                            key={
                                                                                cliente.id
                                                                            }
                                                                            className={`${
                                                                                cliente.id ===
                                                                                    hoveredRow &&
                                                                                'bg-gray200'
                                                                            } flex justify-between w-full border-b py-4 px-6 cursor-pointer`}
                                                                            onClick={() =>
                                                                                setSelectedOption(
                                                                                    cliente
                                                                                )
                                                                            }
                                                                            onMouseEnter={() =>
                                                                                setHoveredRow(
                                                                                    cliente.id
                                                                                )
                                                                            }
                                                                            onMouseLeave={() =>
                                                                                setHoveredRow(
                                                                                    undefined
                                                                                )
                                                                            }
                                                                        >
                                                                            <span>
                                                                                {
                                                                                    cliente.nombre
                                                                                }
                                                                            </span>
                                                                            <span>
                                                                                {
                                                                                    cliente.rut
                                                                                }
                                                                            </span>
                                                                        </div>
                                                                    );
                                                                }
                                                            }
                                                        )
                                                    ) : (
                                                        <div className='flex justify-between w-full border-b py-4 px-6 cursor-pointer'>
                                                            No hay clientes con
                                                            este nombre o rut
                                                        </div>
                                                    )}
                                                </div>
                                            )}
                                    </div>
                                    <div className='flex justify-center md:pl-2 mt-4 md:mt-0 w-2/6'>
                                        <ActionButton
                                            disabled={
                                                !selectedOption ? true : false
                                            }
                                            onClick={() => {
                                                addFilial(selectedOption!);
                                                setSearchTerm('');
                                                setSelectedOption(undefined);
                                                setSearched.setValue(false);
                                            }}
                                        >
                                            Agregar
                                        </ActionButton>
                                    </div>
                                </div>

                                {filiales.length ? (
                                    <div className='mt-10'>
                                        <div className='px-5 lg:mr-5'>
                                            <div className='w-full grid grid-cols-12'>
                                                <div className='col-span-3'></div>
                                                <p className='col-span-3 text-gray400 text-tiny font-bold'>
                                                    Empresa pagadora
                                                </p>
                                                <p className='col-span-2 text-gray400 text-tiny font-bold'>
                                                    $ Monto en pesos
                                                </p>
                                                <p className='col-span-3 text-gray400 text-tiny font-bold flex items-center'>
                                                    % Porcentaje de pago{' '}
                                                </p>
                                                <div className='col-span-1'>
                                                    <span className='opacity-0'>
                                                        h
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                        {filiales.map(filial => (
                                            <div
                                                className='mt-1'
                                                key={filial.empresa.id}
                                            >
                                                <Invoice
                                                    prefill={prefill}
                                                    filial={filial}
                                                    monto={filialesAmmount(
                                                        true
                                                    )}
                                                    filiales={filiales}
                                                    setFiliales={setFiliales}
                                                />
                                            </div>
                                        ))}
                                    </div>
                                ) : null}
                            </div>
                        </>
                    )}
                </>
            </div>
            {lastSettingsQuery.isLoading && (
                <div className='flex justify-center my-14'>
                    <Loader />
                </div>
            )}
            {lastSettingsQuery.isError && (
                <AlertInfo
                    className='mt-10 md:mr-5 text-center'
                    errorMessage={'Hubo un error, por favor inténtalo de nuevo'}
                />
            )}
        </>
    );
};

export default PartsCorredor;
