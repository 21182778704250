import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { LocalDate, ZonedDateTime } from 'js-joda';
import VoucherRow from './VoucherRow';
import TableHeader from './TableHeader';
import {
    User,
    VoucherPagination,
    ListVoucherPart,
    VoucherState,
    TotalToShow,
} from '../../types/types';
import { useQuery } from 'react-query';
import { authStorage, fetchVouchers } from '../../endpoints/endpoints';
import Loader from '../Loader/Loader';
import { useJsonStorage } from '@tdc-cl/async-stuff';
import AlertInfo from '../Alert/AlertInfo';
import { selectedVoucherAtom, refetchTableAtom } from '../../store/Atoms';
import { useAtom } from 'jotai';
import { TableCell } from '@material-ui/core';
import { useRol } from '../../customHooks/customHooks';

const useStyles = makeStyles({
    table: {
        minWidth: 650,
    },
});

const createData = (
    id: number,
    fecha: ZonedDateTime,
    state: VoucherState,
    total: number,
    user: User,
    partes: ListVoucherPart[],
    vencimiento: LocalDate,
    total_to_show: TotalToShow,
    waiting_facturation?: boolean
) => {
    return {
        id,
        fecha,
        state,
        total,
        user,
        partes,
        vencimiento,
        total_to_show,
        waiting_facturation,
    };
};

const PaymentsTable: React.FC<{
    searchTerm: string;
}> = ({ searchTerm }) => {
    const user = useJsonStorage(authStorage);
    const rol = useRol();
    const [page, setPage] = useState(1);
    const [loading, setLoading] = useState(false);
    const [refetchTable] = useAtom(refetchTableAtom);
    const { data, isLoading, isError, error } = useQuery<VoucherPagination>(
        ['voucherFetch', page, refetchTable],
        () => fetchVouchers.fetch(user!.id, `${page}&`),
        {
            refetchOnMount: 'always',
            onSettled: () => setLoading(false),
        }
    );

    const totalPages = (data && Math.ceil(data!.count / 25)) ?? 0;

    const classes = useStyles();
    const rows =
        data &&
        data.results?.map(voucher => {
            return createData(
                voucher.id,
                voucher.fecha,
                voucher.state,
                voucher.total,
                voucher.user,
                voucher.partes,
                voucher.vencimiento,
                voucher.total_to_show,
                voucher.waiting_facturation
            );
        });

    const [selectedVoucher, setSelectedVoucher] = useAtom(selectedVoucherAtom);
    useEffect(() => {
        setLoading(true);
        return () => {
            setSelectedVoucher({
                voucher: selectedVoucher.voucher,
                show: false,
            });
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <>
            <TableContainer
                style={{
                    borderRadius: '7px',
                    borderWidth: '1px',
                    borderStyle: 'solid',
                    borderColor: '#E1E1E1',
                }}
            >
                <>
                    <Table
                        className={classes.table}
                        size='small'
                        aria-label='spaning table'
                    >
                        <TableHead>
                            <TableRow
                                style={{
                                    height: '50px',
                                    backgroundColor: '#EEEEEE',
                                }}
                            >
                                <TableHeader />
                            </TableRow>
                        </TableHead>
                        {loading || isLoading ? (
                            <>
                                <TableCell style={{ border: 'none' }} />
                                <TableCell style={{ border: 'none' }} />
                                <TableCell style={{ border: 'none' }} />
                                {rol !== 'persona' && (
                                    <>
                                        <TableCell style={{ border: 'none' }} />
                                    </>
                                )}
                                <TableCell
                                    style={{ border: 'none' }}
                                    colSpan={8}
                                >
                                    <Loader />
                                </TableCell>
                            </>
                        ) : isError ? (
                            <AlertInfo errorMessage={`${error}`} reload />
                        ) : rows?.length ? (
                            <TableBody>
                                {!!rows && (
                                    <VoucherRow
                                        rows={rows}
                                        searchTerm={searchTerm}
                                    />
                                )}
                            </TableBody>
                        ) : (
                            <>
                                <TableCell style={{ border: 'none' }} />
                                <TableCell style={{ border: 'none' }} />
                                {rol !== 'persona' && (
                                    <>
                                        <TableCell style={{ border: 'none' }} />
                                    </>
                                )}
                                <TableCell
                                    style={{ border: 'none' }}
                                    colSpan={8}
                                >
                                    <p className='ml-24 py-6'>
                                        No hay vouchers de pago
                                    </p>
                                </TableCell>
                            </>
                        )}
                    </Table>
                </>
            </TableContainer>
            {totalPages > 1 && !loading && (
                <ul className='flex items-center rounded w-full font-sans mt-3 text-sm justify-end'>
                    <p className='mr-5 text-base text-gray700'>{`Página ${page} de ${totalPages}`}</p>
                    {
                        <li
                            className={`text-base block cursor-pointer px-3 py-2 mr-1 ${
                                !data?.previous &&
                                'pointer-events-none text-gray300'
                            }`}
                            onClick={() => setPage(page - 1)}
                        >
                            Anterior
                        </li>
                    }
                    -
                    {
                        <li
                            className={`text-base block cursor-pointer px-3 py-2 ml-1 ${
                                !data?.next &&
                                'pointer-events-none text-gray300'
                            }`}
                            onClick={() => setPage(page + 1)}
                        >
                            Siguiente
                        </li>
                    }
                </ul>
            )}
        </>
    );
};
export default PaymentsTable;
