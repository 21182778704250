import React from 'react';
import { FacturaPreview } from '../../types/types';
import { formatDate } from '../../utils/utils';
interface InfoProps {
    factura: FacturaPreview;
}

const InvoiceInfo: React.FC<InfoProps> = ({ factura }) => {
    return (
        <div className='border-1-5 border-gray-400 mt-5 p-2 grid grid-cols-12'>
            <div className='col-span-7 flex flex-col text-2xs'>
                <div className='flex'>
                    <div className='flex flex-col font-bold'>
                        <span>FECHA:</span>
                        <span>NOMBRE:</span>
                        <span>DIRECCIÓN:</span>
                        <span>CIUDAD:</span>
                    </div>
                    <div className='ml-16 flex flex-col text-gray600 font-base'>
                        <span>{formatDate(factura.fecha)}</span>
                        <span>
                            {factura.pago_data.payer_setting!.empresa.nombre}
                        </span>
                        <span>{factura.pago_data.direccion.direccion}</span>
                        <span>{factura.pago_data.direccion.ciudad}</span>
                    </div>
                </div>
            </div>
            <div className='col-span-5 text-2xs'>
                <div className='flex'>
                    <div className='flex flex-col font-bold'>
                        <span>RUT:</span>
                        <span>COMUNA:</span>
                    </div>
                    <div className='ml-16 flex flex-col text-gray600 font-base'>
                        <span>
                            {factura.pago_data.payer_setting!.empresa.rut}
                        </span>
                        <span>{factura.pago_data.direccion.comuna}</span>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default InvoiceInfo;
