import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { Divisa, PartitionPartItem } from '../../../types/types';
import { StyledTableCell } from '../../PaymentsTable/TableStyle';
import { formatCurrency } from '../../../utils/utils';
import Decimal from 'decimal.js';
import ActionButton from '../../Buttons/ActionButton';
import { useQuery } from 'react-query';
import { fetchDetailVoucher } from '../../../endpoints/endpoints';
import Loader from '../../Loader/Loader';
import close from '../../../svgs/e-close.svg';

const DetailPreview: React.FC<{
    items?: PartitionPartItem[];
    parteID: number;
    percentage?: number;
    voucherID?: number;
    setShowModal: (arg: boolean) => void;
}> = ({ items, parteID, percentage, setShowModal, voucherID }) => {
    const useStyles = makeStyles({
        table: {
            minWidth: 10,
            border: '1px solid #E0E0E0',
        },
    });
    const classes = useStyles();

    const createData = (
        id: number,
        monto: number,
        data: string,
        divisa: Divisa
    ) => {
        return { id, monto, data, divisa };
    };
    const voucherDetail = useQuery(['voucher_detail', voucherID], () =>
        fetchDetailVoucher.fetch(voucherID!)
    );
    const rows =
        items &&
        items?.map((item) => {
            return createData(
                item.id,
                (item as any)[item.monto_to_show].monto,
                item.data,
                (item as any)[item.monto_to_show].divisa
            );
        });

    return (
        <>
            <div className='bg-white flex flex-col w-full px-6 text-left'>
                <div className='gray600 border-b flex justify-between items-center border-gray200 mb-7 pb-4'>
                    <p className='text-xl font-bold text-gray700'>
                        Detalle {percentage && `(${percentage!.toFixed(0)}%)`}
                    </p>

                    <div
                        onClick={() => setShowModal(false)}
                        className='bg-gray200 hover:bg-gray250 transition-colors duration-100 w-11 h-11 flex justify-center items-center rounded cursor-pointer'
                    >
                        <img src={close} alt='' />
                    </div>
                </div>
                <div>
                    <TableContainer>
                        <TableContainer>
                            <Table
                                className={classes.table}
                                size='small'
                                aria-label='spaning table'
                            >
                                <TableHead>
                                    <TableRow>
                                        <StyledTableCell align='left'>
                                            ID
                                        </StyledTableCell>
                                        <StyledTableCell align='left'>
                                            Monto
                                        </StyledTableCell>
                                        <StyledTableCell align='left' />

                                        <StyledTableCell align='left' />

                                        <StyledTableCell align='left'>
                                            Tipo{' '}
                                        </StyledTableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {!items && voucherDetail.isLoading && (
                                        <>
                                            {' '}
                                            <StyledTableCell align='left' />{' '}
                                            <StyledTableCell align='left' />{' '}
                                            <Loader />
                                        </>
                                    )}
                                    {!items &&
                                        (voucherDetail.data as any)?.partes
                                            .find((x: any) => x.id === parteID)
                                            .partition_part.items?.map(
                                                (row: any) => {
                                                    return (
                                                        <TableRow key={row.id}>
                                                            <StyledTableCell
                                                                align='left'
                                                                className='cursor-'
                                                            >
                                                                {row.id}
                                                            </StyledTableCell>
                                                            <StyledTableCell
                                                                align='left'
                                                                className='cursor-'
                                                            >
                                                                {formatCurrency(
                                                                    new Decimal(
                                                                        row[
                                                                            row.monto_to_show
                                                                        ].monto
                                                                    ),
                                                                    row[
                                                                        row
                                                                            .monto_to_show
                                                                    ].divisa
                                                                )}{' '}
                                                            </StyledTableCell>
                                                            <StyledTableCell align='left' />

                                                            <StyledTableCell align='left' />
                                                            <StyledTableCell
                                                                align='left'
                                                                className='cursor-'
                                                            >
                                                                {row.data}
                                                            </StyledTableCell>
                                                        </TableRow>
                                                    );
                                                }
                                            )}
                                    {!!items &&
                                        rows?.map((row: any) => {
                                            return (
                                                <TableRow key={row.id}>
                                                    <StyledTableCell
                                                        align='left'
                                                        className='cursor-'
                                                    >
                                                        {row.id}
                                                    </StyledTableCell>
                                                    <StyledTableCell
                                                        align='left'
                                                        className='cursor-pointer'
                                                    >
                                                        {formatCurrency(
                                                            new Decimal(
                                                                row.monto
                                                            ),
                                                            row.divisa
                                                        )}{' '}
                                                    </StyledTableCell>
                                                    <StyledTableCell align='left' />

                                                    <StyledTableCell align='left' />
                                                    <StyledTableCell
                                                        align='left'
                                                        className='cursor-pointer'
                                                    >
                                                        {row.data}
                                                    </StyledTableCell>
                                                </TableRow>
                                            );
                                        })}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </TableContainer>
                </div>
            </div>

            <div className='flex justify-center md:justify-end mt-8 pr-5'>
                <div className='w-52'>
                    <ActionButton onClick={() => setShowModal(false)}>
                        Cerrar
                    </ActionButton>
                </div>
            </div>
        </>
    );
};

export default DetailPreview;
