import Navbar from "../components/Navbar/Navbar";
import { Link } from "react-router-dom";

const NotFound = () => {

  return (
    <>
      <Navbar />
      <section className="flex  items-center justify-center">
        <div className="rounded mt-16 border-1-3 w-2/5 border-gray-300 px-6 flex justify-center items-center py-2 h-72 mb-5 bg-white">
          <div>
            <div className="flex justify-center mb-6">
              <h1 className='font-bold text-7xl'>404</h1>
            </div>
            <div className="flex justify-center">
              <p className="justify-center">
                La página que estás tratando de acceder no existe.
              </p>
            </div>
            <div className="flex justify-center mt-16 w-full">
              <Link to='/' className="bg-red400 border flex items-center justify-center text-white focus:ring-0 outline-none border-red400 hover:bg-red600 hover:border-red600 w-3/4 h-11 rounded">
               <div>Ir al inicio</div> 
              </Link>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default NotFound;
