import { useAtom } from 'jotai';
import React from 'react';
import { useParams } from 'react-router-dom';
import { deleteReciboAtom } from '../../store/Atoms';
import { Divisa, Recibo } from '../../types/types';
import { formatCurrency } from '../../utils/utils';
import PayRemove from './PayRemove';

interface Props {
    deleteIcon?: boolean;
    recibo: Recibo;
    divisa: Divisa;
    cuota: number;
    cuotas: number;
}
const PayItemDetail: React.FC<Props> = ({ recibo, divisa, cuota, cuotas }) => {
    const [, deleteRecibo] = useAtom(deleteReciboAtom);
    const { paso } = useParams() as { paso: string };

    return (
        <div className='flex pb-2 justify-between items-center'>
            <div>
                <small className='text-gray700 font-bold'>
                    Cuota {cuota + 1}/{cuotas}
                </small>
                <small className='block gray400'>
                    Recibo {recibo.external_id}
                </small>
            </div>

            {paso === 'seguros' ? (
                <PayRemove
                    amount={recibo.monto}
                    divisa={divisa}
                    id={recibo.id}
                    deleteItem={deleteRecibo}
                    disabled={cuota !== cuotas - 1}
                />
            ) : (
                <small>{formatCurrency(recibo.monto, divisa)}</small>
            )}
        </div>
    );
};

PayItemDetail.defaultProps = {
    deleteIcon: true,
};
export default PayItemDetail;
