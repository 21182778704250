import React from 'react';
import InvoiceInfo from './InvoiceInfo';
import { FacturaPreview } from '../../types/types';
import InvoiceHeader from './InvoiceHeader';
import InvoiceAmmounts from './InvoiceAmmounts';
import InvoiceDetails from './InvoiceDetails';

interface PreviewProps {
    factura: FacturaPreview;
}

const InvoicePreview: React.FC<PreviewProps> = ({ factura }) => {
    return (
        <div className='bg-white flex flex-col w-full py-5 px-6 text-left'>
            <div className='border-b border-gray'>
                <InvoiceHeader />
                <div className='w-32 md:w-40 pt-2'>
                    <img
                        src='../../../../images/logo-color-transparent.png'
                        alt='logo'
                    />
                </div>
                {factura.pago_data.payer_setting && (
                    <InvoiceInfo factura={factura} />
                )}
                <InvoiceDetails factura={factura} />
                <InvoiceAmmounts items={factura.items} />
            </div>
        </div>
    );
};

export default InvoicePreview;
