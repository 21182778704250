import React from 'react';
import { QueryObserverResult, RefetchOptions } from 'react-query';
import NewAccountForm from '../../forms/NewAccountForm';
import close from '../../../svgs/e-close.svg';

const NewAccount: React.FC<{
    setShowNewAccount: (arg: boolean) => void;
    refetch: (options?: RefetchOptions | undefined) => Promise<
        QueryObserverResult<
            {
                id: number;
                banco: string;
                tipo: string;
                rut: string;
                n_cuenta: string;
                email: string;
            }[],
            unknown
        >
    >;
}> = ({ setShowNewAccount, refetch }) => {
    return (
        <>
            <div className='bg-white flex flex-col w-full px-6 text-left'>
                <div className='gray600 border-b flex justify-between items-center border-gray200 mb-6 pb-4'>
                    <p className='text-xl font-bold text-gray700'>
                        Nueva cuenta bancaria
                    </p>

                    <div
                        onClick={() => setShowNewAccount(false)}
                        className='bg-gray200 hover:bg-gray250 w-11 h-11 flex justify-center items-center rounded cursor-pointer'
                    >
                        <img src={close} alt='close' />
                    </div>
                </div>
                <p className='text-tiny text-gray700 mb-4'>
                    Ingrese los datos para crear una nueva cuenta bancaria
                </p>
                <div className='flex justify-center'>
                    <div className='w-full'>
                        <NewAccountForm
                            setShowNewAccount={setShowNewAccount}
                            refetch={refetch}
                        />
                    </div>
                </div>
            </div>
        </>
    );
};

export default NewAccount;
