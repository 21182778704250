import { useState } from 'react';

function useBoolean(initialValue: boolean) {
    const [value, setValue] = useState(initialValue);

    function toggle(): void {
        setValue((o) => !o);
    }

    return {
        value,
        setValue,
        toggle,
    };
}

export { useBoolean };
