import React, { useState } from 'react';
import circleDown from '../../../svgs/circle-ctrl-down.svg';
import add from '../../../svgs/e-add.svg';
import BankAccount from './BankAccount';
import Decimal from 'decimal.js';
import { ListClientBankAccount, PartitionPart } from '../../../types/types';
import { useAtom } from 'jotai';
import { voucherInfoAtom } from '../../../store/Atoms';
import Loader from '../../Loader/Loader';
import { useQuery } from 'react-query';
import {
    apiURL,
    authStorage,
    cuentasPersona,
    direccionesPersona,
} from '../../../endpoints/endpoints';
import CustomModal from '../../CustomModal/CustomModal';
import DetailPreview from './DetailPreview';
import AlertInfo from '../../Alert/AlertInfo';
import NewAccount from './NewAccount';
import { formatCurrency } from '../../../utils/utils';
import cInfo from '../../../svgs/c-info.svg';
import ReactTooltip from 'react-tooltip';

interface DetailProps {
    part: PartitionPart;
    percentage: number;
    client_id: number;
}

const PartsDetail: React.FC<DetailProps> = ({
    part: { id, cod_cia, monto_to_show, items },
    percentage,
    client_id,
    part,
}) => {
    const [show, setShow] = useState(false);
    const [voucherInfo, setVoucherInfo] = useAtom(voucherInfoAtom);
    const [cuentasMapfre, setCuentasMapfre] = useState<ListClientBankAccount[]>(
        []
    );
    const [fetchCLPAccounts, setFetchCLPAccounts] = useState(false);
    const handleChange = (value: any, tipo: string) => {
        const changedPart = voucherInfo.partes.find(
            parte => parte.partition_part_id === id
        );

        setVoucherInfo({
            partes: [
                // @ts-ignore
                ...voucherInfo.partes.filter(
                    parte =>
                        parte.partition_part_id !==
                        changedPart?.partition_part_id
                ),
                // @ts-ignore
                Object.assign(changedPart, {
                    [tipo]: value,
                }),
            ],
            factura_mode: voucherInfo.factura_mode,
        });
    };

    const [showModal, setShowModal] = useState(false);
    const [showNewAccount, setShowNewAccount] = useState(false);
    const changedPart = voucherInfo.partes?.find(
        parte => parte.partition_part_id === id
    );

    const getMapfreAccounts = (divisa: string) => {
        return fetch(
            `${apiURL}factura/cuentas-mapfre?client_id=${client_id}&cod_cia=${cod_cia}&divisa=${divisa}`,
            {
                method: 'GET',
                headers: {
                    Authorization: `Bearer ${authStorage.get()?.token}`,
                },
            }
        ).then(response => response.json());
    };

    const queryCuentasMapfre = useQuery(
        ['cuentasMapfre', cod_cia, (part as any)[monto_to_show].divisa],
        () => getMapfreAccounts((part as any)[monto_to_show].divisa),
        {
            onSuccess: data => {
                if (changedPart?.cuenta_target === 0 && data.length) {
                    handleChange(data[0].id, 'cuenta_target');
                }
                setCuentasMapfre(data);
                if ((part as any)[monto_to_show].divisa === 'UF') {
                    setFetchCLPAccounts(true);
                }
            },
        }
    );

    const queryCuentasMapfreCLP = useQuery(
        ['cuentasMapfre', 'clp', cod_cia],
        () => getMapfreAccounts('CLP'),
        {
            onSuccess: data => {
                if ((part as any)[monto_to_show].divisa === 'UF') {
                    setCuentasMapfre(prev => [...prev, ...data]);
                }
                if (changedPart?.cuenta_target === 0 && data.length) {
                    handleChange(data[0].id, 'cuenta_target');
                }
            },
            enabled: fetchCLPAccounts,
        }
    );
    const queryDirecciones = useQuery(
        [direccionesPersona.url, id],
        () => direccionesPersona.fetch(`/${client_id}/${cod_cia}`),
        {
            onSuccess: data => {
                if (changedPart?.direccion === 0 && data.length) {
                    handleChange(data[0].id, 'direccion');
                }
            },
        }
    );

    const queryCuentasOrigen = useQuery(
        [cuentasPersona.url],
        () => cuentasPersona.fetch(client_id),
        {
            onSuccess: data => {
                if (!changedPart?.cuenta_source && data.length) {
                    handleChange(data[0].id, 'cuenta_source');
                }
            },
        }
    );

    const [isHover, setIsHover] = useState(false);
    return (
        <div
            className={'w-full mb-5'}
            onMouseEnter={() => {
                setIsHover(true);
            }}
            onMouseLeave={() => setIsHover(false)}
        >
            <div className='rounded-lg border-1-3 bg-white'>
                <div
                    className='flex p-4 px-6 justify-between items-center cursor-pointer font-bold'
                    onClick={() => (show ? setShow(false) : setShow(true))}
                >
                    <div>
                        {part.items?.map(x => (
                            <p className='font-bold'>
                                Recibo {x.document.external_id}
                            </p>
                        ))}
                    </div>
                    <div className='flex items-center'>
                        {!show && (
                            <>
                                <div
                                    className='text-right'
                                    data-tip
                                    data-for={'info'}
                                >
                                    <ReactTooltip
                                        id='info'
                                        place='top'
                                        className='text-xs px-1 p-0 mb-4 font-light'
                                    >
                                        El valor a pagar puede ser mayor debido
                                        a los impuestos aplicados.
                                    </ReactTooltip>
                                    <p className='font-normal mb-1'>
                                        Valor a pagar
                                    </p>

                                    <div className='flex items-center'>
                                        <img
                                            src={cInfo}
                                            alt='info'
                                            className='w-4 mr-1'
                                        />
                                        {formatCurrency(
                                            new Decimal(
                                                (part as any)[
                                                    monto_to_show
                                                ].monto
                                            ),
                                            (part as any)[monto_to_show].divisa
                                        )}
                                    </div>
                                </div>
                                <div className='mx-14'>
                                    <p className='font-normal mb-1'>
                                        equivalente al
                                    </p>

                                    <div>{`${
                                        percentage !== 100
                                            ? percentage.toFixed(2)
                                            : percentage
                                    }%`}</div>
                                </div>
                            </>
                        )}
                        <div
                            className={`font-normal text-sm bg-gray200 flex items-center justify-center rounded-full h-10 min-w-9 px-3 ${
                                isHover && 'bg-gray250'
                            }`}
                        >
                            Completar información
                            <img
                                className={`${
                                    show && 'rotate-180'
                                } w-3 ml-2`}
                                src={circleDown}
                                alt='complete'
                            />
                        </div>
                    </div>
                </div>
                <div className={`${!show ? 'hidden' : ' border-t-1-5'}`}>
                    <div className='p-6'>
                        <p
                            className='text-tiny text-blue700'
                            onClick={() => console.log(part)}
                        >
                            Monto a pagar
                        </p>
                        <div className='mt-3 md:flex justify-between'>
                            <div className='font-bold flex items-end text-lg'>
                                <div>
                                    {formatCurrency(
                                        new Decimal(
                                            (part as any)[monto_to_show].monto
                                        ),
                                        (part as any)[monto_to_show].divisa
                                    )}
                                    <span className='h-full border-r border-gray300 mx-3'></span>
                                    {`${
                                        percentage !== 100
                                            ? percentage.toFixed(2)
                                            : percentage
                                    }%`}
                                </div>
                                <p
                                    className='text-xs gray400 font-light ml-4 underline cursor-pointer'
                                    onClick={() => setShowModal(true)}
                                >
                                    Ver más detalle
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className='p-6 pt-1'>
                        <div className='flex justify-between mb-3 items-center cursor-pointer'>
                            <p>
                                Selecciona una cuenta{' '}
                                <strong>bancaria de origen</strong>
                            </p>
                        </div>
                        {queryCuentasOrigen.isLoading ? (
                            <div className='my-2 flex justify-center'>
                                <Loader />
                            </div>
                        ) : queryCuentasOrigen.error ? (
                            <AlertInfo
                                errorMessage={`${queryCuentasOrigen.error}`}
                                reload
                            />
                        ) : (
                            queryCuentasOrigen.data &&
                            queryCuentasOrigen.data?.length > 0 &&
                            queryCuentasOrigen.data.map(x => {
                                return (
                                    <BankAccount
                                        id={x.id}
                                        banco={x.banco}
                                        tipo={x.tipo}
                                        rut={x.rut}
                                        selected={
                                            voucherInfo.partes?.find(
                                                x =>
                                                    x.partition_part_id ===
                                                    part.id
                                            )?.cuenta_source!
                                        }
                                        onChange={(value: any) => {
                                            handleChange(
                                                value,
                                                'cuenta_source'
                                            );
                                            if (value === undefined) {
                                                queryCuentasOrigen.refetch();
                                            }
                                        }}
                                    />
                                );
                            })
                        )}
                        <div
                            className='rounded cursor-pointer h-20 bg-gray100 flex px-8 items-center py-10 border border-dashed border-gray300'
                            onClick={() => setShowNewAccount(true)}
                        >
                            <div className='flex items-center justify-between cursor-pointer w-full font-bold'>
                                <p className='underline mr-2'>
                                    Añadir cuenta bancaria
                                </p>
                                <img src={add} alt='add' />
                            </div>
                        </div>
                        <p className='mt-8 mb-4'>
                            Selecciona una cuenta{' '}
                            <strong>bancaria de destino</strong>
                        </p>
                        {queryCuentasMapfre.isLoading ||
                            (queryCuentasMapfreCLP.isLoading && (
                                <div className='my-2 flex justify-center'>
                                    <Loader />
                                </div>
                            ))}
                        {queryCuentasMapfre?.error && (
                            <AlertInfo
                                errorMessage={`${queryCuentasMapfre?.error}`}
                                reload
                            />
                        )}
                        {queryCuentasMapfre.data &&
                            Array.isArray(queryCuentasMapfre.data) &&
                            queryCuentasMapfre.data.map((x: any) => {
                                return (
                                    <BankAccount
                                        id={x.id}
                                        banco={x.banco}
                                        tipo={x.tipo}
                                        rut={x.rut}
                                        cod_cia={x.cod_cia}
                                        selected={
                                            voucherInfo.partes.find(
                                                x =>
                                                    x.partition_part_id ===
                                                    part.id
                                            )?.cuenta_target!
                                        }
                                        onChange={(value: number) => {
                                            handleChange(
                                                value,
                                                'cuenta_target'
                                            );
                                        }}
                                    />
                                );
                            })}
                        <p className='mt-8 mb-4'>Dirección de facturación</p>
                        {queryDirecciones.isLoading ? (
                            <div className='my-2 flex justify-center'>
                                <Loader />
                            </div>
                        ) : queryDirecciones.error ? (
                            <AlertInfo
                                errorMessage={`${queryDirecciones.error}`}
                                reload
                            />
                        ) : (
                            queryDirecciones.isSuccess &&
                            queryDirecciones.data.map(x => {
                                return (
                                    <BankAccount
                                        id={x.id}
                                        direccion={x.direccion}
                                        selected={
                                            voucherInfo.partes.find(
                                                x =>
                                                    x.partition_part_id ===
                                                    part.id
                                            )?.direccion!
                                        }
                                        cod_cia={x.nombre}
                                        onChange={(value: number) => {
                                            handleChange(value, 'direccion');
                                        }}
                                    />
                                );
                            })
                        )}
                    </div>
                </div>
            </div>
            <CustomModal isOpen={showModal} setIsOpen={setShowModal} shadow>
                <DetailPreview
                    parteID={id}
                    percentage={percentage}
                    setShowModal={setShowModal}
                    items={items}
                />
            </CustomModal>
            <CustomModal
                isOpen={showNewAccount}
                setIsOpen={setShowNewAccount}
                shadow
            >
                <NewAccount
                    setShowNewAccount={setShowNewAccount}
                    refetch={queryCuentasOrigen.refetch}
                />
            </CustomModal>
        </div>
    );
};

export default PartsDetail;
