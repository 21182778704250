import Screen from "../components/Screen/Screen";
import React, {useState} from "react";
import {useRol} from "../customHooks/customHooks";
import {$Button, $Form, spanish, useForm, XFormContext} from "@tdc-cl/x-form";
import {$Rut} from "../customFields/$Rut";
import AlertInfo from "../components/Alert/AlertInfo";
import {RecaptchaField} from "../components/forms/RecaptchaField";
import ActionButton from "../components/Buttons/ActionButton";
import {useMutation} from "react-query";
import {resetPasswordRequest} from "../endpoints/endpoints";


const Form = () => {
    const rol = useRol();
    const [request, setRequest] = useState(false)
    const renderCond = ['contabilidad', 'cobrador'].includes(rol)


    const mutation = useMutation(resetPasswordRequest.fetch, {
        onSettled: data => {
            setRequest(true)
        },
    });

    const [captchaValidation, setCaptchaValidation] =
        useState<string | null>(null);

    const handleCaptcha = (value: string) => setCaptchaValidation(value);

    const RutForm = useForm(
        $Form({
            fields: {
                rut: $Rut('Ingresa tu RUT'),
            },
            submit: $Button('INGRESAR', {
                async onValid(values) {
                    mutation.mutate(
                        {
                            ...values,
                            recaptcha: captchaValidation!,
                        }
                    )
                },
            }),
        })
    );

    return (renderCond ?
            <XFormContext.Provider value={{locale: spanish}}>
                <div className='customized-form'>
                    {(mutation.isError) && (
                        <div className='pb-2'>
                            <AlertInfo>
                                <p className='font-bold'>Solicitud Realizada por favor revisa tu correo</p>
                            </AlertInfo>
                        </div>

                    )}
                    <div>
                        {RutForm.renderFields()}
                        <div className='mx-auto mt-5'>
                            <RecaptchaField
                                onChange={(value: string | null) =>
                                    handleCaptcha(value!)
                                }
                            />
                        </div>
                        <div className='mt-5'>
                            <ActionButton
                                classname={'text-tiny'}
                                isLoading={mutation.isLoading}
                                disabled={
                                    !captchaValidation || !RutForm.isValid
                                }
                                onClick={() => {
                                    RutForm.submit();
                                }}
                            >
                                Continuar
                            </ActionButton>
                        </div>
                    </div>

                </div>
            </XFormContext.Provider> : <></>
    )
}

export const ResetPwdRequest = () => {
    return (
        <Screen public>
            <div className='flex flex-col justify-center items-center mt-10'>

                <div
                    className='bg-white border-1-5 border-gray-300 rounded shadow-lg w-4/5 md:w-2/6 flex flex-col justify-center items-center my-8 py-20'>
                    <div className='mb-5 w-4/12'>
                        <img
                            src='../images/logo-color-transparent.png'
                            alt='logo'
                        />
                    </div>
                    <h3 className='font-bold mb-8 text-4xl text-gray700'>
                        Portal de Pagos
                    </h3>
                    <div className='w-8/12'>
                        <Form></Form>
                    </div>
                </div>
                <small className='gray400 mb-16'>
                    MAPFRE &#169; 2021 Todos los derechos reservados
                </small>
            </div>
        </Screen>
    );
}