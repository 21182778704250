import classNames from 'classnames';
import React, { useState } from 'react';
import generalInsurance from '../../svgs/general_insurance.svg';
import lifeInsurance from '../../svgs/life_insurance.svg';
import generalInsuranceRed from '../../svgs/general_insuranc_red.svg';
import lifeInsuranceRed from '../../svgs/life_insurance_red.svg';
import TypeOfInsurance from './TypeOfInsurance';

interface InsuranceTypeProps {
    activeType: string;
    setActiveType: (text: string) => void;
    generalLength: number;
    vidaLength: number;
}

const InsuranceType: React.FC<InsuranceTypeProps> = ({
    activeType,
    setActiveType,
    generalLength,
    vidaLength,
}) => {
    return (
        <>
            <TypeOfInsurance
                activeType={activeType}
                type={'Seguro General'}
                icon={generalInsurance}
                activeIcon={generalInsuranceRed}
                length={generalLength}
                setActiveType={setActiveType}
                title={'GENERALES'}
            />
            <TypeOfInsurance
                activeType={activeType}
                type={'Seguro de Vida'}
                icon={lifeInsurance}
                activeIcon={lifeInsuranceRed}
                length={vidaLength}
                setActiveType={setActiveType}
                title={'VIDA'}
            />
        </>
    );
};

export default InsuranceType;
