import React from 'react';

const LogoNavbar = () => {
  return (
    <div className='flex items-center text-white w-1/3 lg:w-5/6 lg:ml-5'>
      <img
        src='../../../images/logo-color-transparent.png'
        alt='logo'
        className='w-11/12 lg:w-36 flex items-center shrink-0 text-white mr-6'
      />
    </div>
  );
};

export default LogoNavbar;
