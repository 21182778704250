import Screen from "../components/Screen/Screen";
import React, {useState} from "react";
import {$Button, $Form, $Password, CustomField, Invalid, spanish, useForm, Valid, XFormContext} from "@tdc-cl/x-form";
import AlertInfo from "../components/Alert/AlertInfo";
import {RecaptchaField} from "../components/forms/RecaptchaField";
import ActionButton from "../components/Buttons/ActionButton";
import {useMutation} from "react-query";
import {resetPassword} from "../endpoints/endpoints";
import {useLocation} from "react-router-dom";

const useQuery = () => new URLSearchParams(useLocation().search);

const Form = () => {
    const [request, setRequest] = useState(false)
    const query = useQuery();
    const token = query.get('token') ?? ''

    const mutation = useMutation(resetPassword.fetch, {
        onSettled: data => {
            setRequest(true)
        },
    });

    const [captchaValidation, setCaptchaValidation] =
        useState<string | null>(null);

    const handleCaptcha = (value: string) => setCaptchaValidation(value);

    const $RepeatPassword = CustomField.extends($Password).with({
        label: 'Repeat password',

        validate(value) {
            const { password } = PwdForm.fields;

            if (!password.is(value)) {
                return Invalid('Contraseñas no son iguales');
            }

            return Valid(value);
        },
    });

    const PwdForm = useForm(
        $Form({
            fields: {
                password: $Password('Ingresa nueva contraseña'),
                password2: $RepeatPassword('Repita nueva contraseña'),
            },
            submit: $Button('INGRESAR', {
                async onValid(values) {
                    mutation.mutate(
                        {
                            password: values.password,
                            recaptcha: captchaValidation!,
                            token: token
                        }
                    )
                },
            }),
        })
    );

    return (
            <XFormContext.Provider value={{locale: spanish}}>
                <div className='customized-form'>
                    {(mutation.isError) && (
                        <div className='pb-2'>
                            <AlertInfo>
                                <p className='font-bold'>Token experado o invalido</p>
                            </AlertInfo>
                        </div>
                    )}
                    {(request) && (
                        <div className='pb-2'>
                            <AlertInfo className='text-base bg-gray-200'>
                                <p className='font-bold'>Cambio de contraseña exitoso</p>
                            </AlertInfo>
                        </div>

                    )}
                    <div>
                        {PwdForm.renderFields()}
                        <div className='mx-auto mt-5'>
                            <RecaptchaField
                                onChange={(value: string | null) =>
                                    handleCaptcha(value!)
                                }
                            />
                        </div>
                        <div className='mt-5'>
                            <ActionButton
                                classname={'text-tiny'}
                                isLoading={mutation.isLoading}
                                disabled={
                                    !captchaValidation || !PwdForm.isValid
                                }
                                onClick={() => {
                                    PwdForm.submit();
                                }}
                            >
                                Continuar
                            </ActionButton>
                        </div>
                    </div>

                </div>
            </XFormContext.Provider>
    )
}

export const ResetPwd = () => {
    return (
        <Screen public>
            <div className='flex flex-col justify-center items-center mt-10'>

                <div
                    className='bg-white border-1-5 border-gray-300 rounded shadow-lg w-4/5 md:w-2/6 flex flex-col justify-center items-center my-8 py-20'>
                    <div className='mb-5 w-4/12'>
                        <img
                            src='../images/logo-color-transparent.png'
                            alt='logo'
                        />
                    </div>
                    <h3 className='font-bold mb-8 text-4xl text-gray700'>
                        Portal de Pagos
                    </h3>
                    <div className='w-8/12'>
                        <Form></Form>
                    </div>
                </div>
                <small className='gray400 mb-16'>
                    MAPFRE &#169; 2021 Todos los derechos reservados
                </small>
            </div>
        </Screen>
    );
}