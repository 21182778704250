import React from 'react';

interface AlertInfoProps {
    className?: string;
    reload?: boolean;
    errorMessage?: string;
}

const AlertInfo: React.FC<AlertInfoProps> = ({
    className,
    errorMessage,
    reload,
    children,
}) => {
    return (
        <div
            className={`text-black p-4 border-0 rounded relative mb-4 shadow-sm bg-red-100 font-semibold ${
                className ?? ''
            }`}
        >
            <span className='inline-block align-middle'>
                {errorMessage ? errorMessage : children}
            </span>
            {reload && (
                <span
                    className='underline cursor-pointer md:mt-3 ml-7'
                    onClick={() => window.location.reload()}
                >
                    Intenta cargar nuevamente
                </span>
            )}
        </div>
    );
};

AlertInfo.defaultProps = {
    reload: false,
};

export default AlertInfo;
