import React from "react";
import ActionButton from "../Buttons/ActionButton";
import check from "../../svgs/c-check.svg";
import { useAtom } from "jotai";
import { refetchTableAtom } from "../../store/Atoms";

interface FacturaConfirmadaProps {
  setFacturaConfirmada: (arg: boolean) => void;
  setShowFacturar: (arg: boolean) => void;
  parteID: number;
}

const FacturaConfirmada: React.FC<FacturaConfirmadaProps> = ({
  setFacturaConfirmada,
  setShowFacturar,
  parteID,
}) => {
  const [, setRefetchTable] = useAtom(refetchTableAtom);

  return (
    <>
      <div className=' border-b border-gray200 '>
        <p className="text-2xl mb-4 ">Facturación Voucher #{parteID}</p>
      </div>
      <div className="gray600 p-10 flex justify-center flex-col items-center">
        <img src={check} alt="check logo" className="inline-block w-24" />
        <h3 className="mt-4 mb-1">Factura Confirmada</h3>
        <small>La factura llegará a su correo en los próximos minutos</small>
        <div className="mx-auto w-48 mt-8">
          <ActionButton
            onClick={() => {
              setRefetchTable(parteID);
              setFacturaConfirmada(false);
              setShowFacturar(false);
            }}
          >
            OK, ENTENDIDO{" "}
          </ActionButton>
        </div>
      </div>
    </>
  );
};

export default FacturaConfirmada;
