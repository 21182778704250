import React, { Dispatch, useEffect, useRef, useState } from "react";
import _ from "lodash";
import {
  useForm,
  $Form,
  $Button,
  $Text,
  Valid,
  Invalid,
  CustomField,
  $Decimal,
} from "@tdc-cl/x-form";
import { PayerSettings } from "../../types/types";
import { useMutation, useQuery } from "react-query";
import { facturaReference, generarSettings } from "../../endpoints/endpoints";
import { useAtom } from "jotai";
import {
  clienteCorredorID,
  porcentajeTotalAtom,
  filialSettingAtom,
  nonFilialSettingAtom,
  nonFilialesAtom,
} from "../../store/Atoms";
import Decimal from "decimal.js";
import { $ArrayEmails } from "../../customFields/$ArrayEmails";
import AlertInfo from "../Alert/AlertInfo";
import { formatCurrency } from "../../utils/utils";
import ActionButton from "../Buttons/ActionButton";
import remove from "../../svgs/e-remove.svg";
import circleDown from "../../svgs/circle-ctrl-down.svg";
import { useBoolean } from "../../customHooks/useBoolean";
import { useClickOutside } from "../../customHooks/useClickOutside";
import check from "../../svgs/check-mark.svg";
import FieldWithExtraFields from "./ExtraFields";
import { isLabeledStatement } from "typescript";
import ReactTooltip from "react-tooltip";

interface InvoiceProps {
  prefill: boolean;
  filial: PayerSettings;
  setFiliales: (arg: PayerSettings[]) => void;
  filiales: PayerSettings[];
  setEditar: (arg: boolean) => void;
  optionsSelected: {
    code: string;
    text?: string | null;
    folio: string;
    date?: string | null;
  }[];
  setOptionsSelected: Dispatch<
    React.SetStateAction<
      {
        code: string;
        text?: string | null;
        folio: string;
        date?: string | null;
      }[]
    >
  >;
  nonFilial?: boolean;
  monto: Decimal;
}
const InvoiceForm: React.FC<InvoiceProps> = ({
  prefill,
  filial,
  filiales,
  setEditar,
  optionsSelected,
  setOptionsSelected,
  nonFilial,
  monto,
}) => {
  const [clientID] = useAtom(clienteCorredorID);
  const [porcentajeTotal, setPorcentajeTotal] = useAtom(porcentajeTotalAtom);
  const [filialSetting, setFilialSetting] = useAtom(filialSettingAtom);
  const [, setNonFilialSetting] = useAtom(nonFilialSettingAtom);
  const [, setNonFiliales] = useAtom(nonFilialesAtom);
  const [newPorcentaje, setNewPorcentaje] = useState(
    new Decimal(filial.porcentaje)
  );
  const [extraFieldsValues, setExtraFieldsValues] = useState<
    { code: string; date?: string | null; text?: string | null }[]
  >([]);
  const generateSetting = useMutation(generarSettings.fetch, {
    onSuccess: (data) => {
      if (nonFilial) {
        setNonFilialSetting({
          empresa: filial.empresa.id,
          setting: data.id,
        });
        setEditar(false);
      } else {
        if (!_.find(filialSetting, { empresa: filial.empresa.id })) {
          setFilialSetting([
            ...filialSetting,
            { empresa: filial.empresa.id, setting: data.id },
          ]);
        } else {
          const replaceID = filialSetting.findIndex(
            (filialSetting) => filialSetting.empresa === filial.empresa.id
          );
          filialSetting[replaceID] = {
            empresa: filial.empresa.id,
            setting: data.id,
          };
        }
        setEditar(false);
        setPorcentajeTotal(porcentajeTotal.add(newPorcentaje));
      }
    },
  });
  const calcPercentage = (total: Decimal, percentage: Decimal) => {
    const per = percentage.dividedBy(new Decimal(100));
    return total.times(new Decimal(per));
  };
  const queryReferences = useQuery(
    [facturaReference.url],
    facturaReference.fetch
  );
  const $PorcentajeFilial = CustomField.extends($Decimal).with({
    validate(value) {
      if (
        /* porcentajeTotal.add(value).greaterThan(new Decimal(100)) */ value.greaterThan(
          new Decimal(100)
        )
      ) {
        return Invalid("No puedes asignar más del 100% del monto total");
      }
      return Valid(value);
    },
    inputProps: {
      onChange: (e: any) => {
        const value =
          e.target.value !== "" ? new Decimal(e.target.value) : new Decimal(0);
        setNewPorcentaje(value);
      },
      type: "number",
    },
  });

  const getReferenceId = (value: string) => {
    const references = queryReferences.data;
    if (references) {
      const reference = references.find((x) => x.code === value.toUpperCase());
      return reference?.id ?? 0;
    } else {
      return 0;
    }
  };

  const form = useForm(
    $Form({
      fields: {
        porcentaje: $PorcentajeFilial("").with({
          inputProps: {
            className: `border rounded-r-none h-12 mt-0 ${
              nonFilial ? "bg-gray200 border-gray-400" : "border-red400"
            }`,
            disabled: nonFilial,
          },
        }),
        "801": $Text("OC")
          .showIf(
            () => _.findIndex(optionsSelected, (o) => o.code === "801") !== -1
          )
          .with({
            render: {
              Label() {
                return null;
              },
            },
            inputProps: {
              placeholder: "Ingresar OC",
            },
          }),
        cec: $Text("CEC")
          .showIf(
            () => _.findIndex(optionsSelected, (o) => o.code === "CEC") !== -1
          )
          .with({
            render: {
              Label() {
                return null;
              },
            },
            inputProps: {
              placeholder: "Ingresar CEC",
            },
          }),
        hes: $Text("HES")
          .showIf(
            () => _.findIndex(optionsSelected, (o) => o.code === "HES") !== -1
          )
          .with({
            render: {
              Label() {
                return null;
              },
            },
            inputProps: {
              placeholder: "Ingresar HES",
            },
          }),
        cta: $Text("CTA")
          .showIf(
            () => _.findIndex(optionsSelected, (o) => o.code === "CTA") !== -1
          )
          .with({
            render: {
              Label() {
                return null;
              },
            },
            inputProps: {
              placeholder: "Ingresar CTA",
            },
          }),
        ate: $Text("ATE")
          .showIf(
            () => _.findIndex(optionsSelected, (o) => o.code === "ATE") !== -1
          )
          .with({
            render: {
              Label() {
                return null;
              },
            },
            inputProps: {
              placeholder: "Ingresar ATE",
            },
          }),
        "802": $Text("NOTA DE PEDIDO")
          .showIf(
            () => _.findIndex(optionsSelected, (o) => o.code === "802") !== -1
          )
          .with({
            render: {
              Label() {
                return null;
              },
            },
            inputProps: {
              placeholder: "Ingresar NOTA DE PEDIDO",
            },
          }),
        "803": $Text("CONTRATO")
          .showIf(
            () => _.findIndex(optionsSelected, (o) => o.code === "803") !== -1
          )
          .with({
            render: {
              Label() {
                return null;
              },
            },
            inputProps: {
              placeholder: "Ingresar CONTRATO",
            },
          }),
        "804": $Text("RESOLUCIÓN")
          .showIf(
            () => _.findIndex(optionsSelected, (o) => o.code === "804") !== -1
          )
          .with({
            render: {
              Label() {
                return null;
              },
            },
            inputProps: {
              placeholder: "Ingresar RESOLUCIÓN",
            },
          }),
        "805": $Text("PROCESO CHILE COMPRA")
          .showIf(
            () => _.findIndex(optionsSelected, (o) => o.code === "805") !== -1
          )
          .with({
            render: {
              Label() {
                return null;
              },
            },
            inputProps: {
              placeholder: "Ingresar PROCESO CHILE COMPRA",
            },
          }),
        "806": $Text("FICHA CHILE COMPRA")
          .showIf(
            () => _.findIndex(optionsSelected, (o) => o.code === "806") !== -1
          )
          .with({
            render: {
              Label() {
                return null;
              },
            },
            inputProps: {
              placeholder: "Ingresar FICHA CHILE COMPRA",
            },
          }),
        "807": $Text("DUS")
          .showIf(
            () => _.findIndex(optionsSelected, (o) => o.code === "807") !== -1
          )
          .with({
            render: {
              Label() {
                return null;
              },
            },
            inputProps: {
              placeholder: "Ingresar DUS",
            },
          }),
        "808": $Text("CONOCIMIENTO DE EMBARQUE")
          .showIf(
            () => _.findIndex(optionsSelected, (o) => o.code === "808") !== -1
          )
          .with({
            render: {
              Label() {
                return null;
              },
            },
            inputProps: {
              placeholder: "Ingresar CONOCIMIENTO DE EMBARQUE",
            },
          }),
        "809": $Text("AWB (AIR WILL BILL)")
          .showIf(
            () => _.findIndex(optionsSelected, (o) => o.code === "809") !== -1
          )
          .with({
            render: {
              Label() {
                return null;
              },
            },
            inputProps: {
              placeholder: "Ingresar AWB (AIR WILL BILL)",
            },
          }),
        "810": $Text("MIC/DTA")
          .showIf(
            () => _.findIndex(optionsSelected, (o) => o.code === "810") !== -1
          )
          .with({
            render: {
              Label() {
                return null;
              },
            },
            inputProps: {
              placeholder: "Ingresar MIC/DTA",
            },
          }),
        "811": $Text("CARTA DE PORTE")
          .showIf(
            () => _.findIndex(optionsSelected, (o) => o.code === "811") !== -1
          )
          .with({
            render: {
              Label() {
                return null;
              },
            },
            inputProps: {
              placeholder: "Ingresar CARTA DE PORTE",
            },
          }),
        "812": $Text("RESOLUCIÓN DEL SNA")
          .showIf(
            () => _.findIndex(optionsSelected, (o) => o.code === "812") !== -1
          )
          .with({
            render: {
              Label() {
                return null;
              },
            },
            inputProps: {
              placeholder: "Ingresar RESOLUCIÓN DEL SNA",
            },
          }),
        "813": $Text("PASAPORTE")
          .showIf(
            () => _.findIndex(optionsSelected, (o) => o.code === "813") !== -1
          )
          .with({
            render: {
              Label() {
                return null;
              },
            },
            inputProps: {
              placeholder: "Ingresar PASAPORTE",
            },
          }),
        "814": $Text("CERTIFICADO DE DEPÓSITO BOLSA PROD. CHILE")
          .showIf(
            () => _.findIndex(optionsSelected, (o) => o.code === "814") !== -1
          )
          .with({
            render: {
              Label() {
                return null;
              },
            },
            inputProps: {
              placeholder: "Ingresar CERTIFICADO DE DEPÓSITO BOLSA PROD. CHILE",
            },
          }),
        "815": $Text("VALE DE PRENDA BOLSA PROD. CHILE")
          .showIf(
            () => _.findIndex(optionsSelected, (o) => o.code === "815") !== -1
          )
          .with({
            render: {
              Label() {
                return null;
              },
            },
            inputProps: {
              placeholder: "Ingresar VALE DE PRENDA BOLSA PROD. CHILE",
            },
          }),
        pep: $Text("PEP")
          .showIf(
            () => _.findIndex(optionsSelected, (o) => o.code === "PEP") !== -1
          )
          .with({
            inputProps: {
              placeholder: "Ingresar PEP",
            },
          }),
        emails: $ArrayEmails(
          "Correos electrónicos de contacto (Separados por ;  )"
        ),
      },
      submit: $Button("GUARDAR", {
        async onValid(values) {
          const references = Object.entries(values).filter(
            (x) =>
              !x.includes("emails") &&
              !x.includes("porcentaje") &&
              !x.includes("empresa") &&
              !x.includes("client_id")
          );

          const filialSetting = {
            porcentaje: values.porcentaje ?? "",
            references: references.map((reference) => {
              const extraFieldsValue = extraFieldsValues.find(
                (x) => x.code.toLowerCase() === reference[0].toLowerCase()
              );
              return {
                reference: getReferenceId(reference[0]),
                folio: reference[1] as string,
                date: extraFieldsValue?.date ?? null,
                text: extraFieldsValue?.text ?? "",
              };
            }),
            emails: values.emails,
            empresa: filial?.empresa.id,
            client_id: clientID!,
          };
          generateSetting.mutate(filialSetting, {
            onSuccess: (data) => {
              if (nonFilial) {
                setNonFiliales({
                  id: data.id,
                  porcentaje: 100,
                  emails: values.emails,
                  empresa: filial.empresa,
                  references: references.map((reference) => {
                    const extraFieldsValue = extraFieldsValues.find(
                      (x) => x.code.toLowerCase() === reference[0].toLowerCase()
                    );
                    return {
                      reference: {
                        code: reference[0].toUpperCase(),
                        id: getReferenceId(reference[0]),
                        name: "string",
                      },
                      folio: reference[1] as string,
                      date: extraFieldsValue?.date ?? null,
                      text: extraFieldsValue?.text ?? "",
                    };
                  }),
                });
              }
            },
          });

          const filialExistente = filiales!.findIndex(
            (item) => item.id === filial!.id
          );
          if (filialExistente !== -1) {
            filiales[filialExistente] = Object.assign(
              filiales[filialExistente],
              {
                ...values,
                references: references.map((reference) => {
                  const extraFieldsValue = extraFieldsValues.find(
                    (x) => x.code.toLowerCase() === reference[0].toLowerCase()
                  );
                  return {
                    reference: getReferenceId(reference[0]),
                    folio: reference[1] as string,
                    date: extraFieldsValue?.date ?? null,
                    text: extraFieldsValue?.text ?? "",
                  };
                }),
              }
            );
          }
        },
        onInvalid: "disable",
      }),
      props: {
        className:
          "customized-form w-full sm:flex flex-col sm:justify-between justify-center placeholder:text-gray700",
      },
    }).each((field) => {
      return field.with({
        inputProps: {
          placeholder: "",
        },
      });
    })
  );

  useEffect(() => {
    if (prefill) {
      const data = { ...filial };
      const references = data.references.map((x) => ({
        [x.reference.code?.toLowerCase()]: x.folio,
      }));
      const ref = Object.assign({}, data, ...references);
      form.fillWith({
        ...ref,
      });
    }
    if (nonFilial) {
      form.fillWith({
        porcentaje: new Decimal(100),
      });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useEffect(() => {
    const data = { ...filial };
    const references = data.references.map((x) => ({
      [x.reference.code?.toLowerCase()]: x.folio,
    }));
    const ref = Object.assign({}, data, ...references);
    form.fillWith({
      ...ref,
    });
  }, [filial]);

  useEffect(() => {
    setExtraFieldsValues(
      optionsSelected.map((x) => {
        return { code: x.code, date: x.date, text: x.text };
      })
    );
  }, [optionsSelected]);

  const {
    porcentaje,
    801: oc,
    hes,
    emails,
    cec,
    cta,
    ate,
    pep,
    802: nota,
    803: contrato,
    804: res,
    805: procCH,
    806: fichCH,
    807: dus,
    808: embarque,
    809: awb,
    810: mci,
    811: porte,
    812: sna,
    813: pasaporte,
    814: certificadoDeposito,
    815: prenda,
  } = form.fields;

  const fieldsArray = [
    oc,
    hes,
    cec,
    cta,
    ate,
    pep,
    nota,
    contrato,
    res,
    procCH,
    fichCH,
    dus,
    embarque,
    awb,
    mci,
    porte,
    sna,
    pasaporte,
    certificadoDeposito,
    prenda,
  ];

  const isVisible = useBoolean(false);
  const optionsRef = useRef(null);
  const triggerRef = useRef(null);
  useClickOutside<any>(optionsRef, isVisible.toggle, triggerRef);
  const [isHovered, setIsHovered] = useState('')

  return (
    <form {...form.props}>
      <p className="text-gray700 text-base mb-5">
        Complete la información de pago
      </p>
      <div className="border-b mb-6 pb-3">
        <div className="flex items-center ">
          <div className="text-sm">
            <div className="relative">
              {porcentaje.render()}
              <span className="absolute top-3 left-10 text-base font-semibold">
                %
              </span>
            </div>
          </div>
          <span className="border-l-0 border h-12 pr-6 text-sm rounded-r bg-gray100 border-gray-400 p-2 font-bold text-right w-9/12 pointer-events-none disabled-input flex items-center justify-end mb-1em">
            {formatCurrency(calcPercentage(monto, newPorcentaje), "CLP")}
          </span>
        </div>
        <div>{emails.render()}</div>
      </div>
      <div>
        <p className="text-gray700 text-tiny">
          Indicar más información (Por ejemplo: OC, CC, HES, etc.)
        </p>
        <div className="mb-4 mt-2 relative">
          <div
            ref={triggerRef}
            className="border border-gray300 h-12 rounded py-3 px-4 flex justify-between items-center cursor-pointer"
            onClick={isVisible.toggle}
          >
            <p>Seleccionar opciones</p>
            <div>
              <img src={circleDown} alt="arrow" className="w-3 mr-2" />
            </div>
          </div>
          {isVisible.value && (
            <div
              ref={optionsRef}
              className="bg-white border rounded shadow-littleBox z-75 absolute w-full top-14 py-2 mb-10 max-h-80 overflow-y-auto"
            >
              {queryReferences.data?.map((x) => {
                const isIncluded =
                  _.findIndex(optionsSelected, (o) => o.code === x.code) !== -1;
                return (
                  <div
                    key={x.id}
                    className="flex h-full hover:bg-gray200 p-3 items-center cursor-pointer"
                    onClick={() => {
                      if (isIncluded) {
                        setOptionsSelected((prev) =>
                          prev.filter((opt) => opt.code !== x.code)
                        );
                      } else {
                        setOptionsSelected((prev) => [
                          ...prev,
                          { code: x.code, folio: "" },
                        ]);
                      }
                    }}
                  >
                    <div
                      className={`transition-all duration-75 w-5 h-5 border shadow-checkbox rounded flex items-center justify-center  ${
                        isIncluded
                          ? "bg-red400 border-red400 shadow-redCheckbox"
                          : "bg-white border-gray300 shadow-checkbox"
                      }`}
                    >
                      <img
                        src={check}
                        alt="check"
                        className={`transition-all duration-75 ${
                          isIncluded ? "w-3" : "w-1"
                        }`}
                      />
                    </div>
                    <span className="ml-3 text-gray700 text-xs font-bold">
                      {x.name.replace(/\\u[\dA-F]{4}/gi, function (match) {
                        return String.fromCharCode(
                          parseInt(match.replace(/\\u/g, ""), 16)
                        );
                      })}
                    </span>
                  </div>
                );
              })}
            </div>
          )}
        </div>
        <div className="grid grid-cols-4 gap-x-3 gap-y-2 mb-8">
          {optionsSelected.map((x, i) => {
            return (
              <>
                <div
                onMouseEnter={()=> setIsHovered(x.code)}
                onMouseLeave={()=> setIsHovered('')}
                  data-tip
                  data-for="info"
                  key={i}
                  className="cursor-pointer px-3 bg-red400 border rounded border-red600 h-9 text-white flex items-center justify-between"
                  onClick={() => {
                    setOptionsSelected((prev) =>
                      prev.filter((option) => option !== x)
                    );
                  }}
                >
                  <span> {x.code}</span>

                  <img src={remove} alt="remove" />
                </div>
                
              </>
            );
          })}
        </div>
      </div>
      {queryReferences.data?.map((x) => { 
          if (isHovered === x.code ){
            return( 
           
                <ReactTooltip
                id="info"
                place="bottom"
                className="text-xs px-1 p-0 mb-4 font-light h-5"
              >
                   { x.name.replace(/\\u[\dA-F]{4}/gi, function (match) {
                      return String.fromCharCode(
                        parseInt(match.replace(/\\u/g, ""), 16)
                      );
                    }) }
              
              </ReactTooltip>)
          }
         
    })}
      
      <div>
        {fieldsArray.map((field) => {
          if (field.shouldBeShown) {
            return (
              <FieldWithExtraFields
                key={field.name}
                field={field}
                extraFieldsValues={extraFieldsValues}
                setExtraFieldsValues={setExtraFieldsValues}
              />
            );
          }
        })}
      </div>
      <div className="flex flex-col">
        {generateSetting.isError && (
          <AlertInfo
            errorMessage={`${generateSetting.error}`}
            className="text-center"
          />
        )}
        <div className="w-full mt-3">
          <ActionButton
            onClick={() => form.submit()}
            isLoading={generateSetting.isLoading}
          >
            Guardar
          </ActionButton>
        </div>
      </div>
    </form>
  );
};

export default InvoiceForm;
