import Decimal from 'decimal.js';
import { useAtom } from 'jotai';
import { useMutation } from 'react-query';
import { useHistory } from 'react-router-dom';
import { useRol } from '../customHooks/customHooks';
import {
    generateVoucher,
    particionarEmpresa,
    particionarPersona,
} from '../endpoints/endpoints';
import {
    documentosRecibosAtom,
    filialesAtom,
    filialSettingAtom,
    invoiceMethodAtom,
    nonFilialesAtom,
    nonFilialSettingAtom,
    payListAtom,
    porcentajeTotalAtom,
    voucherInfoAtom,
} from './Atoms';
import { CreateVoucherPart } from '../types/types';
import { useClearSettings } from '../customHooks/useClearSettings';

export interface AlertType {
    setShowModal: React.Dispatch<React.SetStateAction<boolean>>;
    setAlertMessage: React.Dispatch<React.SetStateAction<string>>;
}
export const useParticionPersona = () => {
    const history = useHistory();
    const [documentosRecibo] = useAtom(documentosRecibosAtom);
    const personaParticionar = useMutation(particionarPersona.fetch, {
        onSuccess: data => {
            history.push({
                pathname: `/persona/checkout`,
                state: data,
            });
        },
    });
    return {
        particionPersona: (alertModal: AlertType) => {
            const { setShowModal, setAlertMessage } = alertModal;
            if (documentosRecibo.documents.length > 0) {
                personaParticionar.mutate(documentosRecibo);
            } else {
                setShowModal(true);
                setAlertMessage(
                    'Agregue al menos una cuota para continuar con el pago'
                );
            }
        },
        personaIsError: personaParticionar.isError,
        personaIsLoading: personaParticionar.isLoading,
    };
};

export const useAgregarRecibos = () => {
    const [documentosRecibo] = useAtom(documentosRecibosAtom);
    const history = useHistory();
    return (alertModal: AlertType) => {
        const { setShowModal, setAlertMessage } = alertModal;
        if (documentosRecibo.documents.length > 0) {
            history.push({ pathname: '/corredor/partes-pago', state: {} });
        } else {
            setShowModal(true);
            setAlertMessage(
                'Debes agregar recibos para poder continuar con el pago'
            );
        }
    };
};

export const useCrearVoucher = () => {
    const [voucherInfoPersona] = useAtom(voucherInfoAtom);
    const history = useHistory();
    const [, setList] = useAtom(payListAtom);
    const [, setPorcentajeTotal] = useAtom(porcentajeTotalAtom);
    const rol = useRol();
    const cleanList = () => {
        localStorage.setItem('payListMapfre', JSON.stringify([]));
        setList([]);
    };
    const clearSettings = useClearSettings();
    const [, setShowInviceMethod] = useAtom(invoiceMethodAtom);

    const generarVoucher = useMutation(generateVoucher.fetch, {
        onSuccess: data => {
            cleanList();
            clearSettings();
            setPorcentajeTotal(new Decimal(0));
            history.push({
                pathname: `/${rol}/voucher`,
                state: {
                    ...data,
                    vencimiento: data.vencimiento.toString(),
                    fecha: data.fecha.toString(),
                },
            });
            setShowInviceMethod(false);
        },
    });

    return {
        crearVoucher: (alertModal: AlertType) => {
            const { setShowModal, setAlertMessage } = alertModal;
            if (
                voucherInfoPersona.partes.some(
                    parte => parte.cuenta_source === undefined
                )
            ) {
                setShowModal(true);
                setAlertMessage(
                    'Debes agregar cuenta bancaria de origen para continuar'
                );
            } else {
                generarVoucher.mutate({
                    partes: voucherInfoPersona.partes as CreateVoucherPart[],
                    factura_mode:
                        rol === 'persona'
                            ? null
                            : voucherInfoPersona.factura_mode,
                });
            }
        },
        voucherIsError: generarVoucher.isError,
        voucherIsLoading: generarVoucher.isLoading,
    };
};

export const useParticionEmpresa = () => {
    const [porcentajeTotal] = useAtom(porcentajeTotalAtom);
    const [filialSetting] = useAtom(filialSettingAtom);
    const [nonFilialSetting] = useAtom(nonFilialSettingAtom);
    const [documentosRecibo] = useAtom(documentosRecibosAtom);
    const history = useHistory();
    const corredorParticionar = useMutation(particionarEmpresa.fetch, {
        onSuccess: data => {
            history.push({
                pathname: `/corredor/checkout`,
                state: data,
            });
        },
    });
    return {
        particionEmpresa: (alertModal: AlertType) => {
            const { setShowModal, setAlertMessage } = alertModal;
            /*  if (!porcentajeTotal.equals(new Decimal(100))) {
                setShowModal(true);

                setAlertMessage(
                    'Debes asignar el 100% del gasto total antes de generar el voucher'
                );
            } else { */
            corredorParticionar.mutate({
                filial_payer_settings: filialSetting.map(
                    filial => filial.setting
                ),
                non_filial_payer_setting: nonFilialSetting
                    ? nonFilialSetting.setting
                    : null,
                documents: documentosRecibo.documents,
            });
            /*  } */
        },
        empresaIsError: corredorParticionar.isError,
        empresaIsLoading: corredorParticionar.isLoading,
    };
};
