import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { LocalDate, ZonedDateTime } from 'js-joda';
import { Divisa, UnassignedPayments, VoucherError } from '../../types/types';
import { useQuery } from 'react-query';
import { unassignedPayments } from '../../endpoints/endpoints';
import Loader from '../Loader/Loader';
import AlertInfo from '../Alert/AlertInfo';
import { TableCell } from '@material-ui/core';
import ErrorHeader from './ErrorHeader';
import ErrorTableBody from './ErrorTableBody';

const useStyles = makeStyles({
    table: {
        minWidth: 650,
    },
});

/* const createData = (rows: UnassignedPayments) => {
    return {
        rows.divisa,
        rows.monto,
        rows.source_account,
    };
}; */

const ErrorsTable: React.FC = () => {
    const paymentsList = useQuery<UnassignedPayments[]>(
        unassignedPayments.url,
        () => unassignedPayments.fetch()
    );

    /*     const totalPages = (data && Math.ceil(data!.count / 25)) ?? 0;
     */
    const classes = useStyles();
    const rows = paymentsList.data && paymentsList.data;

    return (
        <TableContainer
            style={{
                borderRadius: '7px',
                borderWidth: '1px',
                borderStyle: 'solid',
                borderColor: '#E1E1E1',
            }}
        >
            <>
                <Table
                    className={classes.table}
                    size='small'
                    aria-label='spaning table'
                >
                    <TableHead>
                        <TableRow
                            style={{
                                height: '50px',
                                backgroundColor: '#EEEEEE',
                            }}
                        >
                            <ErrorHeader />
                        </TableRow>
                    </TableHead>
                    {paymentsList.isLoading ? (
                        <>
                            <TableCell style={{ border: 'none' }} />
                            <TableCell style={{ border: 'none' }}>
                                <Loader />
                            </TableCell>
                            <TableCell style={{ border: 'none' }} />
                        </>
                    ) : paymentsList.isError ? (
                        <AlertInfo
                            errorMessage={`${paymentsList.error}`}
                            reload
                        />
                    ) : (
                        <TableBody>
                            {!!rows && <ErrorTableBody rows={rows} />}
                        </TableBody>
                    )}
                </Table>
                {/*     {totalPages > 1 && !loading && (
                    <ul className='flex list-reset rounded w-auto font-sans mt-3 text-sm justify-end'>
                        {data?.previous && (
                            <li
                                className='block cursor-pointer shadow-sm bg-gray-50 px-3 py-2 mr-1 border border-gray-300'
                                onClick={() => setPage(page - 1)}
                            >
                                Anterior
                            </li>
                        )}
                        {[...Array(totalPages).keys()].map((x) => (
                            <li
                                className={`p-2 cursor-pointer w-10 flex items-center justify-center ${
                                    page === x + 1
                                        ? 'border-1-5 border-gray-300 bg-gray400'
                                        : 'bg-gray-50 border-1-5 border-gray-200'
                                }`}
                                key={x + 1}
                                onClick={() => {
                                    setPage(x + 1);
                                }}
                            >
                                {x + 1}
                            </li>
                        ))}
                        {data?.next && (
                            <li
                                className='block cursor-pointer border border-gray-300 shadow-sm px-3 py-2 bg-gray-50 ml-1'
                                onClick={() => setPage(page + 1)}
                            >
                                Siguiente
                            </li>
                        )}
                    </ul>
                )} */}
            </>
        </TableContainer>
    );
};
export default ErrorsTable;
