import { Field } from '@tdc-cl/x-form';
import React, { useEffect, useState } from 'react';

const FieldWithExtraFields: React.FC<{
    field: Field<any, any>;
    extraFieldsValues: {
        code: string;
        date?: string | null;
        text?: string | null;
    }[];
    setExtraFieldsValues: React.Dispatch<
        React.SetStateAction<
            {
                code: string;
                date?: string | null;
                text?: string | null;
            }[]
        >
    >;
}> = ({ field, extraFieldsValues, setExtraFieldsValues }) => {
    const [extraValues, setExtraValues] =
        useState<{
            code: string;
            text?: string | null;
            date?: string | null;
        }>();
    const values = extraFieldsValues.find(
        x => x.code.toLowerCase() === field.name.toLowerCase()
    );
    const [textReference, setTextReference] = useState('');
    const [dateReference, setDateReference] = useState('');

    const handleChange = (e: any, key: 'date' | 'text') => {
        const value = e.target?.value;
        // @ts-ignore
        setExtraFieldsValues(prev => [
            ...prev.filter(x => x.code !== field.name),
            // @ts-ignore
            Object.assign(extraValues, {
                [key]: value,
            }),
        ]);
    };

    /*     useEffect(() => {
        if (field.shouldBeShown) {
            if (!extraValues) {
                setExtraFieldsValues(prev => [
                    ...prev,
                    {
                        code: field.name,
                    },
                ]);
            }
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [field.shouldBeShown]); */

    useEffect(() => {
        setExtraValues(values);
        setDateReference(values?.date ?? '');
        setTextReference(values?.text ?? '');
    }, [field.shouldBeShown, values]);

    return (
        <div className={`mb-4 ${!field.shouldBeShown ? 'hidden' : ''}`}>
            <div className='ml-3'>{field.label}</div>
            <div className='flex'>
                <div className='mt-2'>
                    <div className='border-r-2 border-red400 h-full  ml-3 mr-5 block'></div>
                </div>
                <div className='flex-1 py-4'>
                <label >
                        Folio de Referencia
                    </label>
                {field.render()}
                    <label onClick={() => console.log(extraValues)}>
                        Fecha Referencia (opcional)
                    </label>
                    <input
                        name={field.name}
                        type='date'
                        value={dateReference}
                        className='w-full'
                        onChange={(e: any) => {
                            setDateReference(e.target?.value);
                            handleChange(e, 'date');
                        }}
                    />
                    <div className='mt-4'>
                        <label>Referencia (opcional)</label>
                        <textarea
                            maxLength={60}
                            placeholder={'Máximo 60 caracteres'}
                            value={textReference}
                            className='w-full border border-gray300 rounded h-16 px-4 py-3 placeholder-gray300'
                            onChange={(e: any) => {
                                setTextReference(e.target?.value);
                                handleChange(e, 'text');
                            }}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default FieldWithExtraFields;
