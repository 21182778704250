import ReCAPTCHA from "react-google-recaptcha";
import React from "react";
import { Environment } from "../../utils/Environment";
import { isLocalhost } from "../../utils/isLocalhost";

export function RecaptchaField(props: { onChange(value: string | null): void }) {
    return (
        <ReCAPTCHA
            sitekey={getSiteKey()}
            onChange={props.onChange}
        />
    );
}

function getSiteKey(): string {
    if (isLocalhost()) {
        return testSiteKey;
    }

    return Environment.getString('REACT_APP_SITE_KEY');
}

const testSiteKey = '6LeIxAcTAAAAAJcZVRqyHh71UMIEGNQ_MXjiZKhI';