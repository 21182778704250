import React, { useEffect } from 'react';
import { Link, useHistory, useLocation } from 'react-router-dom';
import Navbar from '../components/Navbar/Navbar';
import VoucherSummary from '../components/PortalPagos/Checkout/VoucherPersona';
import check from '../svgs/c-check.svg';
import { useRol } from '../customHooks/customHooks';
import ActionButton from '../components/Buttons/ActionButton';
import { ListVoucher } from '../types/types';
import { useAtom } from 'jotai';
import { selectedVoucherAtom } from '../store/Atoms';
import Screen from '../components/Screen/Screen';

const PayCompleted = () => {
    const rol = useRol();
    const history = useHistory();
    const { state } = useLocation() as { state: ListVoucher };
    const [, setSelectedVoucher] = useAtom(selectedVoucherAtom);

    useEffect(() => {
        if (!state) {
            if (rol === 'contabilidad') {
                history.push(`/${rol}/portal-de-pagos/vouchers`);
            } else {
                history.push(`/${rol}/portal-de-pagos/seguros`);
            }
        }
        return () => {
            history.replace({ state: undefined });
        };
    }, [state, rol, history]);
    return (
        <Screen>
            <Navbar />
            <section className='flex justify-center'>
                <div className='container  grid grid-cols-1 md:grid-cols-12 '>
                    <div className='col-span-6'>
                        <div className='w-full mx-auto  mb-4 md:pr-10'>
                            <div className='rounded-lg border-1-3 border-gray-300 h-80 bg-white'>
                                <div className='flex p-4 justify-between border-gray-300 border-b-1-5'>
                                    <p className='font-bold text-gray700 text-lg'>
                                        ¡Tu voucher de pago ha sido generado!
                                    </p>
                                    <img src={check} alt='logo check' />
                                </div>
                                <div className='p-6 mt-8'>
                                    {rol === 'contabilidad' ? (
                                        <p className='mb-5'>
                                            Puedes revisar el estado en la
                                            sección de{' '}
                                            <Link
                                                to='/contabilidad/portal-de-pagos/vouchers'
                                                className='underline font-semibold'
                                            >
                                                Vouchers
                                            </Link>
                                        </p>
                                    ) : state?.user.tipo === 'empresa' ? (
                                        <p className='mb-5'>
                                            Ya puedes confirmar las facturas de
                                            tu voucher.
                                        </p>
                                    ) : (
                                        <p className='mb-5'>
                                            Tu <b>Voucher nº {state?.id}</b> fue
                                            generado y se encuentra pendiente de
                                            pago. <br />
                                            <br />
                                            Puedes revisar el detalle de tu
                                            voucher en la sección{' '}
                                            <Link
                                                to={
                                                    '/persona/portal-de-pagos/vouchers'
                                                }
                                                className='underline'
                                            >
                                                Mis Vouchers
                                            </Link>
                                        </p>
                                    )}
                                    <div className=' flex'>
                                        <div className='mr-3 w-28'>
                                            <ActionButton
                                                variant={'white'}
                                                onClick={() =>
                                                    history.push('/')
                                                }
                                            >
                                                Ir al inicio
                                            </ActionButton>
                                        </div>
                                        <div className='w-48'>
                                            <ActionButton
                                                onClick={() => {
                                                    setSelectedVoucher({
                                                        voucher: state.id,
                                                        show: true,
                                                    });
                                                    history.push(
                                                        `/${rol}/portal-de-pagos/vouchers`
                                                    );
                                                }}
                                            >
                                                Ver voucher
                                            </ActionButton>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className='col-span-4 '>
                        {state && <VoucherSummary {...state} />}
                    </div>
                </div>
            </section>
        </Screen>
    );
};

export default PayCompleted;
