import React from 'react';
import ActionButton from '../Buttons/ActionButton';
interface AlertProps {
  setShowModal: (arg: boolean) => void;
  alertMessage: string;
}
const AlertModal: React.FC<AlertProps> = ({ setShowModal, alertMessage }) => {
  return (
    <div className='flex flex-col justify-center items-center p-3 text-center text-lg gray600'>
      {alertMessage}
      <div className='w-20 mt-5'>
        <ActionButton onClick={() => setShowModal(false)}>OK</ActionButton>
      </div>
    </div>
  );
};

export default AlertModal;
