import Decimal from "decimal.js";
import React, { useState } from "react";
import Document from "../components/VoucherDetail/Document";
import circleDown from "../svgs/circle-ctrl-down.svg";
import { ListVoucherPart } from "../types/types";
import { formatCurrency } from "../utils/utils";

const VoucherPartDetail: React.FC<{ item: ListVoucherPart; errors?: any }> = ({
  item,
  errors,
}) => {
  const [showMore, setShowMore] = useState(false);
  const toggleShow = () => {
    console.log(errors);
    if (showMore) {
      setShowMore(false);
    } else {
      setShowMore(true);
    }
  };
  const [hoveredRow, setHovereRow] = useState<number | undefined>(undefined);
  return (
    <div className={"w-full  mb-4 "} key={item.id}>
      <div className="rounded border-1-3 border-gray300 bg-white">
        <div
          className="flex p-4 font-bold text-lg items-center justify-between cursor-pointer"
          onClick={toggleShow}
          onMouseEnter={()=> setHovereRow(item.id)}
          onMouseLeave={()=> setHovereRow(undefined)}
        >
          <div>
            <span className="text-2xl text-gray700">Parte {item.id}</span>

            <span className="text-2xl mx-2 text-gray700">&#xb7;</span>
            <span className="text-gray700">
              {formatCurrency(
                new Decimal(
                  (item.partition_part as any)[
                    item.partition_part.monto_to_show
                  ].monto
                ),
                (item.partition_part as any)[item.partition_part.monto_to_show]
                  .divisa
              )}{" "}
            </span>
          </div>
          <div className={`h-7 w-7 ${item.id === hoveredRow && 'bg-gray200'} rounded-full flex justify-center items-center`}>
            <img
              src={circleDown}
              alt="see more icon"
              className=" max-w-max w-14  cursor-pointer"
            />
          </div>
        </div>
        {showMore && (
          <div className="px-4 pb-4">
            <p className="font-bold mb-2 text-gray700 text-base">Documentos:</p>

            {item.partition_part.items?.map((partition, i) => {
              return (
                <div key={i} className={`${i !== 0 && "border-t pt-3 mt-3"}`}>
                  <Document
                    partition={partition}
                    errors={errors ? errors.failed_documents : undefined}
                  />
                </div>
              );
            })}
          </div>
        )}
      </div>
    </div>
  );
};

export default VoucherPartDetail;
