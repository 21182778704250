import React, { useState } from 'react';
import { useRol } from '../customHooks/customHooks';
import PartsCorredor from '../components/Corredor/PartsCorredor';
import Navbar from '../components/Navbar/Navbar';
import PaySummary from '../components/PaySummary/PaySummary';
import PartsSummary from '../components/PortalPagos/Checkout/PartsSummary';
import { useParams } from 'react-router-dom';
import CorredorCheckout from '../components/PortalPagos/Checkout/CorredorCheckout';
import {
    AlertType,
    useCrearVoucher,
    useParticionEmpresa,
} from '../store/hooks';
import Screen from '../components/Screen/Screen';
import { useAtom } from 'jotai';
import { invoiceMethodAtom, voucherInfoAtom } from '../store/Atoms';
import CustomModal from '../components/CustomModal/CustomModal';
import InvoiceMethod from '../components/PortalPagos/Checkout/InvoiceMethod';
const CheckoutInsurance = () => {
    const rol = useRol();
    const { paso } = useParams() as { paso: string };

    const { crearVoucher, voucherIsError, voucherIsLoading } =
        useCrearVoucher();

    const { particionEmpresa, empresaIsError, empresaIsLoading } =
        useParticionEmpresa();

    const handlePayCorredor = (alertModal: AlertType) => {
        if (paso === 'partes-pago') {
            particionEmpresa(alertModal);
        } else if (paso === 'checkout') {
            setShowInviceMethod(true);
        }
    };
    const createVoucherCorredor = (alertModal: AlertType) => {
        crearVoucher(alertModal);
    };
    const [voucherInfo] = useAtom(voucherInfoAtom);
    const [showInvoiceMethod, setShowInviceMethod] = useAtom(invoiceMethodAtom);
    return (
        <Screen>
            <Navbar />
            <section>
                <div className='grid grid-cols-1 lg:grid-cols-12 '>
                    <div className='col-span-9'>
                        {rol === 'persona' && <PartsSummary />}
                        {rol === 'corredor' && paso === 'partes-pago' ? (
                            <PartsCorredor />
                        ) : (
                            rol === 'corredor' &&
                            paso === 'checkout' && <CorredorCheckout />
                        )}
                    </div>

                    <div className='md:col-span-3 mt-8 lg:mt-0'>
                        <PaySummary
                            onClick={
                                rol === 'corredor'
                                    ? handlePayCorredor
                                    : crearVoucher
                            }
                            isError={
                                rol === 'corredor'
                                    ? empresaIsError
                                    : voucherIsError
                            }
                            isLoading={
                                rol === 'corredor'
                                    ? paso === 'partes-pago'
                                        ? empresaIsLoading
                                        : voucherIsLoading
                                    : voucherIsLoading
                            }
                        />
                    </div>
                </div>
                <CustomModal
                    isOpen={showInvoiceMethod}
                    setIsOpen={setShowInviceMethod}
                >
                    <InvoiceMethod
                        onClick={createVoucherCorredor}
                        isLoading={voucherIsLoading}
                    />
                </CustomModal>
            </section>
        </Screen>
    );
};

export default CheckoutInsurance;
