import React from 'react';
import { ListVoucher } from '../../types/types';
import MotivesForm from '../forms/MotivesForm';
import close from '../../svgs/e-close.svg';
import { useAtom } from 'jotai';
import { showPreviewAtom } from '../../store/Atoms';

interface CancelProps {
  setShow: (arg: boolean) => void;
  state: ListVoucher;
  preview: string;
}
const CancelVoucher: React.FC<CancelProps> = ({ setShow, state, preview }) => {
  const [, setShowModal] = useAtom(showPreviewAtom);
  return (
    <div className='bg-white flex flex-col w-full px-6 text-left'>
      <div className='gray600 border-b flex justify-between items-center border-gray200 mb-6 pb-4'>
        <p className='text-xl font-bold text-gray700'>
          Facturación Voucher #{state.id}
        </p>

        <div
          onClick={() => setShowModal(false)}
          className='bg-gray200 hover:bg-gray250 w-11 h-11 flex justify-center items-center rounded cursor-pointer'
        >
          <img src={close} alt='' />
        </div>
      </div>
      <div>
        <MotivesForm
          options={Object.entries({
            1: 'Error en la selección de recibos',
            2: 'Error de distribución de porcentaje de Factura Anticipada',
            3: 'Factura Necesitaba otros Tipos de Cambio Explícitos',
            4: 'Error u Omisión de Datos de Factura como OC, HES, DAS, CeCo',
            5: 'Cliente Pagará Más Recibos en un único Voucher',
            6: 'Cliente Crear Más Vouchers y descompondrá el actual',
            7: 'Faltaban Recibos no remesados en el Voucher',
            8: 'Otro',
          })}
          state={state}
          setShow={setShow}
          preview={preview}
        />
      </div>
    </div>
  );
};

export default CancelVoucher;
