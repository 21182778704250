import { withStyles, Theme, createStyles } from '@material-ui/core/styles';
import TableCell from '@material-ui/core/TableCell';

export const StyledTableCell = withStyles((theme: Theme) =>
    createStyles({
        head: {
            backgroundColor: '#EEEEEE',
            color: '#616664',
            fontSize: 13,
            fontWeight: 'bold',
            fontFamily: 'DMSans',
        },
        body: {
            fontSize: 12,
            fontFamily: 'DMSans',
            color: '#363636',
            paddingTop: 14,
            paddingBottom: 14,
            backgroundColor: '#fff',
        },
    })
)(TableCell);
