import Decimal from "decimal.js";
import { LocalDate } from "js-joda";
import React from "react";
import { Divisa, ListVoucher } from "../../../types/types";
import { formatCurrency, formatDate } from "../../../utils/utils";
import Status from "../../PaymentsTable/Status";
import VoucherDetailPersona from "./VoucherDetailPersona";
import VoucherInfo from "./VoucherInfo";
const VoucherPersona: React.FC<ListVoucher> = ({
    id,
    state,
    fecha,
    partes,
    user,
    vencimiento,
    total_to_show,
}) => {
    const totalDivisas = Object.entries(total_to_show);
    return (
        <div>
            <div className='flex flex-col h-3/5 rounded-lg bg-white shadow-lg'>
                <div className='py-3 px-8 bg-gray700 text-white  flex  rounded-t-lg items-center'>
                    <p className='font-bold text-2xl'>Total</p>
                    <div className='text-right text-2xl font-bold ml-auto flex items-center'>
                        <div >
                            {totalDivisas.map(
                                (divisa) =>
                                    !!divisa[1] && (
                                        <p className='text-2xl'>
                                            {formatCurrency(
                                                new Decimal(divisa[1]),
                                                divisa[0] as Divisa
                                            )}
                                        </p>
                                    )
                            )}
                        </div>
                    </div>
                </div>
                <div className='py-4 px-8 text-sm'>
                    <div className='border-dashed border-b border-gray300 pb-5 mb-3 text-gray-600'>
                        <VoucherInfo title='ID Voucher' value={id} />

                        <div className='flex mt-3'>
                            <small className='text-gray400 text-sm '>Fecha</small>
                            <small className='ml-auto text-sm text-gray700 font-medium'>
                                {`${new Date(
                                    fecha.toString()
                                ).toLocaleDateString("es-ES", {
                                    day: "numeric",
                                    month: "short",
                                    year: "numeric",
                                })}`}
                            </small>
                        </div>
{/* 
                        <div className='flex mt-3'>
                            <small>Fecha de vencimiento</small>
                            <small className='ml-auto font-bold text-black'>
                                {formatDate(
                                    LocalDate.parse(vencimiento.toString())
                                )}
                            </small>
                        </div> */}

                        {user.tipo === "empresa"  && (
                            <>
                                <div className='flex mt-3'>
                                    <small>Empresa</small>
                                    <small className='ml-auto text-sm text-gray700 font-medium'>
                                        {user.nombre}
                                    </small>
                                </div>

                                <div className='flex mt-3'>
                                    <small>Rut Empresa</small>
                                    <small className='ml-auto text-sm text-gray700 font-medium'>
                                        {user.rut}
                                    </small>
                                </div>
                            </>
                        )}

                        <div className='flex mt-3'>
                            <small className='text-gray400 text-sm'>Estado actual</small>
                            <small className='ml-auto text-sm text-gray700 font-medium'>
                                Pendiente
                            </small>
                        </div>
                    </div>
                    {partes.map((parte, i) => (
                        <VoucherDetailPersona index={i} parte={parte} key={i} />
                    ))}
                </div>
            </div>
        </div>
    );
};
export default VoucherPersona;
