import React, { useEffect } from 'react';
import Navbar from '../components/Navbar/Navbar';
import InvoiceSummary from '../components/VoucherDetail/InvoiceSummary';
import CancelVoucher from '../components/VoucherDetail/CancelVoucher';
import { useAtom } from 'jotai';
import { setPreviewAtom, showPreviewAtom } from '../store/Atoms';
import { Link, useHistory, useLocation } from 'react-router-dom';
import { ListVoucher } from '../types/types';
import AnticipadoBadge from '../components/Badges/AnticipadoBadge';
import { formatCurrency } from '../utils/utils';
import Decimal from 'decimal.js';
import CustomModal from '../components/CustomModal/CustomModal';
import { fetchDetailVoucher, voucherError } from '../endpoints/endpoints';
import { useQuery } from 'react-query';
import { classNamed } from '../utils/utils';
import arrowLeft from '../svgs/arrowLeft.svg';
import VoucherPartDetail from './VoucherPartDetail';
import Screen from '../components/Screen/Screen';

const VoucherDetail = () => {
  const [show, setShow] = useAtom(showPreviewAtom);
  const [preview, setPreview] = useAtom(setPreviewAtom);
  const { state } = useLocation() as { state: ListVoucher };
  const history = useHistory();

  const voucherErrors = useQuery(
    [voucherError.url],
    () => voucherError.fetch(state.id),
    {
      refetchOnWindowFocus: false,
      enabled: !!state.id,
    }
  );

  useEffect(() => {
    if (!state) {
      history.push('/contabilidad/portal-de-pagos/vouchers');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state]);

  useEffect(() => {
    if (!state) {
      history.push('/contabilidad/portal-de-pagos/vouchers');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const needsRefund = (refund: number | null) => {
    if (refund === 1) {
      return 'Si';
    } else if (refund === -1) {
      return 'No';
    } else if (refund === 0) {
      return 'A evaluar';
    }
    return '-';
  };

  const HeaderCell = classNamed.div('border-r p-2 font-bold');
  const Cell = classNamed.div('border-r border-b p-2');
  const voucherDetail = useQuery<ListVoucher>(
    ['voucher_detail', state.id],
    () => fetchDetailVoucher.fetch(state.id)
  );
  return (
    <Screen>
      <Navbar />
      <section>
        <div className='grid grid-cols-1 md:grid-cols-12 px-4'>
          <div className='col-span-9'>
            <div className='w-full mx-auto mb-4 md:pr-10'>
              <Link
                to='/contabilidad/portal-de-pagos/vouchers'
                className='flex mb-3 cursor-pointer'
              >
                <img src={arrowLeft} alt='back arrow' />
                <p className='font-base ml-3'>Volver a Mis Vouchers</p>
              </Link>
              <div className='rounded-lg border-1-3 border-gray-300 px-6 py-2 h-20 flex justify-between items-center  mb-5 bg-white'>
                <div>
                  <p className='text-gray700 font-bold text-2xl'>
                    {state.user.nombre}
                  </p>
                  <small className='text-gray400 text-xs '>
                    RUT {state.user.rut}
                  </small>
                </div>
                <div>
                  {state.user.facturacion_anticipada && <AnticipadoBadge />}
                </div>
              </div>
              <div className='rounded-lg border-1-3 border-gray-300  bg-white'>
                <div className='flex px-6 py-4 justify-between'>
                  <p className='font-bold text-gray700 text-2xl'>
                    Partes del voucher #{state.id}
                  </p>
                </div>
                <div className='p-6 '>
                  {voucherDetail?.data?.partes.map((parte, i) => (
                    <VoucherPartDetail
                      item={parte}
                      errors={
                        voucherErrors.data
                          ? voucherErrors.data![0].payments_errors.find(
                              error => error.voucher_part === parte.id
                            )
                          : undefined
                      }
                    />
                  ))}
                </div>
              </div>
              {voucherErrors.isSuccess && voucherErrors.data?.length > 0 && (
                <div className={'my-4'}>
                  <div className='grid md:grid-cols-2 gap-x-2 pr-5'>
                    <div>
                      <p className='font-bold text-xl'>Solicitado: </p>
                      <div className='grid grid-cols-4 border border-b-0 border-r-0 rounded mb-4'>
                        <div className='grid grid-cols-4 col-span-4 bg-gray-100 border-b'>
                          <HeaderCell>Parte Voucher</HeaderCell>
                          <HeaderCell>Monto Cartola</HeaderCell>
                          <HeaderCell>Monto email</HeaderCell>
                          <HeaderCell>Reembolso</HeaderCell>
                        </div>
                        <div className='grid grid-cols-4 col-span-4 bg-white'>
                          {voucherErrors.data[0]?.requests_data.map(
                            (data, i) => {
                              console.log(data);
                              return (
                                <div
                                  key={i}
                                  className='col-span-4 grid grid-cols-4'
                                >
                                  <Cell>{data.voucher_part}</Cell>
                                  <Cell>
                                    {!!data &&
                                      data.monto_cartola?.map(x => (
                                        <p className=''>
                                          {formatCurrency(
                                            new Decimal(x.monto),
                                            x.divisa
                                          )}
                                        </p>
                                      ))}
                                  </Cell>
                                  <Cell>
                                    {' '}
                                    {!!data &&
                                      data.monto_email?.map(x => (
                                        <p className=''>
                                          {formatCurrency(
                                            new Decimal(x.monto),
                                            x.divisa
                                          )}
                                        </p>
                                      ))}
                                  </Cell>
                                  <Cell>{needsRefund(data.need_refund)}</Cell>
                                </div>
                              );
                            }
                          )}
                        </div>
                      </div>
                    </div>
                    <div>
                      <p className='font-bold text-xl'>Errores: </p>
                      <div className='grid grid-cols-3 border border-b-0 border-r-0 rounded mb-4'>
                        <div className='grid grid-cols-3 col-span-3 bg-gray-100 border-b'>
                          <HeaderCell>Parte Voucher</HeaderCell>
                          <HeaderCell>Monto</HeaderCell>
                          <HeaderCell>Tipo</HeaderCell>
                        </div>
                        <div className='grid grid-cols-3 col-span-3 bg-white'>
                          {voucherErrors.data[0]?.payments_errors.map(
                            (data, i) => (
                              <div
                                key={i}
                                className='col-span-3 grid grid-cols-3'
                              >
                                <Cell>{data.voucher_part}</Cell>
                                <Cell>
                                  {data.not_used_monto
                                    ? formatCurrency(
                                        new Decimal(
                                          Number(data.not_used_monto)
                                        ),
                                        data.divisa
                                      )
                                    : '-'}
                                </Cell>
                                <Cell>{data.type ?? '-'}</Cell>
                              </div>
                            )
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
          <div className='md:col-span-3'>
            <InvoiceSummary
              setPreview={setPreview}
              setShowPreview={setShow}
              voucher={state}
              documents={[]}
            />
          </div>
        </div>
      </section>
      <CustomModal isOpen={show} setIsOpen={setShow} size={'md'} shadow onTop>
        <CancelVoucher setShow={setShow} state={state} preview={preview} />
      </CustomModal>
    </Screen>
  );
};

export default VoucherDetail;
