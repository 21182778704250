import React, {useState} from 'react';
import './form.css';
import {$Button, $Form, $Password, spanish, useForm, XFormContext} from '@tdc-cl/x-form';
import {useHistory} from 'react-router-dom';
import {$Rut} from '../../customFields/$Rut';
import {useMutation} from 'react-query';

import {authStorage, loginCobrador, loginContabilidad, loginCorredor, loginPersona,} from '../../endpoints/endpoints';
import AlertInfo from '../Alert/AlertInfo';
import ActionButton from '../Buttons/ActionButton';
import {RecaptchaField} from "./RecaptchaField";

interface FormProps {
    rol: string;
}

const Form: React.FC<FormProps> = ({rol}) => {
    const history = useHistory();

    const personaLogin = useMutation(loginPersona.fetch, {
        onSuccess: data => {
            authStorage.set(data);
            history.push(`/${rol}/portal-de-pagos/seguros`);
        },
    });

    const corredorLogin = useMutation(loginCorredor.fetch, {
        onSuccess: data => {
            authStorage.set(data);

            history.push(`/corredor/inicio`);
        },
    });

    const cobradorLogin = useMutation(loginCobrador.fetch, {
        onSuccess: data => {
            authStorage.set(data);

            history.push(`/contabilidad/portal-de-pagos/vouchers`);
        },
    });

    const contabilidadLogin = useMutation(loginContabilidad.fetch, {
        onSuccess: data => {
            authStorage.set(data);
            history.push(`/contabilidad/portal-de-pagos/vouchers`);
        },
    });

    const PasswordForm = useForm(
        $Form({
            fields: {
                rut: $Rut().with({
                    inputProps: {
                        className: 'mb-4',
                    },
                }),
                password: $Password('Contraseña'),
            },
            submit: $Button('INGRESAR', {
                async onValid(values) {
                    if (rol === 'corredor') {
                        corredorLogin.mutate({
                            ...values,
                            recaptcha: captchaValidation!,
                        });
                    } else if (rol === 'contabilidad' || rol === 'agente') {
                        contabilidadLogin.mutate({
                            rut: values.rut,
                            password: values.password,
                            recaptcha: captchaValidation!,
                        });
                    } else if (rol === 'cobrador') {
                        cobradorLogin.mutate({
                            rut: values.rut,
                            password: values.password,
                            recaptcha: captchaValidation!,
                        });
                    }
                },
            }),
        })
    );

    const [captchaValidation, setCaptchaValidation] =
        useState<string | null>(null);

    const handleCaptcha = (value: string) => setCaptchaValidation(value);

    const RutForm = useForm(
        $Form({
            fields: {
                rut: $Rut('Ingresa tu RUT'),
            },
            submit: $Button('INGRESAR', {
                async onValid(values) {
                    personaLogin.mutate({
                        rut: values.rut,
                        recaptcha: captchaValidation!,
                    });
                },
            }),
        })
    );

    return (
        <XFormContext.Provider value={{locale: spanish}}>
            <div className='customized-form'>
                {(personaLogin.isError ||
                    corredorLogin.isError ||
                    contabilidadLogin.isError ||
                    cobradorLogin.isError) && (
                    <AlertInfo className='text-red600'>
                        <p className='font-bold'>RUT o Contraseña incorrecta</p>
                        <p className='font-normal'>
                            Por favor verifica el RUT y contraseña ingresada
                        </p>
                    </AlertInfo>
                )}
                {rol === 'persona' ? (
                    <div>
                        {RutForm.renderFields()}
                        <div className='mx-auto mt-5'>
                            <RecaptchaField
                                onChange={(value: string | null) =>
                                    handleCaptcha(value!)
                                }
                            />
                        </div>
                        <div className='mt-5'>
                            <ActionButton
                                classname={'text-tiny'}
                                isLoading={personaLogin.isLoading}
                                disabled={
                                    !captchaValidation || !RutForm.isValid
                                }
                                onClick={() => {
                                    RutForm.submit();
                                }}
                            >
                                Ingresar
                            </ActionButton>
                        </div>
                    </div>
                ) : (
                    <>
                        {PasswordForm.renderFields()}
                        {(['contabilidad', 'cobrador'].includes(rol)) && (
                            <div className='text-red-600 font-bold text-tiny'>
                                <a href={`/${rol}/reset-pwd-request`}>¿Olvido la Contraseña?</a>
                            </div>)
                        }

                        <div className='mx-auto mt-5'>
                            <RecaptchaField
                                onChange={(value: string | null) =>
                                    handleCaptcha(value!)
                                }
                            />
                        </div>
                        <div className='mt-5'>
                            <ActionButton
                                classname={'text-tiny'}
                                isLoading={
                                    personaLogin.isLoading ||
                                    corredorLogin.isLoading ||
                                    contabilidadLogin.isLoading ||
                                    cobradorLogin.isLoading
                                }
                                onClick={() => PasswordForm.submit()}
                                disabled={
                                    !captchaValidation || !PasswordForm.isValid
                                }
                            >
                                Ingresar
                            </ActionButton>
                        </div>
                    </>
                )}
            </div>
        </XFormContext.Provider>
    );
};

export default Form;
