import React, { useState, useEffect } from 'react';
import InsuranceCard from '../components/InsuranceCard/InsuranceCard';
import Navbar from '../components/Navbar/Navbar';
import PaySummary from '../components/PaySummary/PaySummary';
import InsuranceType from '../components/PortalPagos/InsuranceType';
import { useRol } from '../customHooks/customHooks';
import CorredorHeader from '../components/Corredor/CorredorHeader';
import { Poliza, User } from '../types/types';
import { proximaCuota } from '../utils/utils';
import { useQuery } from 'react-query';
import Loader from '../components/Loader/Loader';
import { useHistory, useLocation } from 'react-router-dom';
import { LocalDate } from 'js-joda';
import { documentosPolizas } from '../endpoints/endpoints';
import AlertInfo from '../components/Alert/AlertInfo';
import { useAgregarRecibos, useParticionPersona } from '../store/hooks';
import Screen from '../components/Screen/Screen';
import { useAtom } from 'jotai';
import { payListAtom } from '../store/Atoms';

const PortalPagos = () => {
    const [activeType, setActiveType] = useState('Seguro General');
    const [cardsOrder, setCardsOrder] = useState('desc');
    const rol = useRol();
    const history = useHistory();
    const { state } = useLocation() as { state: User };
    const personaID = JSON.parse(localStorage.getItem('userInfoMapfre')!)?.id;
    const queryEndpointID = rol === 'persona' ? personaID : state?.id;
    const [list] = useAtom(payListAtom);

    const { data, isLoading, isSuccess, isError, error } = useQuery<Poliza[]>(
        [documentosPolizas.url, queryEndpointID],
        () => documentosPolizas.fetch(queryEndpointID)
    );

    const sortList = (insurances: Poliza[], activeType: string) => {
        const ultimasCuotas = insurances.sort((poliza1, poliza2) => {
            const siguientePago1 = proximaCuota(poliza1.recibos)?.vencimiento;
            const siguientePago2 = proximaCuota(poliza2.recibos)?.vencimiento;

            if (!siguientePago1 || !siguientePago2) {
                return 1;
            }
            if (cardsOrder === 'desc') {
                return LocalDate.parse(siguientePago2.toString()).isAfter(
                    LocalDate.parse(siguientePago1.toString())
                )
                    ? -1
                    : 0;
            } else {
                return LocalDate.parse(siguientePago1.toString()).isAfter(
                    LocalDate.parse(siguientePago2.toString())
                )
                    ? -1
                    : 0;
            }
        });
        return ultimasCuotas
            .filter((cuota) => cuota.tipo === activeType)
            .filter((x) =>
                x.recibos.some(
                    (recibo) =>
                        recibo.en_voucher === false && recibo.pagado === false
                )
            );
    };
    const handleSelectChange = (event: { target: HTMLSelectElement }) => {
        setCardsOrder(event.target.value);
    };

    const typeOfInsurance = (value: string, data?: Poliza[]) => {
        return !!data
            ? data?.filter((insurance) => insurance.tipo === value).length
            : 0;
    };

    const agregarRecibos = useAgregarRecibos();
    const { particionPersona, personaIsError, personaIsLoading } =
        useParticionPersona();

    useEffect(() => {
        if (rol === 'corredor') {
            if (!state) {
                history.push(`/corredor/inicio`);
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [state, rol]);

    return (
        <Screen>
            <Navbar />
            <section>
                <div className='grid grid-cols-1 lg:grid-cols-12'>
                    <div className='col-span-9'>
                        {rol === 'corredor' && (
                            <CorredorHeader
                                link={'/corredor/inicio'}
                                checkout={false}
                                cliente={state}
                            />
                        )}
                        <p className='text-2xl font-bold text-gray700'>
                            1. Seleccione las cuotas a pagar
                        </p>
                        <div className='mt-2 lg:mr-5 flex flex-col justify-center bg-calcite rounded-lg border'>
                            <div className='pt-5 px-8 lg:flex  items-center'></div>
                            <div className='md:flex px-8 py-4 items-end'>
                                <InsuranceType
                                    activeType={activeType}
                                    setActiveType={setActiveType}
                                    generalLength={typeOfInsurance(
                                        'Seguro General',
                                        data?.filter((x) =>
                                            x.recibos.some(
                                                (recibo) =>
                                                    recibo.en_voucher ===
                                                        false &&
                                                    recibo.pagado === false
                                            )
                                        )
                                    )}
                                    vidaLength={typeOfInsurance(
                                        'Seguro de Vida',
                                        data?.filter((x) =>
                                            x.recibos.some(
                                                (recibo) =>
                                                    recibo.en_voucher ===
                                                        false &&
                                                    recibo.pagado === false
                                            )
                                        )
                                    )}
                                />
                                <div className='ml-auto mt-4 lg:mt-0 flex items-center text-sm'>
                                    <span className='text-tiny text-gray700'>
                                        Ordenar seguros por
                                    </span>
                                    <select
                                        className='bg-gray100 rounded-2xl p-2 ml-2 font-bold text-sm cursor-pointer'
                                        onChange={handleSelectChange}
                                    >
                                        <option value='desc'>
                                            vencimiento más próximo
                                        </option>
                                        <option value='asc'>
                                            vencimiento más lejano
                                        </option>
                                    </select>
                                </div>
                            </div>
                            {isLoading ? (
                                <div className='h-72 flex justify-center items-center'>
                                    <Loader />
                                </div>
                            ) : isError ? (
                                <AlertInfo
                                    errorMessage={`Hubo un error. Por favor intente nuevamente o contacte al
                                administrador.`}
                                    reload
                                />
                            ) : (
                                isSuccess && (
                                    <div className='px-8'>
                                        {sortList(data!, activeType).length >
                                            0 && (
                                            <div className='grid grid-cols-4 cursor-pointer lg:grid-cols-12 w-full items-center mb-3 mt-6'>
                                                <div className='lg:col-span-4'></div>
                                                <div className='text-gray400 text-sm font-bold lg:col-span-2'>
                                                    Total cuotas
                                                </div>
                                                <div className='text-gray400 text-sm font-bold lg:col-span-2'>
                                                    Valor cuota
                                                </div>
                                                <div className='text-gray400 text-sm font-bold lg:col-span-2'>
                                                    Fecha vencimiento
                                                </div>
                                                <div className='lg:col-span-2'></div>
                                            </div>
                                        )}
                                        {sortList(data!, activeType).map(
                                            (insurance, i: number) => (
                                                <InsuranceCard
                                                    {...insurance}
                                                    key={i}
                                                />
                                            )
                                        )}
                                    </div>
                                )
                            )}
                            {isSuccess &&
                                sortList(data!, activeType).length === 0 && (
                                    <h3 className='text-center my-10'>
                                        No hay seguros de este tipo
                                    </h3>
                                )}
                        </div>
                    </div>
                    <div className='col-span-12 md:col-span-3 mt-6 lg:mt-0 h-full'>
                        <PaySummary
                            tooltip={
                                'Debe añadir al menos una cuota para continuar con el pago'
                            }
                            disabled={list.length === 0}
                            onClick={
                                rol === 'corredor'
                                    ? agregarRecibos
                                    : particionPersona
                            }
                            isLoading={
                                rol === 'corredor' ? false : personaIsLoading
                            }
                            isError={
                                rol === 'corredor' ? false : personaIsError
                            }
                        />
                    </div>
                </div>
            </section>
        </Screen>
    );
};

export default PortalPagos;
