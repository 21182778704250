import React from 'react';
import { useRol } from '../customHooks/customHooks';
import Form from '../components/forms/Form';
import Screen from '../components/Screen/Screen';

const Login = () => {
    const rol = useRol();

    return (
        <Screen public>
            <div className='flex flex-col justify-center items-center mt-10'>
                {(rol === 'contabilidad' || rol === 'agente') && (
                    <p className='font-normal text-3xl gray400'>CONTABILIDAD</p>
                )}
                <div className='bg-white border-1-5 border-gray-300 rounded shadow-lg w-4/5 md:w-2/6 flex flex-col justify-center items-center my-8 py-20'>
                    <div className='mb-5 w-4/12'>
                        <img
                            src='../images/logo-color-transparent.png'
                            alt='logo'
                        />
                    </div>
                    <h3 className='font-bold mb-8 text-4xl text-gray700'>
                        Portal de Pagos
                    </h3>
                    <div className='w-8/12'>
                        <Form rol={rol} />
                    </div>
                </div>
                <small className='gray400 mb-16'>
                    MAPFRE &#169; 2021 Todos los derechos reservados
                </small>
            </div>
        </Screen>
    );
};

export default Login;
