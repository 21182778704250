import {
    $Text,
    CustomField,
    Invalid,
    removeExcessWhitespace,
    Result,
    Valid,
} from '@tdc-cl/x-form';

export const $Rut = CustomField.extends($Text).with({
    label: 'RUT',

    preprocess(input) {
        return removeExcessWhitespace(
            input.replace(/[^\d/A-Z]/g, '')
        ).toUpperCase();
    },

    parse(input) {
        return parseRut(input).map(() => input);
    },
    validate(value) {
        return parseRut(value).chain(({ digits, dv }) => {
            if (computeDV(digits) !== dv) {
                return Invalid('Dígito verificador incorrecto');
            }

            return Valid(value);
        });
    },
    render: {
        Field({ field }) {
            /* Here is a simplified version of the default render */

            const {
                containerRef, // attach to your outermost div
                inputProps, // or textAreaProps
                inputRef, // or textAreaRef
                label, // a string or arbitrary JSX
                isOptional,
                locale,
                result,
                input,
                form,
            } = field;

            return (
                <div ref={containerRef}>
                    <label htmlFor={inputProps.id}>
                        {label}
                        {isOptional && <em>({locale.optional})</em>}
                    </label>

                    <input
                        {...inputProps}
                        ref={inputRef}
                        value={field.input.value as any}
                        onChange={(newValue: any) => {
                            field.input.setValue(
                                formatRut(newValue.target.value) as any
                            );
                        }}
                    />

                    {result instanceof Invalid &&
                        (input.hasBeenBlurred || form.hasBeenSubmitted) && (
                            <small
                                style={{
                                    color: 'rgb(182, 0, 0)',
                                    fontWeight: 600,
                                }}
                            >
                                {result.message}
                            </small>
                        )}
                </div>
            );
        },
    },
});

interface Rut {
    digits: string[]; // ['1', '8', ...]
    dv: string; // '0' | '1' | ... | 'K'
}

function parseRut(s: string): Result<Rut> {
    const match = withDots.exec(s) ?? withoutDots.exec(s);

    if (!match) {
        return Invalid('Rut inválido');
    }

    let digits = match.slice(1);
    const dv = digits.pop()!;

    return Valid({ digits, dv });
}

const withDots = /^(\d?)(\d)\.(\d)(\d)(\d).(\d)(\d)(\d)-([0-9K])$/;
const withoutDots = /^(\d?)(\d)(\d)(\d)(\d)(\d)(\d)(\d)-?([0-9K])$/;

function computeDV(digits: string[]): string {
    let factors = Array<number>();
    while (factors.length < digits.length) {
        factors.push(2, 3, 4, 5, 6, 7);
    }

    let sum = 0;
    digits.reverse().forEach((digit, i) => {
        sum += Number(digit) * factors[i];
    });

    const dv = 11 - (sum % 11);
    switch (dv) {
        case 11:
            return '0';
        case 10:
            return 'K';
        default:
            return dv.toString();
    }
}

function formatRut(value: string) {
    if (!value) return value;

    let rutNumber = value
        .toUpperCase()
        .replace(/[^\d/A-Z]/g, '')
        .split('');
    let onlyNumbers = String(value).replace(/[^\d]/g, '').split('');

    const formatter = new Intl.NumberFormat('es-CL');

    const rutLength = onlyNumbers.length;

    if (rutLength === 1) {
        rutNumber = rutNumber.join('').replace(/[^\d]/g, '').split('');
        return rutNumber.join('');
    }

    if (rutLength > 1 && rutLength < 5) {
        rutNumber = rutNumber.join('').replace(/[^\d]/g, '').split('');
        return `${rutNumber.slice(0, rutLength - 1).join('')}-${
            rutNumber[rutLength - 1]
        }`;
    } else if (rutLength >= 5 && rutLength < 7) {
        rutNumber = rutNumber.join('').replace(/[^\d]/g, '').split('');
        return `${formatter.format(
            Number(rutNumber.slice(0, rutLength - 1).join(''))
        )}-${rutNumber[rutLength - 1]}`;
    } else if (rutLength >= 7) {
        if (
            rutNumber.length === 9 &&
            isNaN(Number(rutNumber[rutNumber.length - 2]))
        ) {
            rutNumber = rutNumber.join('').replace(/[^\d]/g, '').split('');
            return `${formatter.format(
                Number(rutNumber.slice(0, rutLength - 1).join(''))
            )}-${rutNumber[rutLength - 1]}`;
        }
        if (!isNaN(Number(rutNumber.slice(0, rutNumber.length - 1).join('')))) {
            return `${formatter.format(
                Number(rutNumber.slice(0, rutNumber.length - 1).join(''))
            )}-${rutNumber[rutNumber.length - 1]}`;
        }
        return `${rutNumber.slice(0, rutNumber.length - 1).join('')}-${
            rutNumber[rutNumber.length - 1]
        }`;
    } else {
        return rutNumber.join('');
    }
}
