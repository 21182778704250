import Decimal from 'decimal.js';
import { useAtom } from 'jotai';
import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import { deleteItemAtom, ListType } from '../../store/Atoms';
import { formatCurrency } from '../../utils/utils';
import InsuranceSummary from './InsuranceSummary';
import PayItemDetail from './PayItemDetail';
import PayRemove from './PayRemove';

const PayItem: React.FC<ListType> = ({ id, nombre, recibos, divisa }) => {
    const { paso } = useParams() as { paso: string };
    const [showMore, setShowMore] = useState(
        paso === 'partes-pago' ? true : false
    );
    const [, deleteItem] = useAtom(deleteItemAtom);
    const itemTotal = recibos.reduce((a, b) => a.add(b.monto), new Decimal(0));

    return (
        <div className=''>
            <div className='p-3 border-gray-300 border-b-1-5 pb-2 '>
                <div className='text-sm flex  mb-2 items-center'>
                    <div className='w-2/4'>
                        <InsuranceSummary
                            nombre={nombre}
                            id={id}
                            showMore={showMore}
                            setShowMore={setShowMore}
                        />
                    </div>
                    {paso === 'seguros' ? (
                        <div className='flex lg:justify-center mt-3 ml-7 lg:mt-0'>
                            <PayRemove
                                amount={itemTotal}
                                divisa={divisa}
                                id={id}
                                deleteItem={deleteItem}
                            />
                        </div>
                    ) : (
                        <span className='w-2/4 text-right font-semibold text-base'>
                            {formatCurrency(itemTotal, divisa)}
                        </span>
                    )}
                </div>
                <div className={`mt-6 ${!showMore && 'hidden'}`}>
                    {recibos.length > 0 &&
                        recibos.map((recibo, i) => (
                            <div key={recibo.id}>
                                <PayItemDetail
                                    cuota={i}
                                    cuotas={recibos.length}
                                    recibo={recibo}
                                    divisa={divisa}
                                />
                            </div>
                        ))}
                </div>
            </div>
        </div>
    );
};

export default PayItem;
