import { Decimal } from 'decimal.js';

type Currency = 'UF' | 'CLP' | 'USD';

export class Money {
    constructor(readonly currency: Currency, readonly amount: Decimal) {}

    private static _ufValue?: Decimal;
    private static _usdValue?: Decimal;

    static get ufValue(): Decimal {
        if (this._ufValue == null) {
            throw new Error('You should initialize the UF value');
        }
        return this._ufValue;
    }

    static get usdValue(): Decimal {
        if (this._usdValue == null) {
            throw new Error('You should initialize the USD value');
        }
        return this._usdValue;
    }

    static setUfValue(x: Decimal | string) {
        this._ufValue = new Decimal(x);
    }

    static setUsdValue(x: Decimal | string) {
        this._usdValue = new Decimal(x);
    }

    static UF(amount: Decimal): Money;
    static UF(amount: string): Money;
    static UF(amount: Decimal | string) {
        return new Money('UF', new Decimal(amount));
    }

    static CLP(amount: Decimal): Money;
    static CLP(amount: string): Money;
    static CLP(amount: Decimal | string): Money {
        return new Money('CLP', new Decimal(amount));
    }

    static USD(amount: Decimal): Money;
    static USD(amount: string): Money;
    static USD(amount: Decimal | string): Money {
        return new Money('USD', new Decimal(amount));
    }

    plus(other: Money): Money {
        return new Money(
            this.currency,
            this.amount.plus(other.as(this.currency))
        );
    }

    minus(other: Money): Money {
        return new Money(
            this.currency,
            this.amount.minus(other.as(this.currency))
        );
    }

    times(factor: Decimal | string): Money {
        return new Money(this.currency, this.amount.times(factor));
    }

    equals(other: Money): boolean {
        return (
            this.currency === other.currency && this.amount.equals(other.amount)
        );
    }

    lessThan(other: Money): boolean {
        return this.as(other.currency).lessThan(other.amount);
    }

    greaterThan(other: Money): boolean {
        return this.as(other.currency).greaterThan(other.amount);
    }

    toString() {
        return `${Money.prefix(this.currency)}${Money.format(
            this.amount.toString()
        )}`;
    }

    /*  format(rounding: Decimal.Rounding = Decimal.ROUND_HALF_UP) {
    return `${Money.prefix(this.currency)}${Money.format(
      this.amount.toFixed(Money.decimals(this.currency), rounding)
    )}`;
  } */

    as(currency: Currency): Decimal {
        return Money.convert(this.amount, this.currency, currency);
    }

    private static convert(
        amount: Decimal,
        from: Currency,
        to: Currency
    ): Decimal {
        if (from === 'UF' && to === 'CLP') {
            return amount.times(Money.ufValue);
        }

        if (from === 'CLP' && to === 'UF') {
            return amount.dividedBy(Money.ufValue);
        }

        if (from === 'USD' && to === 'CLP') {
            return amount.times(Money.usdValue);
        }

        if (from === 'CLP' && to === 'USD') {
            return amount.dividedBy(Money.usdValue);
        }

        return amount;
    }

    static format(input: string): string {
        const [integerPart, decimalPart] = input.split('.');
        let ans = thousands(integerPart);
        if (decimalPart) ans += ',' + decimalPart;
        return ans;
    }

    static decimals(currency: Currency): number {
        switch (currency) {
            case 'UF':
                return 4;
            case 'CLP':
                return 0;
            case 'USD':
                return 2;
        }
    }

    static prefix(currency: Currency): string {
        switch (currency) {
            case 'UF':
                return 'UF ';
            case 'CLP':
                return '$';
            case 'USD':
                return 'US$';
        }
    }
}

export function thousands(digits: string): string {
    const n = digits.length;

    if (n > 3) {
        return thousands(digits.slice(0, n - 3)) + '.' + digits.slice(n - 3, n);
    }

    return digits;
}
