import Decimal from 'decimal.js';
import React from 'react';
import { Money } from '../../Money';
import { FacturaItemsPreview } from '../../types/types';

const InvoiceAmmounts: React.FC<{ items: FacturaItemsPreview[] }> = ({
    items,
}) => {
    const totalExento = (type: string) =>
        items
            .filter((x) => x.type === `MontoType.${type}`)
            .reduce((a, b) => a + b.monto, 0);
    return (
        <div className='border-1-5 border-gray-400 mt-4 grid grid-cols-12 text-2xs'>
            <div className='col-span-6 border-r-1-5 border-gray-400'></div>
            <div className='col-span-2 border-r-1-5 border-gray-400'>
                <div className='py-5 px-2 flex flex-col font-bold'>
                    <span className='mb-3'>MONTO EXENTO</span>
                    <span className='mb-3'>MONTO AFECTO</span>
                    <span className='mb-3'>MONTO IVA</span>

                    <span>MONTO TOTAL</span>
                </div>
            </div>
            <div className='col-span-4'>
                <div className='py-5 px-2 text-right flex flex-col'>
                    <span className='mb-3'>
                        {Money.CLP(
                            new Decimal(totalExento('EXENTO'))
                        ).toString()}
                    </span>
                    <span className='mb-3'>
                        {' '}
                        {Money.CLP(
                            new Decimal(totalExento('AFECTO'))
                        ).toString()}
                    </span>
                    <span className='mb-3'>
                        {' '}
                        {Money.CLP(new Decimal(totalExento('IVA'))).toString()}
                    </span>
                    <span>
                        {' '}
                        {Money.CLP(
                            new Decimal(items.reduce((a, b) => a + b.monto, 0))
                        ).toString()}
                    </span>
                </div>
            </div>
        </div>
    );
};

export default InvoiceAmmounts;
