import React, { useEffect } from 'react';
import { Link, useHistory, useLocation } from 'react-router-dom';
import Navbar from '../components/Navbar/Navbar';
import VoucherSummary from '../components/PortalPagos/Checkout/VoucherPersona';
import arrowLeft from '../svgs/arrowLeft.svg';
import { useRol } from '../customHooks/customHooks';
import { ListVoucher } from '../types/types';
import { useAtom } from 'jotai';
import { selectedVoucherAtom } from '../store/Atoms';
import AnticipadoBadge from '../components/Badges/AnticipadoBadge';
import Screen from '../components/Screen/Screen';

const DeleteVoucher = () => {
    const rol = useRol();
    const history = useHistory();
    const { state } = useLocation() as { state: ListVoucher };
    const [, setSelectedVoucher] = useAtom(selectedVoucherAtom);

    const voucherData = [
        {
            number: '18',
            id: '12345',
            date: '17 ene 2021',
            monto: '$ 40.000',
        },
        {
            number: '19',
            id: '6563331',
            date: '17 ene 2021',
            monto: '$ 40.000',
        },
        {
            number: '20',
            id: '98653',
            date: '17 ene 2021',
            monto: '$ 40.000',
        },
    ];

    return (
        <Screen>
            <Navbar />
            <section className='flex justify-center'>
                <div className='container  grid grid-cols-1 md:grid-cols-12 '>
                    <div className='col-span-8'>
                        <div className='w-full mx-auto  mb-4 md:pr-10'>
                            <Link
                                to='/contabilidad/portal-de-pagos/vouchers'
                                className='flex mb-3 cursor-pointer'
                            >
                                <img src={arrowLeft} alt='back arrow' />
                                <p className='font-base ml-3'>
                                    Volver a Mis Vouchers
                                </p>
                            </Link>
                            <div className='rounded-lg border-1-3 border-gray-300 px-6 py-2 h-20 flex justify-between items-center  mb-5 bg-white'>
                                <div>
                                    <p className='text-gray700 font-bold text-2xl'>
                                        Empresa 1
                                    </p>
                                    <small className='text-gray400 text-xs '>
                                        RUT 1111111-2
                                    </small>
                                </div>
                                <div>
                                    <AnticipadoBadge />
                                </div>
                            </div>
                            <div className='rounded-lg border-1-3 border-gray-300  bg-white'>
                                <div className='flex px-6 py-4 justify-between'>
                                    <p className='font-bold text-gray700 text-2xl'>
                                        Partes del voucher #2
                                    </p>
                                </div>
                                <div className='p-6 '>
                                    <table className='w-full'>
                                        <thead>
                                            <tr>
                                                <th className='w-1/3 '></th>
                                                <th className='w-1/3 text-center text-sm text-gray400 font-normal'>
                                                    Vencimiento
                                                </th>
                                                <th className='w-1/3 text-right text-sm text-gray400 font-normal'>
                                                    Monto
                                                </th>
                                            </tr>
                                        </thead>
                                        {voucherData.map((voucher) => {
                                            return (
                                                <tbody>
                                                    <tr className='border-b border-gray200'>
                                                        <td className='w-1/3'>
                                                            <span className='text-sm text-gray700 font-bold'>
                                                                Parte{' '}
                                                                {voucher.number}
                                                            </span>

                                                            <br />
                                                            <span className='text-xs text-gray400'>
                                                                ID {voucher.id}
                                                            </span>
                                                        </td>
                                                        <td className='w-1/3 text-center text-sm font-medium'>
                                                            {voucher.date}
                                                        </td>
                                                        <td className='w-1/3 text-right text-sm font-bold'>
                                                            {voucher.monto}
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            );
                                        })}
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className='col-span-4'>
                        {state && <VoucherSummary {...state} />}
                    </div>
                </div>
            </section>
        </Screen>
    );
};

export default DeleteVoucher;
