import Decimal from 'decimal.js';
import React from 'react';
import { Money } from '../../Money';
import { FacturaPreview } from '../../types/types';

interface PreviewProps {
  factura: FacturaPreview;
}

const InvoiceDetails: React.FC<PreviewProps> = ({ factura }) => {
  return (
    <div className='border-1-5 border-gray-400 mt-4 grid grid-cols-12 text-2xs'>
      <div className='col-span-9 border-r-1-5 border-gray-400 lex flex-col'>
        <div className='border-b-1-5 border-gray-400 text-center font-bold'>
          DETALLE
        </div>
        <div className='py-5 px-2 flex flex-col'>
          {factura.items
            .filter(x => x.type !== 'MontoType.IVA')
            .map(item => (
              <div className='mb-4'>
                {/* <span> PÓLIZA:</span> */}
                {/* <span className='ml-14'> {item.document.id}</span> */}
                <span className=''>
                  {item.data.split('\n').map(x => (
                    <p>{x}</p>
                  ))}
                </span>
              </div>
            ))}
        </div>
        <div></div>
      </div>
      <div className='col-span-3 flex flex-col'>
        <div className='border-b-1-5 border-gray-400 text-center font-bold'>
          MONTO
        </div>
        <div className='py-5 px-2 text-right flex flex-col'>
          {factura.items
            .filter(x => x.type !== 'MontoType.IVA')
            .map(item => (
              <span className='mb-4'>
                {Money.CLP(new Decimal(item.monto)).toString()}
              </span>
            ))}
        </div>
      </div>
    </div>
  );
};

export default InvoiceDetails;
