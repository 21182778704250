import { useJsonStorage } from '@tdc-cl/async-stuff';
import React from 'react';
import { Redirect, useHistory } from 'react-router';
import { authStorage, userStorage } from '../../endpoints/endpoints';
import { LoginResponse } from '../../types/types';
import { useRequestErrorHandler } from '@tdc-cl/async-stuff';
import CustomModal from '../CustomModal/CustomModal';
import { useBoolean } from '../../customHooks/useBoolean';
import { useClearSettings } from '../../customHooks/useClearSettings';
import { payListAtom } from '../../store/Atoms';
import { useAtom } from 'jotai';
import ActionButton from '../Buttons/ActionButton';

const Screen: React.FC<{ public?: boolean }> = (props) => {
    const user: LoginResponse | undefined = useJsonStorage(authStorage);
    const showModal = useBoolean(false);
    const [, setList] = useAtom(payListAtom);
    const clearSettings = useClearSettings();
    const history = useHistory();
    useRequestErrorHandler({
        onDjangoTokenError: function () {
            showModal.setValue(true);
            clearSettings();
        },
    });

    const closeModal = (arg: boolean) => {
        history.push('/');
        authStorage.clear();
        userStorage.clear();
        showModal.setValue(false);
        localStorage.removeItem('payListMapfre');
        setList([]);
    };

    if (!user && !props.public) {
        return <Redirect to='/' />;
    }

    return (
        <main>
            {props.children}

            <CustomModal isOpen={showModal.value} setIsOpen={closeModal} shadow>
                <div className='h-40vh flex flex-col items-center justify-center px-10'>
                    <div className='text-center text-gray700 h-full flex flex-col justify-center'>
                        <h1>Sesión expirada</h1>
                        <h3 className='font-normal'>
                            Debes iniciar sesión nuevamente
                        </h3>
                    </div>
                    <div className='w-full'>
                        <ActionButton onClick={closeModal}>
                            Ir al inicio
                        </ActionButton>
                    </div>
                </div>
            </CustomModal>
        </main>
    );
};

export default Screen;
