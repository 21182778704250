import React from 'react';
import { useRol } from '../../customHooks/customHooks';
import { StyledTableCell } from './TableStyle';

const TableHeader = () => {
    const rol = useRol();
    return (
        <>
            <StyledTableCell align='left'>ID</StyledTableCell>
            <StyledTableCell align='left'>FECHA CREACIÓN</StyledTableCell>
            {rol !== 'persona' && (
                <StyledTableCell align='left'>TITULAR</StyledTableCell>
            )}
            <StyledTableCell align='left'>Nº PARTES</StyledTableCell>
            <StyledTableCell align='left'>MONTO TOTAL</StyledTableCell>
            <StyledTableCell align='left'>ESTADO ACTUAL</StyledTableCell>
            <StyledTableCell align='left'>
                {rol === 'persona' ? '' : 'ACCIONES'}
            </StyledTableCell>
            {rol !== 'persona' && <StyledTableCell/>}
        </>
    );
};

export default TableHeader;
