import {
    filialesAtom,
    filialSettingAtom,
    nonFilialesAtom,
    nonFilialSettingAtom,
} from '../store/Atoms';
import { useAtom } from 'jotai';

function useClearSettings() {
    const [, setFiliales] = useAtom(filialesAtom);
    const [, setNonFiliales] = useAtom(nonFilialesAtom);
    const [, setFilialSetting] = useAtom(filialSettingAtom);
    const [, nonFilialSetting] = useAtom(nonFilialSettingAtom);
    const clearSettings = () => {
        setFilialSetting([]);
        nonFilialSetting(null);
        setFiliales([]);
        setNonFiliales(undefined);
    };
    return clearSettings;
}
export { useClearSettings };
