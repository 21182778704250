import React, { useState } from 'react';
import { useMutation, useQuery } from 'react-query';
import { FacturaPreview, ListVoucherPart } from '../../types/types';
import ActionButton from '../Buttons/ActionButton';
import {
  confirmarFacturaParte,
  facturaPreview,
} from '../../endpoints/endpoints';
import Loader from '../Loader/Loader';
import AlertInfo from '../Alert/AlertInfo';
import circleDown from '../../svgs/circle-ctrl-down.svg';
import close from '../../svgs/e-close.svg';
import InvoicePreview from '../InvoicePreview/InvoicePreview';

interface PartsPreview {
  setFacturaConfirmada: (arg: boolean) => void;
  setShowFacturar: (arg: boolean) => void;
  parte: number;
}
const PartPreview: React.FC<PartsPreview> = ({
  setFacturaConfirmada,
  setShowFacturar,
  parte,
}) => {
  const { data, isLoading, error } = useQuery<FacturaPreview[]>(
    [facturaPreview.url, parte],
    () => facturaPreview.fetch(parte),
    {
      onSuccess: () => console.log(data),
    }
  );
  const confirmarFactura = useMutation(
    confirmarFacturaParte.url,
    confirmarFacturaParte.fetch,
    {
      onSuccess: () => {
        setFacturaConfirmada(true);
      },
    }
  );
  const [showMore, setShowMore] = useState(false);
  const [selectedID, setSelectedID] = useState<number>();

  const toggleShow = (id: number) => {
    setSelectedID(id);
    if (showMore === false) {
      setShowMore(true);
    } else if (showMore === true && id === selectedID) {
      setShowMore(false);
    }
  };

  const [hoveredRow, setHoveredRow] = useState<number | undefined>(undefined);
  return (
    <div className='p-4'>
      <div className=' gray600 border-b flex justify-between items-center border-gray200 mb-6 pb-4'>
        <p className='text-xl font-bold text-gray700'>
          Facturación Voucher #{parte}
        </p>

        <div
          onClick={() => setShowFacturar(false)}
          className='hover:bg-gray250 bg-gray200 w-11 h-11 flex justify-center items-center rounded cursor-pointer'
        >
          <img src={close} alt='close' />
        </div>
      </div>
      <p className='font-bold mb-3'>Previsualización de facturas</p>
      {isLoading && (
        <div className='flex justify-center'>
          <Loader />
        </div>
      )}
      {error && `${error}`}
      {data &&
        data.map((factura, i) => {
          return (
            <div
              className={`px-4 border-1-3 ${
                !showMore && 'h-14'
              }  border-gray250 relative z-10 shadow-rows rounded mb-4`}
            >
              <div
                onMouseEnter={() => setHoveredRow(i)}
                onMouseLeave={() => setHoveredRow(undefined)}
                onClick={() => toggleShow(i)}
                className='h-full py-4 cursor-pointer flex items-center justify-between'
              >
                <div className='font-bold h-full'>Factura {i + 1}</div>
                <div
                  className={`flex items-center justify-center rounded-full  h-8 w-8 ${
                    hoveredRow === i && 'bg-gray200'
                  }`}
                >
                  <img
                    src={circleDown}
                    alt={'arrow'}
                    className='cursor-pointer'
                    onClick={() => toggleShow(i)}
                  />
                </div>
              </div>
              <div
                className={`border-t-1-5 mt-4 border-gray300 ${
                  showMore && selectedID === i ? '' : 'hidden'
                }`}
              >
                <InvoicePreview factura={factura} />
              </div>
            </div>
          );
        })}

      {confirmarFactura.isError && (
        <AlertInfo
          errorMessage={`Hubo un problema al facturar, por favor contacte a un agente de Mapfre`}
          className='text-center'
        />
      )}
      <div className='w-96 ml-auto mt-5 flex'>
        <div className='mr-4 w-52'>
          <ActionButton
            classname='bg-gray200 hover:bg-gray250 text-gray400 border-gray200'
            onClick={() => setShowFacturar(false)}
          >
            Cancelar
          </ActionButton>
        </div>
        <ActionButton
          isLoading={confirmarFactura.isLoading}
          onClick={() => {
            confirmarFactura.mutate({ voucher: parte });
          }}
        >
          CONFIRMAR FACTURA(S)
        </ActionButton>
      </div>
    </div>
  );
};

export default PartPreview;
