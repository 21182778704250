import Decimal from 'decimal.js';
import TableRow from '@material-ui/core/TableRow';
import React from 'react';
import { UnassignedPayments } from '../../types/types';
import { formatCurrency } from '../../utils/utils';
import { StyledTableCell } from '../PaymentsTable/TableStyle';
interface RowProps {
    rows: UnassignedPayments[];
}
const ErrorTableBody: React.FC<RowProps> = ({ rows }) => {
    return (
        <>
            {rows.map((row, i) => (
                <TableRow key={i}>
                    <StyledTableCell align='left'>
                        <span className='font-bold'>
                            {row.account_source_number ?? '-'}{' '}
                        </span>
                    </StyledTableCell>
                    <StyledTableCell align='left'>
                        <span className='font-bold'>
                            {row.account_target_number ?? '-'}{' '}
                        </span>
                    </StyledTableCell>

                    <StyledTableCell align='left'>
                        <span className='font-bold'>
                            {formatCurrency(
                                new Decimal(Number(row.monto) ?? 0),
                                row.divisa
                            )}
                        </span>
                    </StyledTableCell>
                    <StyledTableCell align='left'>
                        <span className='font-bold'>
                            {`${new Date(
                                row.timestamp.toString()
                            ).toLocaleDateString('es-ES', {
                                day: 'numeric',
                                month: 'short',
                                year: 'numeric',
                            })}`}
                        </span>
                    </StyledTableCell>
                </TableRow>
            ))}
        </>
    );
};
export default ErrorTableBody;
