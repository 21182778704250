import Decimal from 'decimal.js';
import { useAtom } from 'jotai';
import React from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useClearSettings } from '../../customHooks/useClearSettings';
import { payListAtom, porcentajeTotalAtom } from '../../store/Atoms';
import arrowLeft from '../../svgs/arrowLeft.svg';
import { User } from '../../types/types';
import AnticipadoBadge from '../Badges/AnticipadoBadge';

interface HeaderProps {
    checkout: boolean;
    link: string;
    cliente: User;
    prefill?: boolean;
    filialesLength?: number;
    onClick?: () => void;
}

const CorredorHeader: React.FC<HeaderProps> = ({
    filialesLength,
    prefill,
    cliente,
    onClick,
}) => {
    const history = useHistory();
    const [, setPorcentajeTotal] = useAtom(porcentajeTotalAtom);
    const [, setList] = useAtom(payListAtom);
    const { paso } = useParams() as { paso: string };
    const clearSettings = useClearSettings();

    const handleGoBack = () => {
        if (paso === 'partes-pago' || paso === 'checkout') {
            setPorcentajeTotal(new Decimal(100));
            if (paso === 'partes-pago') {
                clearSettings();
            }
        } else {
            setList([]);
            localStorage.setItem('payListMapfre', JSON.stringify([]));
        }
        history.goBack();
    };
    return (
        <div className='mb-5'>
            <div className='cursor-pointer flex' onClick={handleGoBack}>
                <img
                    src={arrowLeft}
                    alt='arrow icon'
                    className='inline mr-3 cursor-pointer'
                />
                <span className='text-base font-normal'>
                    Volver a{' '}
                    {paso === 'partes-pago'
                        ? 'selección de cuotas a pagar'
                        : 'selección de empresa'}
                </span>
            </div>
            <div className='flex mt-5 gray400'></div>
            <div className='bg-calcite rounded-md lg:mr-5 border border-gray250 px-8 py-4'>
                <div className={'flex items-center justify-between'}>
                    <div>
                        <h5 className='gray600 mr-5 font-bold text-lg'>
                            {cliente && cliente.nombre}
                        </h5>
                        <small className='text-xs text-gray400 font-light'>
                            RUT{' '}
                            {cliente &&
                                cliente.rut.slice(0, cliente.rut.length - 1)}
                            -{cliente && cliente.rut[cliente.rut.length - 1]}
                        </small>
                    </div>
                    {cliente && cliente.facturacion_anticipada && (
                        <div>
                            {' '}
                            <AnticipadoBadge />
                        </div>
                    )}
                </div>
                {prefill === false && filialesLength === 0 && (
                    <span
                        className='cursor-pointer gray400 underline text-xs font-semibold'
                        onClick={onClick}
                    >
                        Utilizar datos de última operación realizada para{' '}
                        {cliente?.nombre}
                    </span>
                )}
            </div>
        </div>
    );
};
CorredorHeader.defaultProps = {
    checkout: false,
};
export default CorredorHeader;
