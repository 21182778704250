import React, { useState } from 'react';
import Decimal from 'decimal.js';
import { useAtom } from 'jotai';
import { useMutation } from 'react-query';
import { useHistory, useParams } from 'react-router-dom';
import { newSettingsAtom, nonFilialSettingAtom } from '../../store/Atoms';
import { Divisa, ListVoucher } from '../../types/types';
import { formatCurrency } from '../../utils/utils';
import ActionButton from '../Buttons/ActionButton';
import VoucherInfo from '../PortalPagos/Checkout/VoucherInfo';
import {
    endVoucher,
    particionarEmpresa,
    reemplazarVoucher,
} from '../../endpoints/endpoints';
import CustomModal from '../CustomModal/CustomModal';
import AlertModal from '../Alert/AlertModal';
import AlertInfo from '../Alert/AlertInfo';
import VoucherDetailPersona from '../PortalPagos/Checkout/VoucherDetailPersona';

interface SummaryProps {
    setPreview: (arg: string) => void;
    setShowPreview: (arg: boolean) => void;
    state?: {
        reason_id: number;
        voucher: ListVoucher;
        other: string;
    };
    voucher: ListVoucher;
    documents: number[];
}
const InvoiceSummary: React.FC<SummaryProps> = ({
    setPreview,
    setShowPreview,
    state,
    voucher,
    documents,
}) => {
    const [showModal, setShowModal] = useState(false);
    const [alertMessage, setAlertMessage] = useState('');
    const history = useHistory();
    const { step } = useParams() as { step: string };
    const [newSettings] = useAtom(newSettingsAtom);
    const [nonFilialSetting] = useAtom(nonFilialSettingAtom);

    const nuevoVoucher = useMutation(reemplazarVoucher.fetch, {
        onSuccess: data =>
            history.push({
                pathname: '/contabilidad/voucher',
                state: {
                    ...data,
                    vencimiento: data.vencimiento.toString(),
                    fecha: data.fecha.toString(),
                },
            }),
    });
    const nuevaParticion = useMutation(particionarEmpresa.fetch, {
        onSuccess: data => {
            nuevoVoucher.mutate({
                voucher_id: voucher.id,
                reason_id: state!.reason_id,
                other: state!.other,
                new_partition: data.partes.map((parte, i) => {
                    return {
                        partition_part_id: parte.id,
                        direccion: voucher.partes[i].pago_data.direccion.id,
                        cuenta_source:
                            voucher.partes[i].pago_data.cuenta_source.id,
                        cuenta_target:
                            voucher.partes[i].pago_data.cuenta_target.id,
                    };
                }),
            });
        },
    });
    const totalDivisas = Object.entries(voucher.total_to_show);
    const finalizarVoucher = useMutation(endVoucher.fetch);

    return (
        <div>
            <div className='flex flex-col h-3/5 rounded-lg bg-white pb-1'>
                <div className='py-3 px-8 border-gray-300 border-b-1-5 flex bg-gray700 items-center rounded-t-lg text-white'>
                    <p className='font-bold text-2xl'> Total</p>
                    <p className='text-right text-2xl font-bold ml-auto'>
                        {totalDivisas.map((totalDivisa, i) => {
                            if (!!totalDivisa[1]) {
                                return (
                                    <span>
                                        {i > 0 && (
                                            <span className='mx-1'>+</span>
                                        )}
                                        {formatCurrency(
                                            new Decimal(totalDivisa[1]),
                                            totalDivisa[0] as Divisa
                                        )}
                                    </span>
                                );
                            }
                        })}
                    </p>
                </div>

                <div className='py-4 px-8 text-sm'>
                    <div className='text-gray-600'>
                        <VoucherInfo title='ID' value={voucher!.id} />
                        <div className='flex mt-3'>
                            <small className='text-sm text-gray400 font-normal'>
                                Fecha
                            </small>
                            <small className='ml-auto text-sm text-gray700 font-medium'>
                                {`${new Date(
                                    voucher.fecha.toString()
                                ).toLocaleDateString('es-ES', {
                                    day: 'numeric',
                                    month: 'short',
                                    year: 'numeric',
                                })}`}
                            </small>
                        </div>
                        {voucher!.user.tipo === 'empresa' && (
                            <>
                                <div className='flex mt-3'>
                                    <small className='text-sm text-gray400 font-normal'>
                                        Empresa
                                    </small>
                                    <small className='ml-auto text-sm text-gray700 font-medium'>
                                        {voucher!.user.nombre}
                                    </small>
                                </div>

                                <div className='flex mt-3'>
                                    <small className='text-sm text-gray400 font-normal'>
                                        Rut Empresa
                                    </small>
                                    <small className='ml-auto text-sm text-gray700 font-medium'>
                                        {voucher!.user.rut}
                                    </small>
                                </div>
                            </>
                        )}
                        <div className='flex mt-3'>
                            <small
                                className='text-sm text-gray400 font-normal'
                                onClick={() => console.log(state?.voucher)}
                            >
                                Estado actual
                            </small>
                            <small className='ml-auto text-sm text-gray700 font-medium'>
                                {voucher.state}
                            </small>
                        </div>
                        <div className={'border-dashed border-t mt-5 pt-5'}>
                            {voucher.partes.map((parte, i) => (
                                <VoucherDetailPersona
                                    index={i}
                                    parte={parte}
                                    key={i}
                                />
                            ))}
                        </div>
                    </div>
                    {voucher.state !== 'Anulado' && (
                        <div className='mt-6'>
                            {step === 'voucher-detail' ? (
                                <>
                                    <div className='mt-4'>
                                        <ActionButton
                                            onClick={() => {
                                                setShowPreview(true);
                                                setPreview('anular');
                                            }}
                                        >
                                            Anular voucher
                                        </ActionButton>
                                    </div>

                                    <div className='flex flex-col items-center justify-center my-3 gray400'>
                                        {'ó'}
                                        <small
                                            className='font-bold underline cursor-pointer'
                                            onClick={() => {
                                                setShowPreview(true);
                                                setPreview('editar');
                                            }}
                                        >
                                            Anular y crear nuevo con estos datos
                                        </small>
                                    </div>
                                    <div className='mt-2 pt-4 border-t'>
                                        <ActionButton
                                            variant={'white'}
                                            isLoading={
                                                finalizarVoucher.isLoading
                                            }
                                            onClick={() => {
                                                finalizarVoucher.mutate(
                                                    {
                                                        voucher_id: voucher.id,
                                                    },
                                                    {
                                                        onSuccess: () => {
                                                            history.push({
                                                                pathname: `/contabilidad/vouchers/finalizado`,
                                                                state: voucher.id,
                                                            });
                                                        },
                                                    }
                                                );
                                            }}
                                        >
                                            Finalizar voucher
                                        </ActionButton>
                                    </div>
                                </>
                            ) : (
                                <div className='mt-2'>
                                    {(nuevoVoucher.isError ||
                                        nuevaParticion.isError) && (
                                        <AlertInfo
                                            errorMessage={`Hubo un problema al anular el voucher, por favor contacte al administrador`}
                                            className='mt-5 text-center'
                                        />
                                    )}
                                    <ActionButton
                                        isLoading={
                                            nuevaParticion.isLoading ||
                                            nuevoVoucher.isLoading
                                        }
                                        onClick={() => {
                                            if (documents.length === 0) {
                                                setAlertMessage(
                                                    'Para reemplazar el voucher debe hacer cambios en los porcentajes'
                                                );
                                                setShowModal(true);
                                            } else {
                                                if (
                                                    !newSettings
                                                        .reduce(
                                                            (a, b) =>
                                                                a.add(
                                                                    b.porcentaje
                                                                ),
                                                            new Decimal(0)
                                                        )
                                                        .equals(
                                                            new Decimal(100)
                                                        )
                                                ) {
                                                    setShowModal(true);
                                                    setAlertMessage(
                                                        'El total debe ser igual a 100%'
                                                    );
                                                } else {
                                                    nuevaParticion.mutate({
                                                        filial_payer_settings:
                                                            newSettings.map(
                                                                filial =>
                                                                    filial.setting
                                                            ),
                                                        non_filial_payer_setting:
                                                            nonFilialSetting
                                                                ? nonFilialSetting.setting
                                                                : null,
                                                        documents: documents,
                                                    });
                                                }
                                            }
                                        }}
                                    >
                                        CONFIRMAR Y GENERAR NUEVO VOUCHER
                                    </ActionButton>
                                </div>
                            )}
                        </div>
                    )}
                </div>
            </div>
            <CustomModal isOpen={showModal} setIsOpen={setShowModal} size='sm'>
                <AlertModal
                    setShowModal={setShowModal}
                    alertMessage={alertMessage}
                ></AlertModal>
            </CustomModal>
        </div>
    );
};

export default InvoiceSummary;
