import classNames from 'classnames';
import React, { useState } from 'react';

interface InsuranceProps {
    activeType: string;
    type: string;
    icon: string;
    activeIcon: string;
    length: number;
    setActiveType: (text: string) => void;
    title: string;
}

const TypeOfInsurance: React.FC<InsuranceProps> = ({
    activeType,
    type,
    icon,
    activeIcon,
    length,
    setActiveType,
    title,
}) => {
    const [hovered, setHovered] = useState('');
    const boxStyle = classNames(
        'border mr-2 md:mr-6 text-xs font-bold py-3 px-5 rounded cursor-pointer w-56 flex items center ',
        { 'text-red400 border-red400': hovered === type && activeType !== type }
    );
    const activeStyle = classNames('bg-red400 border-red400 text-white ');
    return (
        <div
            className={boxStyle + (activeType === type ? activeStyle : '')}
            onClick={() => setActiveType(type)}
            onMouseEnter={() => setHovered(type)}
            onMouseLeave={() => setHovered('')}
        >
            <img
                src={
                    hovered === type && activeType !== type ? activeIcon : icon
                }
                alt='general'
                className='mr-4 w-8'
            />
            <div>
                <p>{title}</p>
                <span className='font-normal'>
                    {length} {length === 1 ? 'seguro' : 'seguros'}
                </span>
            </div>
        </div>
    );
};

export default TypeOfInsurance;
