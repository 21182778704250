import classNames from 'classnames';
import React from 'react';
import { useBoolean } from '../../customHooks/useBoolean';
import Spinner from '../Loader/Spinner';

interface ActionButtonProps {
    onClick: (arg: any) => void;
    disabled?: boolean;
    variant?: string;
    isLoading?: boolean;
    classname?: string;
    dataFor?: string;
}

const ActionButton: React.FC<ActionButtonProps> = ({
    onClick,
    disabled,
    children,
    variant,
    isLoading,
    classname,
    dataFor,
}) => {
    const styles = classNames(
        'flex focus:outline-none justify-center items-center h-11 text-center w-full font-bold text-sm rounded transition-colors',
        {
            'opacity-60 cursor-default': disabled || isLoading,
            'cursor-pointer': !disabled,
            'hover:bg-red400 hover:border-red400':
                (disabled || isLoading) && variant !== 'white',
            'bg-white border-1-3 border-red400 text-red-700 hover:bg-gray-100':
                variant === 'white',
            'bg-red400 border text-white border-red400 hover:bg-red600 hover:border-red600':
                variant !== 'white',
        }
    );
    return (
        <button
            data-tip
            data-for={dataFor && disabled ? dataFor : 'wwww'}
            className={`${styles} ${classname}`}
            onClick={(e: any) => {
                e.preventDefault();
                if (disabled) {
                    return null;
                } else {
                    onClick(e);
                }
            }}
        >
            {isLoading && (
                <div className='mr-2'>
                    <Spinner
                        color={variant === 'white' ? 'text-gray600' : undefined}
                    />
                </div>
            )}{' '}
            {children}
        </button>
    );
};
ActionButton.defaultProps = {
    variant: 'red',
    disabled: false,
};
export default ActionButton;
